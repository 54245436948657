import { Component, OnInit } from '@angular/core';
import { CollapseService } from '@shared/collapse.service';
import { ISubscription } from 'rxjs/Subscription';
import { SearchResult } from '../../model/search-result';
import { SearchResultService } from '../../services/search-result.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-side-menu',
  styleUrls: ['./side-menu.component.less'],
  templateUrl: './side-menu.component.html',
})
export class SideMenuComponent implements OnInit {
  searchResultSubscription: ISubscription;
  searchResult: SearchResult;

  // public isCollapsed = false;

  public constructor(
    public collapseService: CollapseService,
    private searchResultService: SearchResultService
  ) {}

  ngOnInit() {
    this.searchResultSubscription = this.searchResultService
      .getSearchResult()
      .subscribe((searchResult) => {
        if (searchResult) {
          this.searchResult = searchResult;
        }
      });
  }
}
