// 1	  5-Star
// 27	  Concentra 130/4
// 101	MD Head - Grauel
// 102	MD Upper - Grauel
// 81	  MDP-2016
// 2	  Mini Head
// 24	  Mini Lower
// 25	  Mini Upper
// 21	  PSD-2000
// 22	  PSD-2010
// 23	  Ring Printer
// 26	  SIGMA - Animal
// 61	  PS 89
// 68	  Morlock
// 69	  Rundbord
// 62	  Chaintrack
// 63	  Rapid
// 64	  ABC
// 65	  PSD 94
// 66	  TS 125
// 67	  TS 350
// 281	MDP-XL
// 282	MDP-2016 P&P
// 121	PSD-2010 HT
// 283	MDP-2016 Servo
// 2022	Mini Doll(D)
// 284	MDP-2023
// 285	MDP-2023 P&P

// Whithin this configuration the parent property is the source machine type, their children are the target machines
// { 1: 81: true } means that from 5-Star machine MDP-2016 can be created
export const copyEnabledPlatforms = {
  1: {
    81: true,
    282: true,
    284: true,
    285: true,
  },
  81: {
    1: true,
    282: true,
    284: true,
    285: true,
  },
  281: {
    282: true,
    284: true,
    285: true,
  },
  282: {
    1: true,
    81: true,
    281: true,
    283: true,
    284: true,
    285: true,
  },
  283: {
    282: true,
    284: true,
    285: true,
  },
  284: {
    1: true,
    81: true,
    281: true,
    282: true,
    283: true,
    285: true,
  },
  285: {
    1: true,
    81: true,
    281: true,
    282: true,
    283: true,
    284: true,
  },
};
