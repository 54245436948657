import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SelectionOption } from '../model/selection-option';
import { BackendService } from '../services/backend.service';

@Injectable()
export class StatusValuesResolver implements Resolve<SelectionOption[]> {
  constructor(private service: BackendService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SelectionOption[]> | Promise<SelectionOption[]> | SelectionOption[] {
    return this.service.getStatusValues();
  }
}
