import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  styleUrls: ['./main.component.scss'],
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
  // tslint:disable-next-line: no-empty
  ngOnInit() {}
}
