import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../../app/can-deactivate/component-can-deactivate';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private modalService: NzModalService, private ts: TranslateService) {}

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    console.log(component.canDeactivate());

    return new Observable((observer) => {
      if (component.canDeactivate()) {
        observer.next(true);
      } else {
        this.modalService.confirm({
          nzCancelText: this.ts.instant('can-deactivate.modalCancelText'),
          nzContent: this.ts.instant('can-deactivate.modalMessage'),
          nzOkText: this.ts.instant('can-deactivate.modalOkText'),
          nzOkType: 'danger',
          // tslint:disable-next-line: no-void-expression
          nzOnCancel: () => {
            observer.next(false);
          },
          nzOnOk: async () => {
            observer.next(true);
          },
        });
      }
    });
  }
}
