import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import {
  BrowserOptions,
  captureException,
  captureMessage,
  init,
  showReportDialog,
  // tslint:disable-next-line: no-implicit-dependencies
} from '@sentry/browser';
// tslint:disable-next-line: no-implicit-dependencies
// tslint:disable-next-line: no-implicit-dependencies
import { NzMessageService } from 'ng-zorro-antd';
// tslint:disable-next-line: no-implicit-dependencies
import { throwError } from 'rxjs';
// tslint:disable-next-line: no-implicit-dependencies
import { catchError } from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';
import { UserFeedback } from '../user-feedback';
import { UserFeedbackService } from '../user-feedback.service';

// tslint:disable-next-line: naming-convention
export const SENTRY_CONFIG = new InjectionToken<BrowserOptions>('SentryOptions');

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  public constructor(
    private uf: UserFeedbackService,
    private httpClient: HttpClient,
    private message: NzMessageService,
    private logger: LoggerService,
    @Inject(SENTRY_CONFIG) config: BrowserOptions
  ) {
    init(config);
  }

  public handleError(error: Error | any) {
    // INFO: use withScope if info is only relevant to this error
    // https://docs.sentry.io/enriching-error-data/scopes/?platform=browser#local-scopes
    const eventId = captureException(error.originalError || error);
    this.logger.error(error);
    if (error.message === 'feedback') {
      this.uf.feedbackId = eventId;
      this.postUserFeedback(this.uf.feedbackInfo).subscribe(
        () => {
          this.message.create('success', `Thank you for your feedback.`);
        },
        (err) => {
          this.message.create('error', `Something went wrong.`);
          this.logger.error(err);
        }
      );
    }
  }

  public handleUserLogin(message: string | any) {
    captureMessage(message);
  }

  postUserFeedback(feedback: UserFeedback) {
    return this.httpClient.post('/api/v1/userfeedback', feedback).pipe(
      catchError((error) => {
        this.logger.error('Failed logging user feedback to sentry', error);

        return throwError(error);
      })
    );
  }
}
