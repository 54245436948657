import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IPlantSelection } from '@shared/plant-selection';
import { SetPlants, SetSelectedPlant } from '../actions/plant-actions';

@State<IPlantStateModel>({
  defaults: { plants: [{} as IPlantSelection] },
  name: 'plantstate',
})
export class PlantState {
  @Selector() static getPlants(state: IPlantStateModel) {
    return state.plants;
  }

  @Selector() static getSelectedPlant(state: IPlantStateModel) {
    return state.selectedPlant;
  }

  @Selector() static getPlantId(state: IPlantStateModel) {
    if (state.selectedPlant) {
      return state.selectedPlant.plantId;
    }
  }

  @Action(SetPlants) setPlants(ctx: StateContext<IPlantStateModel>, payload: SetPlants) {
    ctx.patchState({ plants: payload.plants });
  }

  @Action(SetSelectedPlant) setSelectedPlant(
    ctx: StateContext<IPlantStateModel>,
    payload: SetSelectedPlant
  ) {
    ctx.patchState({ selectedPlant: payload.plant });
  }
}

export interface IPlantStateModel {
  plants: IPlantSelection[];
  selectedPlant?: IPlantSelection;
}
