import { Component, forwardRef, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@shared/logger/logger.service';
import { ConsoleLoggerService } from 'projects/shared/src/public-api';

@Component({
  providers: [
    { provide: LoggerService, useClass: ConsoleLoggerService },
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line: no-forward-ref
      useExisting: forwardRef(() => FilmDataComponent),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      // tslint:disable-next-line: no-forward-ref
      useExisting: forwardRef(() => FilmDataComponent),
    },
  ],
  selector: 'app-film-data',
  styleUrls: ['./film-data.component.less'],
  templateUrl: './film-data.component.html',
})
export class FilmDataComponent implements ControlValueAccessor, Validator {
  filmDataForm: FormGroup;
  filmCreator: FormControl;
  filmDate: FormControl;
  filmDateModified: FormControl;
  filmModifier: FormControl;

  constructor(private fb: FormBuilder, private ts: TranslateService) {
    console.log(this.filmDataForm);

    this.filmDataForm = this.fb.group({
      // tslint:disable: no-unbound-method
      filmCreator: [''],
      filmDate: [''],
      filmDateModified: [''],
      filmModifier: [''],
    });

    console.log(this.filmDataForm);
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.filmDataForm.valid
      ? // tslint:disable-next-line: no-null-keyword
        null
      : {
          invalidForm: {
            message: this.ts.instant(
              'main-content.processing-cards.filmData.processingDataFieldError'
            ),
            valid: false,
          },
        };
  }

  // tslint:disable-next-line: no-empty
  public onTouched: () => void = () => {};

  registerOnChange(fn: any): void {
    this.filmDataForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    // tslint:disable-next-line: no-unused-expression
    obj && this.filmDataForm.setValue(obj, { emitEvent: false });
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.filmDataForm.disable() : this.filmDataForm.enable();
  }
}
