import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { LoggerService } from '@shared/logger/logger.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum AccessDecisionStrategy {
  ALL_OF,
  ONE_OF,
}

export interface IRoleGuardConfig {
  requiredRoles: string[];
  requiredRolesStrategy?: AccessDecisionStrategy;
}

@Injectable()
export class RoleGuard implements CanActivate {
  private logPrefix = 'RoleGuard -';

  constructor(
    private authService: MsalService,
    private msalGuard: MsalGuard,
    private router: Router,
    private logger: LoggerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return Promise.resolve(this.msalGuard.canActivate(next, state)).then((canActivate) => {
      if (canActivate) {
        if (!environment.required_roles_enabled) {
          if (!environment.production) {
            this.logger.info(
              `${this.logPrefix} Access to ${state.url} granted (Environment is not configured to require roles)`
            );
          }

          return Promise.resolve(true);
        }

        const user = this.authService.getUser();
        // tslint:disable-next-line: no-string-literal
        const userRoles = (((user && user.idToken && user.idToken['roles']) || ['user']) as Array<
          string
        >).map((roleName) => String(roleName).toLowerCase());

        const config = next.data as IRoleGuardConfig;
        const requiredRoles: Array<string> = (config.requiredRoles || []).map((roleName) =>
          String(roleName).toLowerCase()
        );
        const strategy = config.requiredRolesStrategy || AccessDecisionStrategy.ONE_OF;

        this.logger.info(
          `${this.logPrefix} Required role(s): ${JSON.stringify(
            requiredRoles
          )} - Strategy: ${strategy}`,
          ` - UserRoles: '${JSON.stringify(userRoles)}'`
        );
        const allowAccess = this.hasPermission(requiredRoles, userRoles, strategy);
        if (allowAccess) {
          this.logger.info(`${this.logPrefix} Access to ${state.url} granted`);

          return Promise.resolve(true);
        }

        this.logger.info(`${this.logPrefix} Access to ${state.url} denied, redirecting to /print`);
        this.router.navigate(['/print']);
      }

      return Promise.resolve(false);
    });
  }

  private hasPermission(
    requiredRoles: string[],
    roles: string[],
    strategy: AccessDecisionStrategy
  ) {
    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    }

    const allowAccess = [];
    for (const requiredRole of requiredRoles) {
      allowAccess.push(roles.includes(requiredRole));
    }

    if (AccessDecisionStrategy.ALL_OF === strategy) {
      return !allowAccess.includes(false);
    }

    return allowAccess.includes(true);
  }
}
