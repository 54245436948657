// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { BrowserOptions } from '@sentry/browser';
import { version } from '../../package.json';

const clientId = '6ea871aa-fbb8-45c1-bfef-cca625cd1f81';

const sentryConfig: BrowserOptions = {
  debug: true,
  dsn: 'https://a9dc15c46ec74b2caf447cfc94a058b4@bugs.api.education.lego.com/36',
  environment: 'DEV',
  release: version,
};

export const environment = {
  clientId,
  enable_oauth: true,
  preflightIntegrationIsEnabled: true,
  previewUrl: 'https://dkawww-pic.corp.lego.com/any/256x256/raw/',
  production: false,
  required_roles_enabled: false,
  rest_api_oauth_scope: `${clientId}/user_impersonation`,
  sentryConfig,
};
