import { ClicheDataHelper } from 'projects/processing-card/src/app/model/cliche-data-helper';

export class ClicheData {
  clicheId: string;
  platformMachineTypeId: string;
  lenFileDate: Date;
  lenPreparedByUserId: string;
  lenFileModifiedDate: Date;
  lenModifiedByUserId: string;

  constructor(data: Partial<ClicheData>) {
    Object.assign(this, data);
  }

  public createFromClicheDataHelper(data: ClicheDataHelper): void {
    this.lenFileDate = data.filmDate;
    this.lenPreparedByUserId = data.filmCreator;
    this.lenFileModifiedDate = data.filmDateModified;
    this.lenModifiedByUserId = data.filmModifier;
  }
}
