import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { IPlantSelection } from '@shared/plant-selection';
import { PlantSelectionService } from '@shared/plant-selection.service';
import { NzMessageService } from 'ng-zorro-antd';
import { SearchState } from 'projects/processing-card/src/app/ngxs/states/search-state';
import { Observable, Subscription } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';
import { SearchResult } from '../../../model/search-result';
import { SetPlants, SetSelectedPlant } from '../../../ngxs/actions/plant-actions';
import { PlantState } from '../../../ngxs/states/plant-state';
import { BackendService } from '../../../services/backend.service';
import { SearchResultService } from '../../../services/search-result.service';

@Component({
  selector: 'app-plant-selection',
  styleUrls: ['./plant-selection.component.less'],
  templateUrl: './plant-selection.component.html',
})
export class PlantSelectionComponent implements OnInit, OnDestroy {
  control = new FormControl();
  plantSelections: IPlantSelection[];
  selectedPlantKey = 'selectedPlant';
  defaultValueString: string;
  displayName: string;
  searchResult: SearchResult;
  searchResultSubscription: ISubscription;

  @Select(PlantState.getPlants) plants: Observable<IPlantSelection[]>;

  @Select(SearchState.getDisableSearch) disableSearch$: Observable<boolean>;

  private selectedPlant: IPlantSelection;
  private subscriptions: Subscription;
  private plantSelectionSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private service: BackendService,
    private searchResultService: SearchResultService,
    private plantSelectionService: PlantSelectionService,
    private messageService: NzMessageService,
    private logger: LoggerService,
    private store: Store
  ) {
    this.plantSelections = (this.route.snapshot.data.plantSelections || []) as IPlantSelection[];
    this.store.dispatch(new SetPlants(this.plantSelections));

    this.subscriptions = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateLanguagePlantSelections();
    });

    this.plantSelectionSubscription = this.plantSelectionService.getPlant().subscribe((plant) => {
      this.selectedPlant = plant;
      this.store.dispatch(new SetSelectedPlant(this.selectedPlant));
      this.updateLanguagePlantSelections();
    });
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.control.valueChanges.subscribe((plant) => {
        this.plantSelectionService.setPlantForUser(plant);
        this.store.dispatch(new SetSelectedPlant(plant));
      })
    );
    this.searchResultSubscription = this.searchResultService
      .getSearchResult()
      .subscribe((searchResult) => {
        if (searchResult) {
          this.searchResult = searchResult;
        }
      });
  }

  updateLanguagePlantSelections() {
    this.tranlateKey('displayNameLanguage').subscribe((data) => {
      this.displayName = data;
      if (this.selectedPlant) {
        this.defaultValueString = this.selectedPlant[`${this.displayName}`];
      } else {
        this.tranlateKey('defaultValueString').subscribe((defaultValue) => {
          this.defaultValueString = defaultValue;
        });
      }
    });
  }

  public tranlateKey(key: string): Observable<any> {
    return this.translate.get(`header.plant-selection.${key}`);
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.plantSelectionSubscription) {
      this.plantSelectionSubscription.unsubscribe();
    }
  }

  trackById(index, item) {
    if (item) {
      return item.id;
    }

    return undefined;
  }

  searchRefresh() {
    if (this.searchResult) {
      const plant = JSON.parse(localStorage.getItem(this.selectedPlantKey));
      this.service
        .searchPhysicalMaterial(plant.plantId, this.searchResult.decorationPhysicalId)
        .subscribe(
          (data: SearchResult) => {
            this.searchResult = data;
            this.logger.info('Result:', data);
            this.searchResultService.changeSearchResult(this.searchResult);
          },
          (err: HttpErrorResponse) => {
            if (err.status === 400 || err.status === 404) {
              this.searchResult = new SearchResult(undefined);
              this.searchResult.error = this.searchResult.codeToTextMapping[err.status];
            } else {
              const errorMessage = `There was an error searching for processing cards for plant with id ${plant.plantId}. Please report this error.`;
              this.logger.warn(errorMessage, err);
              this.messageService.error(errorMessage, {
                nzDuration: 10000,
                nzPauseOnHover: true,
              });
            }
          }
        );
    }
  }
}
