import { Injectable, OnDestroy, OnInit } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
// tslint:disable-next-line: no-implicit-dependencies
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { LoggerService } from './logger/logger.service';
import { IPlantSelection } from './plant-selection';

@Injectable({
  providedIn: 'root',
})
export class PlantSelectionService implements OnDestroy {
  private selectedPlanStorageKey = 'selectedPlant';
  private subject = new BehaviorSubject<IPlantSelection | undefined>(this.getPlantForUser());
  private subscriptions = new Subscription();

  constructor(private logger: LoggerService, private translate: TranslateService) {}

  public getPlant(): Observable<IPlantSelection> {
    return this.subject;
  }

  public setPlantForUser(plant: IPlantSelection) {
    localStorage.setItem(this.selectedPlanStorageKey, JSON.stringify(plant));
    this.logger.info('Emitting new plant selection', plant);
    this.subject.next(plant);
  }

  public tranlateKey(key: string): Observable<any> {
    return this.translate.get(`header.plant-selection.${key}`);
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  public getPlantForUser() {
    const plantJson = localStorage.getItem(this.selectedPlanStorageKey);
    if (plantJson) {
      return JSON.parse(plantJson) as IPlantSelection;
    }

    return undefined;
  }
}
