import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { NzMessageService } from 'ng-zorro-antd';
import {
  ISearchStateModel,
  SearchState,
} from 'projects/processing-card/src/app/ngxs/states/search-state';
import { Observable, Subscription } from 'rxjs';
import { BackendService } from '../../../services/backend.service';

@Component({
  selector: 'app-design-specification',
  styleUrls: ['./design-specification.component.less'],
  templateUrl: './design-specification.component.html',
})
export class DesignSpecificationComponent implements OnInit, OnDestroy {
  pdfSrc: Blob;
  decorationDesignId: string;
  searchStateSubscription: Subscription;

  @Select(SearchState) searchState$: Observable<ISearchStateModel>;

  constructor(
    private backendService: BackendService,
    private errorMessageService: NzMessageService,
    private logger: LoggerService,
    private ts: TranslateService
  ) {}

  getSearchResult() {
    this.searchStateSubscription = this.searchState$.subscribe(
      (data) => {
        if (data) {
          this.decorationDesignId = data.decorationDesignId;
          if (this.decorationDesignId !== undefined) {
            this.fetchProcessingCard(this.decorationDesignId);
          } else {
            this.pdfSrc = undefined;
          }
        }
      },
      (error) => {
        this.decorationDesignId = undefined;
      }
    );
  }

  ngOnInit() {
    this.getSearchResult();
  }

  ngOnDestroy() {
    if (this.searchStateSubscription) {
      this.searchStateSubscription.unsubscribe();
    }
  }

  fetchProcessingCard(decorationId: string) {
    this.backendService.getProcessingCardSinglePdf(decorationId).subscribe(
      (data) => {
        this.logger.info('data: ', data);
        this.pdfSrc = data;
      },
      (error: HttpErrorResponse) => {
        const errorMessage = `${this.ts.instant(
          'main-content.design-specification.fetchCardErrorP1'
        )}${decorationId}${this.ts.instant('main-content.design-specification.fetchCardErrorP2')}`;
        this.logger.warn(errorMessage, error);
        this.errorMessageService.error(errorMessage, {
          nzDuration: 10000,
          nzPauseOnHover: true,
        });
        this.pdfSrc = undefined;
      }
    );
  }
}
