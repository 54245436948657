import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericPlatform } from '../model/generic-platform';
import { BackendService } from '../services/backend.service';

@Injectable({
  providedIn: 'root',
})
export class GenericPlatformResolver implements Resolve<GenericPlatform[]> {
  constructor(private service: BackendService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GenericPlatform[]> | Promise<GenericPlatform[]> | GenericPlatform[] {
    return this.service.getGenericPlatforms();
  }
}
