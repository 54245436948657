export class ProductBriefData {
  static validateDecorationDesignId(decorationDesignId: string) {
    const onlyDecorationDesignIdRegExp = new RegExp(
      `^${ProductBriefData.decorationDesignIdRegExp}$`
    );

    return onlyDecorationDesignIdRegExp.test(decorationDesignId);
  }

  private static materialGroupIdToIconMapping = {
    '0122': 'D',
    '0123': 'T',
    '0151': 'P',
    '0154': 'F',
    '0191': 'S',
  };

  private static decorationDesignIdRegExp = '10[0-9]{6}';

  decorationTemplateFileName: string;
  statusCode: string;
  decorationDescription: string;
  decorationDesignId: string;
  materialGroupId: string;
  materialGroupDescription: string;
  versionText: string;

  isInFinalStatus = false;
  isBeingFetchedFromSap = false;
  hasBeenIdentifiedInSap = false;
  isDuplicate = false;
  sapDataErrors: string[] = [];

  constructor(obj: Partial<ProductBriefData>) {
    Object.assign(this, obj);
    if (this.decorationDesignId === undefined) {
      this.initializeDecorationDesignIdFromFileName();
    }
  }

  decorationDesignIdIsValid(): boolean {
    return ProductBriefData.validateDecorationDesignId(this.decorationDesignId);
  }

  isValid(): boolean {
    return (
      this.hasBeenIdentifiedInSap &&
      this.statusCode &&
      !this.isInFinalStatus &&
      this.decorationDesignIdIsValid()
    );
  }

  reset() {
    this.statusCode = undefined;
    this.decorationDescription = undefined;
    this.materialGroupId = undefined;
    this.materialGroupDescription = undefined;
    this.versionText = undefined;
    this.isInFinalStatus = false;
    this.isBeingFetchedFromSap = false;
    this.hasBeenIdentifiedInSap = false;
    this.isDuplicate = false;
    this.sapDataErrors = [];
  }

  initializeDecorationDesignIdFromFileName(): void {
    if (this.decorationTemplateFileName !== undefined && this.decorationDesignId === undefined) {
      const matches = this.decorationTemplateFileName.match(
        new RegExp(ProductBriefData.decorationDesignIdRegExp)
      );

      if (matches && matches.length > 0) {
        this.decorationDesignId = matches[0];
      }
    }
  }

  getErrorText(): string {
    if (this.sapDataErrors) {
      return this.sapDataErrors.join(' ');
    }

    return undefined;
  }

  getMaterialGroupIcon(): string {
    return ProductBriefData.materialGroupIdToIconMapping[this.materialGroupId];
  }
}
