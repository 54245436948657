import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { ProductBriefStatusChangeInfo } from '../components/main-content/advanced-search/model/product-brief-status-change-info';
import { CreateSuccessStatus } from '../components/main-content/upload/model/create-success-status';
import { ProductBriefCreateInfo } from '../components/main-content/upload/model/product-brief-create-info';
import { DecorationDesignDataFromSap } from '../model/decoration-design-data-from-sap';
import { DesignProductBriefData } from '../model/design-product-brief-data';
import { SelectionOption } from '../model/selection-option';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  public constructor(private httpClient: HttpClient, private logger: LoggerService) {}

  public fetchUser() {
    return this.httpClient.get('/api/v1/user');
  }

  getDesignProductBriefData(decoDesignId: string): Observable<DesignProductBriefData> {
    return this.get<DesignProductBriefData>(`api/v1/productbrief/${decoDesignId}`);
  }

  getSapMasterDataByDecorationId(decoDesignId: string): Observable<DecorationDesignDataFromSap> {
    return this.get<DecorationDesignDataFromSap>(
      `api/v1/material/design/decoration/${decoDesignId}`
    );
  }

  getGeneratedProductBriefPdf(
    fileName: string,
    decoDesignId: string,
    statusCode: string
  ): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', ['application/pdf', 'application/json']);

    const fileNameOrNull = fileName || 'NULL';
    const decoDesignIdOrNull = decoDesignId || 'NULL';
    const statusCodeOrNull = statusCode || 'NULL';
    const url = `api/v1/document/generated/${decoDesignIdOrNull}/${statusCodeOrNull}/${fileNameOrNull}`;

    return this.httpClient
      .get(url, { headers, responseType: 'blob' })
      .pipe(catchError((error) => this.logError(error)));
  }

  getStatusValues(): Observable<SelectionOption[]> {
    return this.get<SelectionOption[]>('/api/v1/productbrief/options/status');
  }

  postProductBriefs(productBriefs: ProductBriefCreateInfo[]): Observable<CreateSuccessStatus[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'my-auth-token',
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post<CreateSuccessStatus[]>('/api/v1/productbrief', productBriefs, httpOptions)
      .pipe(catchError((error) => this.logError(error)));
  }

  postProductBriefsStatusChange(
    productBriefs: ProductBriefStatusChangeInfo[]
  ): Observable<CreateSuccessStatus[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'my-auth-token',
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post<CreateSuccessStatus[]>('/api/v1/productbrief/updateStatus', productBriefs, httpOptions)
      .pipe(catchError((error) => this.logError(error)));
  }

  private get<T>(url: string, ignoreStatusCodes?: number[]) {
    return this.httpClient.get<T>(url).pipe(catchError((error) => this.logError(error)));
  }

  private logError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      if (error.status !== 404) {
        this.logger.error('An error occurred:', error.error.message);
      }
    } else if (error.error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status !== 404) {
        if (error.error.message) {
          this.logger.error(
            `Backend returned code ${error.status}, ` + `message: ${error.error.message}`
          );
        } else {
          // The backend returned an unsuccessful response code without the message set.
          // The response body may contain clues as to what went wrong,
          this.logger.error(
            `Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`
          );
        }
      }
    } else {
      this.logger.error(`Backend returned code ${error.status}, error: ${JSON.stringify(error)}`);
    }

    return throwError(error);
  }
}
