import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { ProductBriefStatusChangeInfo } from '../components/main-content/advanced-search/model/product-brief-status-change-info';
import { CreateSuccessStatus } from '../components/main-content/upload/model/create-success-status';
import { SearchResult } from '../model/search-result';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private httpClient: HttpClient, private logger: LoggerService) {}

  buildSearchQuery(
    uploaderID: string,
    launchID: string,
    launchDescription: string,
    designerID: string,
    decorationDesignID: string
  ): Observable<SearchResult[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    let searchParams = new HttpParams();

    searchParams = uploaderID ? searchParams.append('uploaderID', uploaderID) : searchParams;
    searchParams = launchID ? searchParams.append('launchID', launchID) : searchParams;
    searchParams = launchDescription
      ? searchParams.append('launchDescription', launchDescription)
      : searchParams;
    searchParams = designerID ? searchParams.append('designerID', designerID) : searchParams;
    searchParams = decorationDesignID
      ? searchParams.append('decorationDesignID', decorationDesignID)
      : searchParams;

    return this.httpClient
      .get<SearchResult[]>('/api/v1/advancedSearch/search', {
        headers,
        params: searchParams,
      })
      .pipe(catchError((error) => this.logError(error)));
  }

  private logError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.logger.error('An error occurred:', error.error.message);
    } else if (error.error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.error.message) {
        this.logger.error(
          `Backend returned code ${error.status}, ` + `message: ${error.error.message}`
        );
      } else {
        // The backend returned an unsuccessful response code without the message set.
        // The response body may contain clues as to what went wrong,
        this.logger.error(
          `Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`
        );
      }
    } else {
      this.logger.error(`Backend returned code ${error.status}, error: ${JSON.stringify(error)}`);
    }

    return throwError(error);
  }
}
