import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IRoleGuardConfig, RoleGuard } from '@shared/auth/guards/role-guard.service';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { SystemUsersComponent } from './system-users/system-users.component';

const routes: Routes = [
  {
    canActivate: environment.enable_oauth ? [RoleGuard] : undefined,
    component: AppComponent,
    data: {
      requiredRoles: ['admin'],
    } as IRoleGuardConfig,
    path: 'admin',
    pathMatch: 'full',
  },
  { path: 'admin/frameredirect', component: AppComponent },
  { path: 'admin/accessdenied', component: AppComponent },
  {
    canActivate: environment.enable_oauth ? [RoleGuard] : undefined,
    children: [
      {
        component: SystemUsersComponent,
        data: {
          requiredRoles: ['admin'],
        } as IRoleGuardConfig,
        path: 'users',
      },
    ],
    component: AppComponent,
    data: {
      requiredRoles: ['admin'],
    } as IRoleGuardConfig,
    path: 'admin/main',
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
