import { Component } from '@angular/core';
import { CollapseService } from '@shared/collapse.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-side-menu',
  styleUrls: ['./side-menu.component.less'],
  templateUrl: './side-menu.component.html',
})
export class SideMenuComponent {
  // public isCollapsed = false;

  public constructor(public collapseService: CollapseService) {}
}
