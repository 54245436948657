import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
import { ColourOption } from '../../../../model/colour-option';
import { ISelectionOption } from '../../../../model/selection-option';

@Component({
  providers: [
    { provide: LoggerService, useClass: ConsoleLoggerService },
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line: no-forward-ref
      useExisting: forwardRef(() => PrinterDataComponent),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      // tslint:disable-next-line: no-forward-ref
      useExisting: forwardRef(() => PrinterDataComponent),
    },
  ],
  selector: 'app-printer-data',
  styleUrls: ['./printer-data.component.less'],
  templateUrl: './printer-data.component.html',
})
export class PrinterDataComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() hardnessOptions: ISelectionOption[];
  @Input() thinnerOptions: ISelectionOption[];
  @Input() colourOptions: ColourOption[];
  @Input() form: FormGroup;
  @Input() frontPrinter: boolean;
  @Input() printerNumberOffset = 1;

  constructor(private fb: FormBuilder, private ts: TranslateService) {}

  validate(control: AbstractControl): ValidationErrors {
    return this.form.valid
      ? // tslint:disable-next-line: no-null-keyword
        null
      : {
          invalidForm: {
            message: this.ts.instant(
              'main-content.processing-cards.printerData.processingDataFieldError'
            ),
            valid: false,
          },
        };
  }

  // tslint:disable-next-line: no-empty
  public onTouched: () => void = () => {};

  writeValue(obj: any): void {
    // tslint:disable-next-line: no-unused-expression
    obj && this.form.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  ngOnInit() {
    console.log('form data', this.form);
    const formArray: FormArray = this.getFormArray();
    if (formArray) {
      for (const group of formArray.controls) {
        group
          .get('thinner')
          .setValue(
            this.thinnerOptions.filter((thinnerDef) => thinnerDef.defaultSelection)[0].code
          );

        group
          .get('hardness')
          .setValue(
            this.hardnessOptions.filter((hardnessDef) => hardnessDef.defaultSelection)[0].code
          );
      }
    }
  }

  getColourRGB(colourId: string): string {
    return this.colourOptions.find((colour) => colour.colourID === colourId).rgb;
  }

  public trackByFunction(index, item) {
    if (!item) {
      // tslint:disable-next-line: no-null-keyword
      return null;
    }
  }

  getFormArrayName() {
    return this.frontPrinter ? 'frontPrinters' : 'backPrinters';
  }

  getFormArray() {
    if (this.form) {
      return this.form.get(this.getFormArrayName()) as FormArray;
    }
  }
}
