import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { NzMessageService } from 'ng-zorro-antd';
import {
  SearchLoading,
  SetSearchState,
} from 'projects/processing-card/src/app/ngxs/actions/search-actions';
import { PlantState } from 'projects/processing-card/src/app/ngxs/states/plant-state';
import { SearchState } from 'projects/processing-card/src/app/ngxs/states/search-state';
import { Observable } from 'rxjs';
import { SearchResult } from '../../../model/search-result';
import { BackendService } from '../../../services/backend.service';
import { SearchResultService } from '../../../services/search-result.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-search',
  styleUrls: ['./search.component.less'],
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {
  public searchForm: FormGroup;
  selectedPlantKey = 'selectedPlant';
  result: SearchResult;
  searchTerm: string;

  @Select(SearchState.getDisableSearch) disableSearch$: Observable<boolean>;
  @Select(SearchState.getSearchLoading) searchLoading$: Observable<boolean>;

  public constructor(
    public rest: BackendService,
    private fb: FormBuilder,
    private searchResultService: SearchResultService,
    private message: NzMessageService,
    private logger: LoggerService,
    private store: Store,
    private router: Router
  ) {}

  public ngOnInit() {
    this.searchForm = this.fb.group({
      // tslint:disable: no-unbound-method
      // tslint:disable: no-null-keyword
      searchInput: [null, [Validators.required]],
    });
  }

  createMessage(type: string, text: string) {
    this.message.create(type, text);
  }

  public searchPhysicalMaterial() {
    if (this.store.selectSnapshot(SearchState.getDisableSearch)) {
      return;
    }

    this.searchTerm = this.searchForm.value.searchInput;
    const selectedPlant = this.store.selectSnapshot(PlantState.getSelectedPlant);

    if (!selectedPlant) {
      this.createMessage('warning', 'Plant is not selected\nPlease select a plant first');
    } else {
      const plantId = selectedPlant.plantId;
      this.store.dispatch(new SearchLoading(true));
      this.rest.searchPhysicalMaterial(plantId, this.searchTerm).subscribe(
        (data: SearchResult) => {
          this.store.dispatch(new SetSearchState(data));
          this.result = data;
          this.logger.info('Result:', this.result);
          this.searchResultService.changeSearchResult(this.result);
          this.store.dispatch(new SearchLoading(false));
        },
        (err: HttpErrorResponse) => {
          // tslint:disable-next-line:prefer-switch
          if (err.status === 400 || err.status === 403 || err.status === 404) {
            this.result = new SearchResult(null);
            this.result.error = err.error.message;
            this.router.navigateByUrl('/processingcard/main');
            this.store.dispatch(new SetSearchState(this.result));
            this.searchResultService.changeSearchResult(this.result);
          } else {
            const errorMessage = `There was an error searching for processing cards for plant with id ${plantId}. Please report this error.`;
            this.logger.warn(errorMessage, err);
            this.message.error(errorMessage, {
              nzDuration: 10000,
              nzPauseOnHover: true,
            });
          }
          this.store.dispatch(new SearchLoading(false));
        }
      );
    }
  }
}
