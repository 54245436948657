import { IPlantSelection } from '@shared/plant-selection';

export class SetPlants {
  static readonly type = '[Plant] Set Plants';

  constructor(public plants: IPlantSelection[]) {}
}

// tslint:disable-next-line:max-classes-per-file
export class SetSelectedPlant {
  static readonly type = '[Plant] Set Selected Plant';

  constructor(public plant: IPlantSelection) {}
}
