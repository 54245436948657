import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ApplicationRef, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BroadcastService } from '@azure/msal-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RoleGuard } from '@shared/auth/guards/role-guard.service';
import { MsalComponent } from '@shared/auth/msal.component';
import { NoCacheHeadersInterceptor } from '@shared/cache-control/NoCacheHeadersInterceptor';
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
import { ProfileDropdownComponent } from '@shared/profile/profile-dropdown.component';
import { SharedModule } from '@shared/shared.module';
// tslint:disable-next-line:ordered-imports
import { en_US, NgZorroAntdModule, NzFormModule, NzMessageModule, NZ_I18N } from 'ng-zorro-antd';
// tslint:disable-next-line:no-submodule-imports
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxSummernoteModule } from 'ngx-summernote';
import { version } from 'src/../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';
import { HeaderComponent } from './components/header/header.component';
import { LanguageSelectorComponent } from './components/header/language-selector/language-selector.component';
import { PlantSelectionComponent } from './components/header/plant-selection/plant-selection.component';
import { SearchComponent } from './components/header/search/search.component';
import { ContentHeaderComponent } from './components/main-content/content-header/content-header.component';
import { DesignSpecificationComponent } from './components/main-content/design-specification/design-specification.component';
import { GlobalNotificationsComponent } from './components/main-content/global-notifications/global-notifications.component';
import { NPIStatusComponent } from './components/main-content/npi-status/npi-status.component';
import { CreateNewSelectionComponent } from './components/main-content/processing-cards/create-new-selection/create-new-selection.component';
import { CreatePageComponent } from './components/main-content/processing-cards/create-page/create-page.component';
import { EditPageComponent } from './components/main-content/processing-cards/edit-page/edit-page.component';
import { FilmDataComponent } from './components/main-content/processing-cards/film-data/film-data.component';
import { NotificationDataComponent } from './components/main-content/processing-cards/notification-data/notification-data.component';
import { PrinterDataComponent } from './components/main-content/processing-cards/printer-data/printer-data.component';
import { ProcessingCardComponent } from './components/main-content/processing-cards/processing-card/processing-card.component';
import { ProcessingCardsComponent } from './components/main-content/processing-cards/processing-cards.component';
import { ProcessingDataComponent } from './components/main-content/processing-cards/processing-data/processing-data.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { StartComponent } from './components/start/start.component';
import { TestPdfComponent } from './components/test-pdf/test-pdf.component';
import { HardnessValuesResolver } from './resolvers/HardnessValuesResolver';
import { InkColourResolver } from './resolvers/InkColourResolver';
import { NpiStatusValuesResolver } from './resolvers/NpiStatusValuesResolver';
import { ProductionToleranceValuesResolver } from './resolvers/ProductionToleranceValuesResolver';
import { TechnicalColourResolver } from './resolvers/TechnicalColourResolver';
import { ThinnerValuesResolver } from './resolvers/ThinnerValuesResolver';

const providers = [
  { provide: LoggerService, useClass: ConsoleLoggerService },
  BroadcastService,
  CanDeactivateGuard,
  HardnessValuesResolver,
  ProductionToleranceValuesResolver,
  ThinnerValuesResolver,
  NpiStatusValuesResolver,
  { provide: NZ_I18N, useValue: en_US },
  { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
  InkColourResolver,
  RoleGuard,
  TechnicalColourResolver,
  DatePipe,
];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    StartComponent,
    HeaderComponent,
    SearchComponent,
    SideMenuComponent,
    PlantSelectionComponent,
    TestPdfComponent,
    DesignSpecificationComponent,
    ProcessingCardComponent,
    GlobalNotificationsComponent,
    NPIStatusComponent,
    ProcessingCardsComponent,
    ProcessingDataComponent,
    LanguageSelectorComponent,
    ProcessingCardComponent,
    PrinterDataComponent,
    ContentHeaderComponent,
    CreateNewSelectionComponent,
    NotificationDataComponent,
    CreatePageComponent,
    EditPageComponent,
    FilmDataComponent,
  ],
  entryComponents: [ProfileDropdownComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    NzMessageModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    NgxExtendedPdfViewerModule,
    // SentryModule.forRoot(environment.sentryConfig),
    NgZorroAntdModule,
    NzFormModule,
    NgxExtendedPdfViewerModule,
    NzSpinModule,
  ],
  providers,
})
export class AppModule {}

// tslint:disable-next-line: max-classes-per-file
@NgModule({})
export class ProcessingCardSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers,
    };
  }

  public static ngDoBootstrap(ref: ApplicationRef) {
    const isIFrame = window !== window.parent && !window.opener;
    if (isIFrame) {
      ref.bootstrap(MsalComponent);
    } else {
      ref.bootstrap(AppComponent);
    }
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${version}`);
}
