import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-shared',
  styles: [],
  template: `
    <p>
      shared works!
    </p>
  `,
})
export class SharedComponent implements OnInit {
  // tslint:disable-next-line: no-empty
  ngOnInit() {}
}
