import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { MsalService } from '@azure/msal-angular';
// tslint:disable-next-line: no-implicit-dependencies
import jwtDecode from 'jwt-decode';
// tslint:disable-next-line: no-implicit-dependencies
import { from, Observable } from 'rxjs';
// tslint:disable-next-line: no-implicit-dependencies
import { switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger/logger.service';

export interface ITokenHolder {
  sam: string;
  tkn: string;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly storageKey: string = 'LEGO_USR';
  private readonly GRAPH_HEADER: string = 'X-GRAPH'; // Must match what the backend expects
  private readonly WEBAPI_HEADER: string = 'X-WEB'; // Must match what the backend expects

  public constructor(private authService: MsalService, private logger: LoggerService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.enable_oauth &&
      (req.url.startsWith('/api/v1/') ||
        req.url.startsWith('api/v1/') ||
        req.url.startsWith('/api/system/v1') ||
        req.url.startsWith('api/system/v1'))
    ) {
      let graphToken: string;

      return from(
        this.getGraphToken().then((idToken) => {
          graphToken = idToken;

          return this.getRestAPIToken();
        })
      ).pipe(
        take(1),
        switchMap((webApiAccessToken) => {
          if (webApiAccessToken) {
            if (!environment.production) {
              this.logger.info(
                `this.authService.acquireTokenSilent(["
                ${environment.rest_api_oauth_scope}"]) returned ${webApiAccessToken}`
              );
            }
            if (graphToken) {
              const authHeaders = {
                Authorization: `Bearer ${webApiAccessToken}`,
              };
              authHeaders[`${this.GRAPH_HEADER}`] = graphToken;
              // authHeaders[`${this.WEBAPI_HEADER}`] = webApiAccessToken;

              if (!environment.production && req.headers && req.headers.keys.length > 0) {
                this.logger.info('Original Headers', req.headers);
              }

              req = req.clone({
                setHeaders: authHeaders,
                withCredentials: true,
              });
            }
          } else {
            this.logger.info(
              `AuthInterceptor::intercept - No token acquired - not adding authorization header to
              ${req.method} ${req.url} ${req}`
            );
          }

          return next.handle(req);
        })
      );
    }

    return next.handle(req);
  }

  private getGraphToken(): Promise<string> {
    if (this.authService.getUser()) {
      return this.getCachedToken().then((token) => {
        if (token) {
          if (this.isTokenToBeRenewed(token)) {
            localStorage.removeItem(this.storageKey);

            return this.getAndStoreNewGraphToken();
          }

          return token;
        }

        return this.getAndStoreNewGraphToken();
      });
    }

    return Promise.reject('User is not authenticated');
  }

  private getCachedToken(): Promise<string | void> {
    const usr = localStorage.getItem(this.storageKey);
    if (usr) {
      return Promise.resolve(usr);
    }

    return Promise.resolve();
  }

  private isTokenToBeRenewed(token: string): boolean {
    let expiresMinusDrift: number;
    let notBefore: number;
    const now = new Date().getTime();

    let renewToken = true;
    if (token) {
      const DRIFT_SECONDS = 30; // in case the users clock is drifting a little compared to the azure clock,
      // we subtract this amount of seconds before checking for expiry

      const decoded = jwtDecode(token);
      if (decoded) {
        if (decoded.exp) {
          const expiresTs = decoded.exp * 1000;
          expiresMinusDrift = expiresTs - DRIFT_SECONDS * 1000;
        }

        notBefore = decoded.nbf ? decoded.nbf * 1000 : undefined;

        // console.debug('Graph token check - expires: ' + expiresMinusDrift + ', notBefore: ' + notBefore + ', now: ' + now);
        if (expiresMinusDrift && now < expiresMinusDrift) {
          if (notBefore) {
            if (now > notBefore) {
              renewToken = renewToken && false;
            }
          } else {
            renewToken = renewToken && false;
          }
        }
      }
    }
    if (renewToken) {
      this.logger.info(
        `Graph token should be renewed - expired: ${expiresMinusDrift}, notBefore: ${notBefore}, now: ${now} renewToken ${renewToken}`
      );
    }

    return renewToken;
  }

  private getRestAPIToken() {
    //              this.spinner.setHttpStatus(true);
    return this.authService.acquireTokenSilent([environment.rest_api_oauth_scope]).catch((err) => {
      if (typeof err === 'string' && err.endsWith('consent_required')) {
        // Retry, this time show the request to the user so they can give their consent
        // return this.authService.acquireTokenRedirect([ environment.rest_api_oauth_scope ]) -
        // The currentStep msal angular returns void - using popup version instead
        return this.authService
          .acquireTokenPopup([environment.rest_api_oauth_scope])
          .catch((tokenConsentErr) => {
            if (
              typeof tokenConsentErr === 'string' &&
              tokenConsentErr.endsWith('consent_required')
            ) {
              // TODO: The user denied/cancelled the consent request. Show them a message explaining why they cannot proceed
              this.logger.warn(
                'TODO: The user denied/cancelled the consent request. Show them a message explaining why they cannot proceed'
              );
            } else {
              this.logger.error(
                `An error occurred when asking the user for consent ${tokenConsentErr}`
              );
            }
          });
      }
      this.logger.error('Failed acquiring token for REST API silently', err);
    });
    //        .finally(() => this.spinner.setHttpStatus(false));
  }

  private getAndStoreNewGraphToken() {
    // this.spinner.setHttpStatus(true);
    return this.authService
      .acquireTokenSilent([
        'user.read', // "https://graph.microsoft.com/Directory.Read.All", "https://graph.microsoft.com/directory.read",
      ])
      .then((token) => {
        if (token) {
          this.logger.info(`this.authService.acquireTokenSilent(["user.read"]) returned: ${token}`);
          localStorage.setItem(this.storageKey, token);

          return token;
        }
      })
      .catch((err) => {
        this.logger.error('Failed acquiring token for Microsoft Graph API silently', err);
      });
    // .finally(() => this.spinner.setHttpStatus(false));
  }
}
