import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalNotification } from '../model/global-notification';
import { ISelectionOption } from '../model/selection-option';

@Injectable({
  providedIn: 'root',
})
export class GlobalNotificationService {
  private NOTIFICATIONURL = 'api/v1/globalnotification/';

  constructor(private httpClient: HttpClient, private logger: LoggerService) {}

  putGlobalNotifications(
    globalNotifications: GlobalNotification[]
  ): Observable<GlobalNotification[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'my-auth-token',
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .put<GlobalNotification[]>(
        `${this.NOTIFICATIONURL}notifications/${globalNotifications[0].decoPhysicalId}`,
        globalNotifications,
        httpOptions
      )
      .pipe(catchError((error) => this.logError(error)));
  }

  getGlobalNotifications(decorationPhysicalId: string): Observable<GlobalNotification[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    return this.get<GlobalNotification[]>(
      `${this.NOTIFICATIONURL}notifications/${decorationPhysicalId}`
    ).pipe(catchError((error) => this.logError(error)));
  }

  deleteGlobalNotification(globalNotification: GlobalNotification): Observable<GlobalNotification> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    return this.httpClient
      .delete<GlobalNotification>(
        `${this.NOTIFICATIONURL}notifications/${globalNotification.decoPhysicalId}/${globalNotification.globalNotificationId}`
      )
      .pipe(catchError((error) => this.logError(error)));
  }

  getGlobalNotificationTypes(): Observable<ISelectionOption[]> {
    return this.get<ISelectionOption[]>(`${this.NOTIFICATIONURL}options/notificationTypes`);
  }

  private get<T>(url: string) {
    return this.httpClient.get<T>(url);
  }

  private logError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.logger.error('An error occurred:', error.error.message);
    } else if (error.error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.error.message) {
        this.logger.error(
          `Backend returned code ${error.status}, ` + `message: ${error.error.message}`
        );
      } else {
        // The backend returned an unsuccessful response code without the message set.
        // The response body may contain clues as to what went wrong,
        this.logger.error(
          `Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`
        );
      }
    } else {
      this.logger.error(`Backend returned code ${error.status}, error: ${JSON.stringify(error)}`);
    }

    return throwError(error);
  }
}
