import { ClicheData } from 'projects/processing-card/src/app/model/cliche-data';

export class ClicheDataHelper {
  filmDate: Date;
  filmDateModified: Date;
  filmCreator: string;
  filmModifier: string;

  constructor(clicheData?: ClicheData) {
    // tslint:disable: no-null-keyword
    if (clicheData) {
      this.filmDate = clicheData.lenFileDate;
      this.filmCreator = clicheData.lenPreparedByUserId;
      this.filmDateModified = clicheData.lenFileModifiedDate
        ? clicheData.lenFileModifiedDate
        : null;
      this.filmModifier = clicheData.lenModifiedByUserId ? clicheData.lenModifiedByUserId : '';
    } else {
      this.filmCreator = '';
      this.filmModifier = '';
      this.filmDate = null;
      this.filmDateModified = null;
    }
  }
}
