import { ErrorHandler, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { BrowserOptions } from '@sentry/browser';
import { SENTRY_CONFIG, SentryErrorHandler } from './sentry-error-handler';

@NgModule()
// tslint:disable-next-line: no-unnecessary-class
export class SentryModule {
  public static forRoot(sentryConfig?: BrowserOptions): ModuleWithProviders {
    return {
      ngModule: SentryModule,
      providers: [
        { provide: SENTRY_CONFIG, useValue: sentryConfig },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
      ],
    };
  }
}
