/* tslint:disable */
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from '@shared/logger/logger.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { PreflightService } from 'projects/deco-brief/src/app/services/preflight.service';
import { Subscription } from 'rxjs';
import { ProductBriefStatusChangeInfo } from '../../../../components/main-content/advanced-search/model/product-brief-status-change-info';
import { SearchResult } from '../../../../model/search-result';
import { AdvancedSearchData } from '../../../../model/search-table-data';
import { SelectionOption } from '../../../../model/selection-option';
import { BackendService } from '../../../../services/backend.service';
import { MessageService } from '../../../../services/message.service';
import { SearchService } from '../../../../services/search.service';
import { PdfViewerDialogComponent } from '../../../pdf-viewer-dialog/pdf-viewer-dialog.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-search-result-table',
  styleUrls: ['./search-result-table.component.less'],
  templateUrl: './search-result-table.component.html',
})
export class SearchResultTableComponent implements OnInit, OnDestroy {
  control = new FormControl();
  defaultValueString?: string;
  selectedStatus: SelectionOption;

  statusOptions: SelectionOption[];
  isAllDisplayDataChecked = false;
  isOperating = false;
  isIndeterminate = false;
  listOfDisplayData: AdvancedSearchData[] = [];
  listOfAllData: AdvancedSearchData[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  isPreflightIntegrationEnabled = false;

  numberOfChecked = 0;

  searchResult: SearchResult[] = [];
  subscription: Subscription;

  searchSub: Subscription;
  private subscriptions = new Subscription();

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    public rest: SearchService,
    private backendService: BackendService,
    private preflightService: PreflightService,
    private modalService: NzModalService,
    private errorMessageService: NzMessageService,
    private logger: LoggerService,
    private datePipe: DatePipe
  ) {
    this.isPreflightIntegrationEnabled = this.preflightService.isPreflightIntegrationEnabled();
    this.statusOptions = this.route.snapshot.data.statusOptions as SelectionOption[];
    this.subscription = this.messageService.subject.pipe().subscribe((message) => {
      if (message) {
        this.searchResult = message;
        this.updateSearchData(this.searchResult);
      } else {
        // clear messages when empty message received
        this.searchResult = [];
      }
    });
  }

  ngOnDestroy() {
    this.messageService.clear();
    this.subscription.unsubscribe();
  }

  updateSearchData(message: SearchResult[]) {
    this.listOfAllData = message.map((el, i) => {
      return this.transformAdvancedSearchData(el, i);
    });

    this.operateData();
  }

  currentPageDataChange($event: AdvancedSearchData[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData
      .filter((item) => !item.isDisabled())
      .every((item) => this.mapOfCheckedId[item.id]);

    this.isIndeterminate =
      this.listOfDisplayData
        .filter((item) => !item.isDisabled())
        .some((item) => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;

    this.numberOfChecked = this.listOfAllData.filter((item) => this.mapOfCheckedId[item.id]).length;
  }

  transformAdvancedSearchData(element: SearchResult, index: number): AdvancedSearchData {
    const record: AdvancedSearchData = new AdvancedSearchData();
    record.id = index;
    record.decorationDesignID = element.decorationDesignId;
    record.designDescription = element.designDescription;
    record.decorationType = element.decorationType;
    record.designerID = element.designerId;
    record.uploaderUserID = element.uploaderUserID;
    record.launchID = element.launchID;
    record.launchDescription = element.launchDescription;
    record.statusCode = element.statusCode;
    record.status = SelectionOption.getDisplayLabelForCode(this.statusOptions, element.statusCode);

    return record;
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData
      .filter((item) => !item.isDisabled())
      .forEach((item) => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }

  unCheckAll(): void {
    this.listOfDisplayData.forEach((item) => (this.mapOfCheckedId[item.id] = false));
    this.refreshStatus();
  }

  operateData(): void {
    this.isOperating = true;
    setTimeout(() => {
      this.listOfAllData.forEach((item) => (this.mapOfCheckedId[item.id] = false));
      this.refreshStatus();
      this.isOperating = false;
    }, 1000);
  }

  trackById(index, item) {
    if (item) {
      return item.id;
    }

    return undefined;
  }

  trackByCode(index: number, item: SelectionOption): string {
    if (item) {
      return item.code;
    }

    return undefined;
  }

  setStatusOnChecked() {
    const PBStatusChanges: ProductBriefStatusChangeInfo[] = [];
    const checkedElements = this.listOfAllData.filter((item) => this.mapOfCheckedId[item.id]);

    checkedElements.forEach((element) => {
      const PBStatusChange = new ProductBriefStatusChangeInfo(
        element.decorationDesignID,
        this.selectedStatus.code
      );
      PBStatusChanges.push(PBStatusChange);
    });

    this.backendService.postProductBriefsStatusChange(PBStatusChanges).subscribe(
      // tslint:disable-next-line:no-empty
      (data) => {},
      (error: HttpErrorResponse) => {
        const errorMessage = `There was an error changing the status. Please report this error.`;
        this.logAndShowError(errorMessage, error);
      }
    );

    this.listOfAllData
      .filter((item) => this.mapOfCheckedId[item.id])
      .forEach((pb) => {
        pb.statusCode = this.selectedStatus.code;
        pb.status = this.selectedStatus.displayLabelEn;
      });

    this.unCheckAll();
  }

  // tslint:disable-next-line: no-empty
  ngOnInit(): void {
    this.defaultValueString = 'Select status';

    this.subscriptions.add(
      this.control.valueChanges.subscribe((status) => {
        this.selectedStatus = status;
      })
    );
  }

  generateAndViewPdf(decorationDesignId: string) {
    this.backendService
      .getGeneratedProductBriefPdf(undefined, decorationDesignId, undefined)
      .subscribe(
        (data) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const dateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd_HHmmss');
          const fileName = `ds_${decorationDesignId}_${dateTime}.pdf`;
          PdfViewerDialogComponent.createPdfViewerModal(this.modalService, file, fileName);
        },
        (error: HttpErrorResponse) => {
          const errorMessage = `There was an error fetching the pdf for decoration design id ${decorationDesignId}. Please report this error.`;
          this.logAndShowError(errorMessage, error);
        }
      );
  }

  getPreflightReport(decorationDesignId: string) {
    this.preflightService.getPreflightReport(decorationDesignId).subscribe(
      (data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const dateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd_HHmmss');
        const fileName = `preflight_${decorationDesignId}_${dateTime}.pdf`;
        PdfViewerDialogComponent.createPdfViewerModal(this.modalService, file, fileName);
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.errorMessageService.warning(
            `Preflight report ${decorationDesignId} is not yet completed. Please try again later!`,
            {
              nzDuration: 5000,
              nzPauseOnHover: true,
            }
          );

          return;
        }

        const errorMessage = `There was an error fetching the preflight for decoration design id ${decorationDesignId}. Please report this error.`;
        this.logAndShowError(errorMessage, error);
      }
    );
  }

  private logAndShowError(errorMessage: string, error: HttpErrorResponse) {
    this.logger.warn(errorMessage, error);
    this.errorMessageService.error(errorMessage, {
      nzDuration: 10000,
      nzPauseOnHover: true,
    });
  }
}
