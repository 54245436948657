import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-root',
  styleUrls: ['./app.component.less'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'DB & DPC';

  constructor(private titleService: Title) {
    this.titleService.setTitle(this.title);
  }
}
