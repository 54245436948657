import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UploadFile } from 'ng-zorro-antd';
import { SelectionOption } from '../../../../model/selection-option';
import { ProductBriefData } from '../model/product-brief-data';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-upload-main',
  styleUrls: ['./upload-main.component.less'],
  templateUrl: './upload-main.component.html',
})
export class UploadMainComponent {
  currentStep = 0;
  validStep = -1;
  productBriefList: ProductBriefData[] = [];
  private uploadedFiles: UploadFile[] = [];
  private statusOptions: SelectionOption[];

  private FILE_SELECTION_STEP = 0;
  private VALIDATION_STEP = 1;

  constructor(private route: ActivatedRoute) {
    this.statusOptions = this.route.snapshot.data.statusOptions as SelectionOption[];
  }

  previous(): void {
    if (this.currentStep === this.VALIDATION_STEP) {
      this.removeFilesWhereProductBriefHasBeenRemoved();
    }

    this.currentStep -= 1;
  }

  next(): void {
    if (this.currentStep === this.FILE_SELECTION_STEP) {
      this.generateListOfProductBriefsFromFileList();
    }

    this.currentStep += 1;
  }

  uploadFilesStepIsValid(newUploadedFiles: UploadFile[]): void {
    this.uploadedFiles = newUploadedFiles;
    this.validStep = this.currentStep;
  }

  uploadFilesStepIsInvalid() {
    this.validStep = this.currentStep - 1;
  }

  validationStepIsValid(newProductBriefList: ProductBriefData[]): void {
    this.productBriefList = newProductBriefList;
    this.validStep = this.currentStep;
  }

  validationStepIsInvalid(newProductBriefList: ProductBriefData[]): void {
    this.productBriefList = newProductBriefList;
    this.validStep = this.currentStep - 1;
  }

  private removeFilesWhereProductBriefHasBeenRemoved() {
    if (this.productBriefList.length === 0) {
      this.uploadedFiles = [];
    } else {
      this.uploadedFiles = this.productBriefList.map((pb) => {
        return this.uploadedFiles.find((f) => f.name === pb.decorationTemplateFileName);
      });
    }
  }

  private generateListOfProductBriefsFromFileList() {
    this.productBriefList = this.uploadedFiles.map(
      (file) => new ProductBriefData({ decorationTemplateFileName: file.name })
    );
  }
}
