import { Injectable } from '@angular/core';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class CollapseService {
  public isCollapsed: boolean;
  public localStorageKey = 'pb.isCollapsed';

  public constructor(private localStorageService: LocalStorageService) {
    this.isCollapsed = this.localStorageService.get(this.localStorageKey) || false;
  }

  public toggle() {
    this.isCollapsed = !this.isCollapsed;
    this.saveToLocalStorage();
  }

  public open() {
    this.isCollapsed = true;
    this.saveToLocalStorage();
  }

  public close() {
    this.isCollapsed = false;
    this.saveToLocalStorage();
  }

  private saveToLocalStorage() {
    this.localStorageService.set(this.localStorageKey, this.isCollapsed);
  }
}
