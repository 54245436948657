import { Component, forwardRef, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
import { NgxSummernoteModule } from 'ngx-summernote';

@Component({
  providers: [
    { provide: LoggerService, useClass: ConsoleLoggerService },
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line: no-forward-ref
      useExisting: forwardRef(() => NotificationDataComponent),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      // tslint:disable-next-line: no-forward-ref
      useExisting: forwardRef(() => NotificationDataComponent),
    },
  ],
  selector: 'app-notification-data',
  styleUrls: ['./notification-data.component.less'],
  templateUrl: './notification-data.component.html',
})
export class NotificationDataComponent implements OnInit, ControlValueAccessor, Validator {
  notificationDataForm: FormGroup;
  productionNotification: FormControl;

  config = {
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
    height: 'auto',
    maximumImageFileSize: 1048576,
    minHeight: '100px',
    placeholder: '',
    tabsize: 2,
    toolbar: [
      // [groupName, [list of button]]
      ['misc', ['undo', 'redo']],
      [
        'font',
        ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear'],
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'hr']],
    ],
    uploadImagePath: '',
  };

  constructor(private fb: FormBuilder, private ts: TranslateService) {}

  validate(control: AbstractControl): ValidationErrors {
    return this.notificationDataForm.valid
      ? // tslint:disable-next-line: no-null-keyword
        null
      : {
          invalidForm: {
            message: this.ts.instant(
              'main-content.processing-cards.create-notification.processingDataFieldError'
            ),
            valid: false,
          },
        };
  }

  // tslint:disable-next-line: no-empty
  public onTouched: () => void = () => {};

  registerOnChange(fn: any): void {
    this.notificationDataForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    // tslint:disable-next-line: no-unused-expression
    obj && this.notificationDataForm.setValue(obj, { emitEvent: false });
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.notificationDataForm.disable() : this.notificationDataForm.enable();
  }

  ngOnInit() {
    this.notificationDataForm = this.fb.group({
      // tslint:disable-next-line: no-unbound-method
      productionNotification: [''],
    });
  }
}
