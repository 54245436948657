import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@shared/logger/logger.service';
import { Subscription } from 'rxjs';
import { Language } from '../../../model/language';

@Component({
  selector: 'app-language-selector',
  styleUrls: ['./language-selector.component.less'],
  templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {
  selectedLanguageKey = 'selectedLanguage';
  langs: any[];

  control = new FormControl();
  defaultValueString: string;
  languages: Language[] = [];
  private subscriptions = new Subscription();

  constructor(private translate: TranslateService, private logger: LoggerService) {
    this.logger.info('initiate');
    this.langs = this.translate.getLangs();

    this.langs.forEach((element) => {
      const language = new Language();
      language.languageCode = element;
      this.logger.info(language);
      this.languages.push(language);
    });

    if (localStorage.getItem(this.selectedLanguageKey)) {
      this.translate.use(localStorage.getItem(this.selectedLanguageKey));
      this.defaultValueString = localStorage.getItem(this.selectedLanguageKey);
    } else {
      this.defaultValueString = this.translate.getDefaultLang();
    }
  }

  ngOnInit() {
    this.subscriptions.add(
      this.control.valueChanges.subscribe((language) => {
        this.useLanguage(language);
      })
    );
  }

  public useLanguage(languageKey: string): void {
    this.translate.use(languageKey);
    localStorage.setItem(this.selectedLanguageKey, languageKey);
  }

  trackById(index, item) {
    if (item) {
      return item.id;
    }

    return undefined;
  }
}
