import { Component, Input } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-pdf-viewer-dialog',
  styleUrls: ['./pdf-viewer-dialog.component.less'],
  templateUrl: './pdf-viewer-dialog.component.html',
})
export class PdfViewerDialogComponent {
  public static createPdfViewerModal(
    modalService: NzModalService,
    pdfBlob: Blob,
    fileName: string
  ): void {
    const modal = modalService.create({
      // tslint:disable-next-line:no-null-keyword
      nzCancelText: null,
      nzComponentParams: {
        pdfName: fileName,
        pdfSrc: pdfBlob,
      },
      nzContent: PdfViewerDialogComponent,
      nzKeyboard: true,
      // tslint:disable-next-line:no-null-keyword
      nzOkText: null,
      nzStyle: { top: '20px' },
      nzTitle:
        'Preview PDF - Hidden layers are shown, download and view in Acrobat Reader for the correct view',
      nzWidth: '80vw',
    });
  }

  @Input() pdfSrc: Blob;
  @Input() pdfName: string;

  public constructor(private modal: NzModalRef) {}

  destroyModal(): void {
    this.modal.destroy({ data: undefined });
  }
}
