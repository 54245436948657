import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import { NzMessageService } from 'ng-zorro-antd';
import { SearchResult } from '../../../../model/search-result';
import { MessageService } from '../../../../services/message.service';
import { SearchService } from '../../../../services/search.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-search-options',
  templateUrl: './search-options.component.html',
  // tslint:disable-next-line:object-literal-sort-keys
  styleUrls: ['./search-options.component.less'],
})
export class SearchOptionsComponent implements OnInit {
  result: SearchResult[] = [];

  uploaderUserID: any;
  MDesignerId: string;
  MLaunchId: string;
  MLaunchDescription: string;
  MDecorationDesignId: string;
  MUploaderId: string;
  loading: boolean;

  constructor(
    public rest: SearchService,
    private messageService: MessageService,
    private errorMessageService: NzMessageService,
    private logger: LoggerService
  ) {}

  // tslint:disable-next-line:no-empty
  ngOnInit() {}

  buildSearchQuery(
    uploaderID: string,
    launchID: string,
    launchDescription: string,
    designerId: string,
    decorationDesignID: string
  ) {
    this.loading = true;

    this.rest
      .buildSearchQuery(uploaderID, launchID, launchDescription, designerId, decorationDesignID)
      .subscribe(
        (data: SearchResult[]) => {
          this.result = data;
          this.messageService.sendMessage(this.result);
          this.loading = false;
        },
        (error: HttpErrorResponse) => {
          const errorMessage = `There was an error fetching the search result. Please report this error.`;
          this.logger.warn(errorMessage, error);
          this.errorMessageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });
          this.loading = false;
        }
      );
  }
}
