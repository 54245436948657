import { NgModule } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SENTRY_CONFIG } from '@shared/sentry/sentry-error-handler';
// tslint:disable-next-line: no-submodule-imports no-implicit-dependencies
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
// tslint:disable-next-line: no-submodule-imports no-implicit-dependencies
import { NzCardModule } from 'ng-zorro-antd/card';
// tslint:disable-next-line: no-submodule-imports no-implicit-dependencies
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
// tslint:disable-next-line: no-submodule-imports no-implicit-dependencies
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
// tslint:disable-next-line: no-submodule-imports no-implicit-dependencies
import { NzFormModule } from 'ng-zorro-antd/form';
// tslint:disable-next-line: no-submodule-imports no-implicit-dependencies
import { NzInputModule } from 'ng-zorro-antd/input';
// tslint:disable-next-line: no-submodule-imports no-implicit-dependencies
import { NzSelectModule } from 'ng-zorro-antd/select';

import { environment } from 'src/environments/environment';
import { AuthModule } from './auth/auth.module';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { NzIconTestModule } from './nz-icont-test.module';
import { ProfileDropdownComponent } from './profile/profile-dropdown.component';
import { SentryModule } from './sentry/sentry.module';
import { SharedComponent } from './shared.component';

@NgModule({
  declarations: [SharedComponent, ProfileDropdownComponent, FeedbackDialogComponent],
  exports: [
    AuthModule,
    FormsModule,
    NzAvatarModule,
    NzCardModule,
    NzDrawerModule,
    NzDropDownModule,
    NzFormModule,
    NzIconTestModule,
    NzInputModule,
    NzSelectModule,
    SharedComponent,
    SentryModule,
    SharedComponent,
    ProfileDropdownComponent,
    ReactiveFormsModule,
    FeedbackDialogComponent,
  ],
  imports: [
    AuthModule,
    FormsModule,
    NzAvatarModule,
    NzCardModule,
    NzDrawerModule,
    NzDropDownModule,
    NzFormModule,
    NzIconTestModule,
    NzInputModule,
    NzSelectModule,
    ReactiveFormsModule,
    SentryModule.forRoot(environment.sentryConfig),
  ],
  providers: [{ provide: SENTRY_CONFIG, useValue: environment.sentryConfig }],
})
export class SharedModule {}
