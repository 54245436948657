import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// "Barrel" of Http Interceptors
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
import { AllowAllGuard } from './allow-all.guard';
import { AuthInterceptor } from './auth-interceptor';

// ** Http interceptor providers in outside-in order
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    { provide: LoggerService, useClass: ConsoleLoggerService },
    AllowAllGuard,
    httpInterceptorProviders,
  ],
})
export class AuthModule {}
