import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { PlantSelectionService } from '@shared/plant-selection.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { SelectionOption } from 'projects/deco-brief/src/app/model/selection-option';
import {
  PdfLoadingDeco,
  PdfLoadingProcessing,
} from 'projects/easy-print/src/app/ngxs/actions/print-actions';
import { Observable, Subscription } from 'rxjs';
import { BackendService } from '../../backend.service';
import { IProcessingCard } from '../../model/processing-card';
import {
  IArchivedDecoSpecification,
  IArchivedProcessingCardInfo,
  IArchivedProcessingCardInformation,
  IDecoSpecification,
  IProcessingCardInfo,
  IProcessingCardInformation,
  PrintState,
} from '../../ngxs/states/print-state';

@Component({
  selector: 'app-print-list',
  styleUrls: ['./print-list.component.less'],
  templateUrl: './print-list.component.html',
})
export class PrintListComponent implements OnInit, OnDestroy {
  @Input() processingCardList: IProcessingCardInfo[] = [];
  @Input() archivedProcessingCardList: IProcessingCardInfo[] = [];
  @Input() processingCardInfo: IProcessingCardInformation;
  @Input() archivedProcessingCardInfo: IProcessingCardInformation;

  @Input() decoSpecInfoList: IDecoSpecification[];
  @Input() archivedDecoSpecInfoList: IArchivedDecoSpecification[];

  @Input() statusOptions: SelectionOption[];

  // tslint:disable: no-unbound-method
  @Select(PrintState.physicalDesignId) physicalDesignId$: Observable<number | undefined>;

  @Select(PrintState.processingCardInformation) processingCardInformation$: Observable<
    IProcessingCardInformation | undefined
  >;
  // tslint:disable-next-line: prefer-inline-decorator
  @Select(PrintState.archivedProcessingCardInformation)
  archivedProcessingCardInformation$: Observable<IArchivedProcessingCardInformation | undefined>;

  @Select(PrintState.getProcessingPdfLoading) processingPdfLoading$: Observable<boolean>;

  @Select(PrintState.decoDesignId) decoDesignId$: Observable<number | undefined>;

  @Select(PrintState.decoSpecificationInformation) decoSpecificationInformation$: Observable<
    IDecoSpecification | undefined
  >;
  // tslint:disable-next-line: prefer-inline-decorator
  @Select(PrintState.archivedDecoSpecificationInformation)
  archivedDecoSpecificationInformation$: Observable<IArchivedDecoSpecification[] | undefined>;

  @Select(PrintState.getDecoSearchLoading) decoSearchLoading$: Observable<boolean>;

  @Select(PrintState.getArchievedDecoSearchLoading) archievedDecoSearchLoading$: Observable<
    boolean
  >;
  @Select(PrintState.getDecoPdfLoading) decoPdfLoading$: Observable<boolean>;
  @Select(PrintState.getFailedLoading) failedLoading$: Observable<boolean | undefined>;

  @Select(PrintState.getProcessingSearchLoading) processingSearchLoading$: Observable<boolean>;

  @Select(PrintState.getArchievedProcessingSearchLoading) // tslint:disable-next-line: prefer-inline-decorator
  archievedProcessingSearchLoading$: Observable<boolean>;
  isDesignMode: boolean;

  private processingCardsStateSubscription: Subscription;
  private archivedProcessingCardsStateSubscription: Subscription;
  private decoSpecificationsStateSubscription: Subscription;
  private archivedDecoSpecificationsStateSubscription: Subscription;

  private processingCardsServiceSubscription: Subscription;

  constructor(
    private logger: LoggerService,
    private backendService: BackendService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private plantService: PlantSelectionService,
    public router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {
    this.statusOptions = this.route.snapshot.data.statusOptions as SelectionOption[];
  }

  ngOnInit() {
    this.logger.info('Initializing PrintListComponent');

    this.isDesignMode = this.router.url.includes('/print/design');
    this.route.params.subscribe((routeParams) => {
      this.cancelDataSubscriptions();
      if (routeParams.id !== '0') {
        this.processingCardsStateSubscription = this.processingCardInformation$.subscribe(
          (procCardInfo) => {
            this.processingCardInfo = procCardInfo;
            this.processingCardList = procCardInfo ? procCardInfo.processingCards : [];
          }
        );

        this.archivedProcessingCardsStateSubscription = this.archivedProcessingCardInformation$.subscribe(
          (procCardInfo) => {
            this.archivedProcessingCardInfo = procCardInfo;
            this.archivedProcessingCardList = procCardInfo ? procCardInfo.processingCards : [];
          }
        );

        this.decoSpecificationsStateSubscription = this.decoSpecificationInformation$.subscribe(
          (decoSpecInfo) => {
            this.decoSpecInfoList = decoSpecInfo ? [decoSpecInfo] : [];
          },
          (err) => {
            this.logger.info('');
          }
        );

        this.archivedDecoSpecificationInformation$.subscribe(
          (archivedDecoSpecs) => {
            this.archivedDecoSpecInfoList = archivedDecoSpecs || [];
          },
          (err) => {
            if (err.state === 404) {
              this.logger.info('decoration specification is not found');
            } else {
              this.logger.info('');
            }
          }
        );
      } else {
        this.processingCardInfo = undefined;
        this.processingCardList = [];
        this.archivedProcessingCardInfo = undefined;
        this.archivedProcessingCardList = [];
        this.decoSpecInfoList = [];
        this.archivedDecoSpecInfoList = [];
      }
    });
    this.store.dispatch(new PdfLoadingDeco(false));
    this.store.dispatch(new PdfLoadingProcessing(false));
    window.document.title = this.isDesignMode ? 'Design' : 'Processing Card';
  }

  createMessage(type: string, text: string) {
    this.message.create(type, text);
  }

  trackBy(index: number, item: IProcessingCard): string {
    if (item) {
      return `${item.type}_${item.platform}_${item.plant}_${item.npiStatus}`;
    }

    return undefined;
  }

  print(row: IProcessingCardInfo | IArchivedProcessingCardInfo) {
    this.logger.info('Printing processing card', row);
    const cardInfo = this.processingCardInfo || this.archivedProcessingCardInfo;
    this.store.dispatch(new PdfLoadingProcessing(true));
    this.generateAndViewPdf(
      cardInfo.decorationPhysicalId,
      cardInfo.plantId,
      row.platformMachineTypeId
    );
  }

  printArchivedProcessingCard(row: IProcessingCardInfo | IArchivedProcessingCardInfo) {
    this.logger.info('Printing archived processing card', row);
    const cardInfo = this.processingCardInfo || this.archivedProcessingCardInfo;
    this.store.dispatch(new PdfLoadingProcessing(true));
    this.getAndViewArchivedPdf(
      cardInfo.decorationPhysicalId,
      row.platformMachineTypeId || 'undefined'
    );
  }

  printDecoSpec(row: IArchivedDecoSpecification) {
    this.logger.info('Printing design spec', row);
    this.store.dispatch(new PdfLoadingDeco(true));
    this.generateAndViewDecoPdf(row.decorationDesignId);
  }

  printArchivedDecoSpec(row: IArchivedDecoSpecification) {
    this.logger.info('Printing archived design spec', row);
    this.store.dispatch(new PdfLoadingDeco(true));
    this.generateAndViewArchivedDecoPdf(row.decorationDesignId);
  }

  generateAndViewDecoPdf(decoDesignId: string) {
    this.backendService.getDesignSpecificationPdf(decoDesignId).subscribe(
      (data) => {
        this.generateDownloadDesign(data, decoDesignId);
        this.store.dispatch(new PdfLoadingDeco(false));
      },
      (error: HttpErrorResponse) => {
        const errorMessage = `There was an error fetching the pdf for decoration design id ${decoDesignId}. Please report this error.`;
        this.logger.error(errorMessage, error);
        this.store.dispatch(new PdfLoadingDeco(false));
      }
    );
  }

  generateAndViewArchivedDecoPdf(decoDesignId: string) {
    this.backendService.getArchivedDesignSpecificationPdf(decoDesignId).subscribe(
      (data) => {
        this.generateDownloadDesign(data, decoDesignId);
        this.store.dispatch(new PdfLoadingDeco(false));
      },
      (error: HttpErrorResponse) => {
        const errorMessage = `There was an error fetching the pdf for decoration design id ${decoDesignId}. Please report this error.`;
        this.logger.error(errorMessage, error);
        this.store.dispatch(new PdfLoadingDeco(false));
      }
    );
  }

  generateDownloadDesign(pdfFileForDownload: Blob, decoDesignId: string) {
    this.download('ds', decoDesignId, pdfFileForDownload);
  }

  download(prefix: string, id: string, pdfFileForDownload: Blob) {
    const file = new Blob([pdfFileForDownload], { type: 'application/pdf' });
    const fileURL = this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file))
    );
    const dateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd_HHmmss');
    const fileName = `${prefix}_${id}_${dateTime}.pdf`;
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      window.navigator.msSaveOrOpenBlob(pdfFileForDownload, fileName);
    } else {
      const element = document.createElement('a');
      element.href = fileURL;
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    }
  }

  generateDownloadProcessing(pdfFileForDownload: Blob, decorationPhysicalId: string) {
    this.download('pc', decorationPhysicalId, pdfFileForDownload);
  }

  getStatusText = (decoSpec: IDecoSpecification): string =>
    SelectionOption.getDisplayLabelForCode(this.statusOptions, decoSpec.statusCode);

  generateAndViewPdf(decorationPhysicalId: string, plantId?: string, platformId?: string) {
    this.backendService
      .getGeneratedProductBriefPdf(decorationPhysicalId, plantId, platformId)
      .subscribe(
        (data) => {
          this.generateDownloadProcessing(data, decorationPhysicalId);
          this.store.dispatch(new PdfLoadingProcessing(false));
        },
        (error: HttpErrorResponse) => {
          const errorMessage = `There was an error fetching the pdf for processing card, plant id: ${plantId}, decoration physical id: ${decorationPhysicalId}, platform: ${platformId}. Please report this error.`;
          this.logger.error(errorMessage, error);
          this.store.dispatch(new PdfLoadingProcessing(false));
        }
      );
  }

  myDateParser(dateStr: string): string {
    // 2018-01-01T12:12:12.123456; - converting valid date format like this
    // console.log(dateStr);
    const date = dateStr.substring(0, 10);
    const time = dateStr.substring(11, 19);
    // const millisecond = dateStr.substring(20);
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let validDate: string;
    validDate = isIEOrEdge ? `${date}T${time}` : `${date} ${time}`;

    return validDate;
  }

  getAndViewArchivedPdf(decorationPhysicalId: string, machineTypeId: string) {
    this.backendService
      .getArchivedProductionSpecificationPdf(decorationPhysicalId, machineTypeId)
      .subscribe(
        (data) => {
          this.generateDownloadProcessing(data, decorationPhysicalId);
          this.store.dispatch(new PdfLoadingProcessing(false));
        },
        (error: HttpErrorResponse) => {
          const errorMessage = `There was an error fetching the pdf for processing card, decoration physical id: ${decorationPhysicalId}, platform: ${machineTypeId}. Please report this error.`;
          this.logger.error(errorMessage, error);
          this.store.dispatch(new PdfLoadingProcessing(false));
        }
      );
  }

  cancelDataSubscriptions() {
    if (this.processingCardsStateSubscription) {
      this.processingCardsStateSubscription.unsubscribe();
    }
    if (this.processingCardsServiceSubscription) {
      this.processingCardsServiceSubscription.unsubscribe();
    }
    if (this.decoSpecificationsStateSubscription) {
      this.decoSpecificationsStateSubscription.unsubscribe();
    }
    if (this.archivedDecoSpecificationsStateSubscription) {
      this.archivedDecoSpecificationsStateSubscription.unsubscribe();
    }
    if (this.archivedProcessingCardsStateSubscription) {
      this.archivedProcessingCardsStateSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.cancelDataSubscriptions();
  }
}
