import { Component, EventEmitter, OnInit, Output } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserFeedbackService } from '@shared/user-feedback.service';
import { UserService } from '@shared/user.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-feedback-dialog',
  styleUrls: ['./feedback-dialog.component.scss'],
  templateUrl: './feedback-dialog.component.html',
})
export class FeedbackDialogComponent implements OnInit {
  public validateForm: FormGroup;
  // tslint:disable-next-line
  @Output() public closeFeedback = new EventEmitter();

  public constructor(
    private fb: FormBuilder,
    private uf: UserFeedbackService,
    private userService: UserService
  ) {}

  public submitForm(): void {
    // tslint:disable:forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      this.validateForm.addControl('event_id', new FormControl());
      this.uf.feedbackInfo = this.validateForm.value;
      this.closeFeedback.emit();
      this.validateForm.removeControl('event_id');

      throw new Error('feedback'); // feedback is sent through error handler of sentry
    }
  }

  public ngOnInit(): void {
    // tslint:disable:no-unbound-method
    this.validateForm = this.fb.group({
      comments: [undefined, [Validators.required]],
      email: [this.getUserEmail(), [Validators.required, Validators.email]], // TODO email still not validating .[a-z]* . For example louise@louise is good enough
      name: [this.getUserName(), [Validators.required]],
    });
  }

  public closeForm() {
    this.closeFeedback.emit();
  }

  private resetForm() {
    this.validateForm.reset({
      comments: undefined,
      email: this.getUserEmail(),
      name: this.getUserName(),
    });
  }

  private getUserEmail() {
    return this.userService.getUserId();
  }

  private getUserName() {
    return this.userService.getUserName();
  }
}
