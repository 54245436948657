import { Component, OnInit } from '@angular/core';
import { CollapseService } from '@shared/collapse.service';
// tslint:disable-next-line:ban-ts-ignore
// @ts-ignore
import { version } from '../../../../../../package.json';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-header',
  styleUrls: ['./header.component.less'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  public version: string = version;

  public constructor(public collapseService: CollapseService) {}
}
