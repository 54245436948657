import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ISelectionOption } from '../model/selection-option';
import { BackendService } from '../services/backend.service';

@Injectable({
  providedIn: 'root',
})
export class ThinnerValuesResolver implements Resolve<ISelectionOption[]> {
  constructor(private service: BackendService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ISelectionOption[]> | Promise<ISelectionOption[]> | ISelectionOption[] {
    return this.service.getThinnerValues();
  }
}
