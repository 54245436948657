import { HttpErrorResponse } from '@angular/common/http';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { Subscription } from 'rxjs';
import { ProcessingCardInfo } from 'src/../projects/processing-card/src/app/model/processing-card-info';
import { SearchResult } from '../../model/search-result';
import { BackendService } from '../../services/backend.service';
import {
  ClearCopyProcessingCard,
  DisableSearch,
  DoSearch,
  EnableSearch,
  RemoveSearchErrorMessage,
  SearchLoading,
  SetCopyProcessingCard,
  SetSearchErrorMessage,
  SetSearchState,
} from '../actions/search-actions';

@State<ISearchStateModel>({
  defaults: { loading: 0 },
  name: 'searchstate',
})
export class SearchState {
  @Selector() static getProcessingCards(state: ISearchStateModel) {
    return state.processingCards;
  }

  @Selector() static getCopyProcessingCard(state: ISearchStateModel) {
    return state.copyProcessingCard;
  }

  @Selector() static getDisableSearch(state: ISearchStateModel) {
    return state.disableSearch || false;
  }

  @Selector() static getSearchLoading(state: ISearchStateModel) {
    return state.loading > 0;
  }

  private processingCardsSubscription: Subscription;

  private codeToTextMapping = {
    400: 'Bad Request - Wrong ID format',
    404: 'ID not found',
  };

  constructor(public rest: BackendService, private logger: LoggerService, private store: Store) {}

  @Action(DoSearch) fetchProductionSpecification(
    ctx: StateContext<ISearchStateModel>,
    payload: DoSearch
  ) {
    ctx.patchState({ processingCards: [] });
    const physicalDesignId = payload.decorationPhysicalId;

    if (physicalDesignId) {
      this.logger.info(
        'Fetching processingCards for plantId:',
        payload.plantId,
        ' physicalDesignId: ',
        physicalDesignId
      );
      this.rest.searchPhysicalMaterial(payload.plantId, physicalDesignId).subscribe(
        (data: SearchResult) => {
          ctx.patchState({ ...data });
          this.logger.info('Result:', data);
        },
        (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.logger.info('production specification is not found');
          } else if (err.status === 400) {
            ctx.setState({ error: this.codeToTextMapping[err.status], loading: 0 });
          } else {
            const errorMessage = `There was an error searching for processing cards for plant with id ${payload.plantId}. Please report this error.`;
            ctx.dispatch(new SetSearchErrorMessage(errorMessage));
            this.logger.warn(errorMessage, err);
          }
        }
      );
    }
  }

  @Action(SetSearchErrorMessage) setUiErrorMessage(
    ctx: StateContext<ISearchStateModel>,
    payload: SetSearchErrorMessage
  ) {
    const state = ctx.getState();
    if (state.uiErrorMessages) {
      ctx.patchState({ uiErrorMessages: [...state.uiErrorMessages, payload.message] });
    } else {
      ctx.patchState({ uiErrorMessages: [payload.message] });
    }
  }

  @Action(RemoveSearchErrorMessage) removeUiErrorMessage(ctx: StateContext<ISearchStateModel>) {
    const state = ctx.getState();
    const errorMessage = state.uiErrorMessages;
    if (errorMessage) {
      errorMessage.shift();
      ctx.patchState({ uiErrorMessages: [...errorMessage] });
    }
  }

  @Action(SetSearchState) setSearchState(
    ctx: StateContext<ISearchStateModel>,
    payload: SetSearchState
  ) {
    ctx.patchState({ ...payload.result });
  }

  @Action(SetCopyProcessingCard) setCopyProcessingCard(
    ctx: StateContext<ISearchStateModel>,
    payload: SetCopyProcessingCard
  ) {
    ctx.patchState({
      copyProcessingCard: {
        ...payload.processingCard,
      },
    });
  }

  @Action(ClearCopyProcessingCard) clearCopyProcessingCard(ctx: StateContext<ISearchStateModel>) {
    ctx.patchState({ copyProcessingCard: undefined });
  }

  @Action(EnableSearch) enableSearch(ctx: StateContext<ISearchStateModel>) {
    ctx.patchState({ disableSearch: false });
  }

  @Action(DisableSearch) disableSearch(ctx: StateContext<ISearchStateModel>) {
    ctx.patchState({ disableSearch: true });
  }

  @Action(SearchLoading) searchLoading(
    ctx: StateContext<ISearchStateModel>,
    payload: SearchLoading
  ) {
    let currentLoading = ctx.getState().loading;
    if (payload.isLoading) {
      currentLoading += 1;
    } else {
      currentLoading = currentLoading === 0 ? 0 : currentLoading - 1;
    }
    ctx.patchState({ loading: currentLoading });
  }
}

export interface ISearchStateModel {
  plantId?: string;
  decorationPhysicalId?: string;
  processingCards?: ProcessingCardInfo[];
  decorationDesignId?: string;
  decorationDesignDescription?: string;
  launchId?: string;
  error?: string;
  uiErrorMessages?: string[];
  copyProcessingCard?: ProcessingCardInfo;
  disableSearch?: boolean;
  loading: number;
}
