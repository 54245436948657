import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import { IPlantSelection } from '@shared/plant-selection';
import { UserFeedback } from '@shared/user-feedback';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ClicheData } from '../model/cliche-data';
import { ColourOption } from '../model/colour-option';
import { GenericPlatform } from '../model/generic-platform';
import { ProcessingCard } from '../model/processing-card';
import { SearchResult } from '../model/search-result';
import { ISelectionOption } from '../model/selection-option';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  private BASEURL = 'api/v1/';
  private CARDURL = 'api/v1/processingcard/';

  public constructor(private httpClient: HttpClient, private logger: LoggerService) {}

  public fetchUser() {
    return this.get(`${this.BASEURL}user`);
  }

  public postUserFeedback(feedback: UserFeedback) {
    return this.httpClient
      .post(`${this.BASEURL}userfeedback`, feedback)
      .pipe(catchError((error) => this.logError(error)));
  }

  getProcessingCardPdf(
    decorationPhysicalId: string,
    plantId: string,
    machineTypeId: string
  ): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', ['application/pdf', 'application/json']);

    return this.httpClient
      .get(
        `${this.CARDURL}cards/document?decorationPhysicalId=${decorationPhysicalId}&plantId=${plantId}&machineTypeId=${machineTypeId}`,
        {
          headers,
          responseType: 'blob',
        }
      )
      .pipe(catchError((error) => this.logError(error)));
  }

  getProcessingCardSinglePdf(decoDesignId: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', ['application/pdf', 'application/json']);

    return this.httpClient
      .get(`${this.CARDURL}design/document?decorationDesignId=${decoDesignId}`, {
        headers,
        responseType: 'blob',
      })
      .pipe(catchError((error) => this.logError(error)));
  }

  searchPhysicalMaterial(plantId: string, searchTerm: string): Observable<SearchResult> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    this.logger.info(`searching for plantId: ${plantId} and materialId: ${searchTerm}`);

    return this.httpClient
      .get<SearchResult>(
        `${this.CARDURL}cards/search?searchTerm=${searchTerm}${
          plantId ? `&plantId=${plantId}` : ''
        }`,
        {
          headers,
        }
      )
      .pipe(catchError((error) => this.logError(error)));
  }

  getPlantValues(): Observable<IPlantSelection[]> {
    return this.get<IPlantSelection[]>(`${this.CARDURL}plants`);
  }

  getProductionToleranceValues(): Observable<ISelectionOption[]> {
    return this.get<ISelectionOption[]>(`${this.CARDURL}options/prodtolerance`);
  }

  getHardnessValues(): Observable<ISelectionOption[]> {
    return this.get<ISelectionOption[]>(`${this.CARDURL}options/hardness`);
  }

  getThinnerValues(): Observable<ISelectionOption[]> {
    return this.get<ISelectionOption[]>(`${this.CARDURL}options/thinner`);
  }

  getNpiStatusOptionCodeValues(): Observable<ISelectionOption[]> {
    return this.get<ISelectionOption[]>(`${this.CARDURL}options/thinner`);
  }

  getDesignColourValues(decoDesignId: string): Observable<ColourOption[]> {
    const url = `${this.CARDURL}colours/design?decorationPhysicalId=${decoDesignId}`;

    return this.getColours(url);
  }

  getColours(url: string) {
    return this.get<ColourOption[]>(url).pipe(
      map((colours: ColourOption[]) => colours.map((colour) => new ColourOption(colour)))
    );
  }

  getTechnicalColourValues(): Observable<ColourOption[]> {
    const url = `${this.CARDURL}colours/technical`;

    return this.getColours(url).pipe(
      map((colours: ColourOption[]) => colours.map((colour) => new ColourOption(colour)))
    );
  }

  getInkColourValues(): Observable<ColourOption[]> {
    const url = `${this.CARDURL}colours/ink`;

    return this.getColours(url);
  }

  getElementColourValue(colourId: string): Observable<ColourOption> {
    const url = `${this.CARDURL}colours/${colourId}`;

    return this.get<ColourOption>(url);
  }

  getGenericPlatforms(): Observable<GenericPlatform[]> {
    return this.get<GenericPlatform[]>(`${this.CARDURL}genericplatforms`);
  }

  getGenericPlatform(machineTypeId: string): Observable<GenericPlatform> {
    return this.get<GenericPlatform>(
      `${this.CARDURL}genericplatforms?machineTypeId=${machineTypeId}`
    );
  }

  putProcessingCard(
    processingCard: ProcessingCard,
    plantId: string,
    machineTypeId: string,
    decorationPhysicalId: string
  ): Observable<ProcessingCard> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'my-auth-token',
        'Content-Type': 'application/json',
      }),
    };
    console.log('processing card update', processingCard);

    return this.httpClient
      .put<ProcessingCard>(
        `${this.CARDURL}cards?plantId=${plantId}&decorationPhysicalId=${decorationPhysicalId}&machineTypeId=${machineTypeId}`,
        processingCard,
        httpOptions
      )
      .pipe(catchError((error) => this.logError(error)));
  }

  getProcessingCard(
    plantId: string,
    machineTypeId: string,
    decorationPhysicalId: string
  ): Observable<ProcessingCard> {
    console.log('platform id', machineTypeId);
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: 'my-auth-token',
    //     'Content-Type': 'application/json',
    //   }),
    // };

    return this.httpClient
      .get<ProcessingCard>(
        `${this.CARDURL}cards?plantId=${plantId}&decorationPhysicalId=${decorationPhysicalId}&machineTypeId=${machineTypeId}`
        // ,
        // processingCard,
        // httpOptions
      )
      .pipe(catchError((error) => this.logError(error)));
  }

  public deleteProcessingCard(
    plantId: number,
    decorationPhysicalId: string,
    machineTypeId: string
  ): Observable<any> {
    return this.httpClient
      .delete<ProcessingCard>(
        `${this.CARDURL}cards?plantId=${plantId}&decorationPhysicalId=${decorationPhysicalId}&machineTypeId=${machineTypeId}`
      )
      .pipe(catchError((error) => this.logError(error)));
  }

  putClicheData(
    clicheData: ClicheData,
    machineTypeId: string,
    clicheId: string
  ): Observable<ClicheData> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'my-auth-token',
        'Content-Type': 'application/json',
      }),
    };
    console.log('cliche data update', clicheData);

    return this.httpClient
      .put<ClicheData>(
        `${this.CARDURL}cliche?machineTypeId=${machineTypeId}&clicheId=${clicheId}`,
        clicheData,
        httpOptions
      )
      .pipe(catchError((error) => this.logError(error)));
  }

  getClicheData(machineTypeId: string, clicheId: string): Observable<ClicheData> {
    console.log('platform id', machineTypeId);
    console.log('cliche id', clicheId);

    return this.httpClient
      .get<ClicheData>(`${this.CARDURL}cliche?clicheId=${clicheId}&machineTypeId=${machineTypeId}`)
      .pipe(catchError((error) => this.logError(error)));
  }

  private get<T>(url: string) {
    return this.httpClient.get<T>(url).pipe(catchError((error) => this.logError(error)));
  }

  private logError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      if (error.status !== 404) {
        this.logger.error('An error occurred:', error.error.message);
      }
    } else if (error.error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status !== 404) {
        if (error.error.message) {
          this.logger.error(
            `Backend returned code ${error.status}, ` + `message: ${error.error.message}`
          );
        } else {
          // The backend returned an unsuccessful response code without the message set.
          // The response body may contain clues as to what went wrong,
          this.logger.error(
            `Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`
          );
        }
      }
    } else {
      this.logger.error(`Backend returned code ${error.status}, error: ${JSON.stringify(error)}`);
    }

    return throwError(error);
  }
}
