export class SelectionOption {
  static getDisplayLabelForCode(statusOptions: SelectionOption[], displayCode: string): any {
    const value = statusOptions.find((option) => option.code === displayCode);
    if (value && value.displayLabelEn) {
      return value.displayLabelEn;
    }

    return `NO TRANSLATION FOR ${displayCode}`;
  }
  code: string;
  displayLabelEn: string;
  sortOrder: number;
}
