import { ColourOption } from 'projects/processing-card/src/app/model/colour-option';

export class PrinterSpecification {
  printerNumber: string;
  printerType: string;
  colour: ColourOption;
  thinnerText: string;
  hardnessText: string;
  padText: string;
  placementText: string;
  noteText: string;
  modifiedBy: string;

  constructor(data: Partial<PrinterSpecification>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
