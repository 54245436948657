import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IRoleGuardConfig, RoleGuard } from '@shared/auth/guards/role-guard.service';
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
import { CanDeactivateGuard } from '../app/can-deactivate/can-deactivate.guard';
import { DesignSpecificationComponent } from '../app/components/main-content/design-specification/design-specification.component';
import { GlobalNotificationsComponent } from '../app/components/main-content/global-notifications/global-notifications.component';
import { NPIStatusComponent } from '../app/components/main-content/npi-status/npi-status.component';
import { ProcessingCardsComponent } from '../app/components/main-content/processing-cards/processing-cards.component';
import { GlobalNotificationTypesResolver } from '../app/resolvers/GlobalNotificationTypesResolver';
import { HardnessValuesResolver } from '../app/resolvers/HardnessValuesResolver';
import { ProductionToleranceValuesResolver } from '../app/resolvers/ProductionToleranceValuesResolver';
import { ThinnerValuesResolver } from '../app/resolvers/ThinnerValuesResolver';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CreatePageComponent } from './components/main-content/processing-cards/create-page/create-page.component';
import { EditPageComponent } from './components/main-content/processing-cards/edit-page/edit-page.component';
import { ProcessingCardComponent } from './components/main-content/processing-cards/processing-card/processing-card.component';
import { TestPdfComponent } from './components/test-pdf/test-pdf.component';
import { GenericPlatformResolver } from './resolvers/GenericPlatformResolver';
import { InkColourResolver } from './resolvers/InkColourResolver';
import { NpiStatusValuesResolver } from './resolvers/NpiStatusValuesResolver';
import { PlantSelectionResolver } from './resolvers/PlantSelectionResolver';
import { TechnicalColourResolver } from './resolvers/TechnicalColourResolver';

const routes: Routes = [
  {
    component: AppComponent,
    path: 'processingcard',
    pathMatch: 'full',
  },
  { path: 'processingcard/frameredirect', component: AppComponent },
  { path: 'processingcard/accessdenied', component: AppComponent },
  {
    canActivate: environment.enable_oauth ? [RoleGuard] : undefined,
    children: [
      {
        component: TestPdfComponent,
        path: 'pdf',
      },
      {
        component: DesignSpecificationComponent,
        path: 'design-specification',
        // resolve: { statusOptions: StatusValuesResolver },
      },
      {
        canDeactivate: [CanDeactivateGuard],
        component: GlobalNotificationsComponent,
        path: 'global-notification',
        resolve: { notificationTypes: GlobalNotificationTypesResolver },
      },
      {
        canDeactivate: [CanDeactivateGuard],
        component: NPIStatusComponent,
        path: 'npi-status',
        resolve: {
          npiStatusOptions: NpiStatusValuesResolver,
        },
      },
      {
        component: CreatePageComponent,
        path: 'processing-card/create',
        resolve: {
          genericPlatformOptions: GenericPlatformResolver,
          hardnessOptions: HardnessValuesResolver,
          productionToleranceOptions: ProductionToleranceValuesResolver,
          thinnerOptions: ThinnerValuesResolver,
        },
      },
      {
        component: EditPageComponent,
        path: 'processing-card/edit',
        resolve: {
          genericPlatformOptions: GenericPlatformResolver,
          hardnessOptions: HardnessValuesResolver,
          productionToleranceOptions: ProductionToleranceValuesResolver,
          thinnerOptions: ThinnerValuesResolver,
        },
      },
      {
        canDeactivate: [CanDeactivateGuard],
        component: ProcessingCardComponent,
        path: 'processing-card/:platform/:decorationPhysicalId/:action',
        resolve: {
          hardnessOptions: HardnessValuesResolver,
          inkColourOptions: InkColourResolver,
          npiStatusOptions: NpiStatusValuesResolver,
          productionToleranceOptions: ProductionToleranceValuesResolver,
          technicalColourOptions: TechnicalColourResolver,
          thinnerOptions: ThinnerValuesResolver,
        },
      },
      {
        component: ProcessingCardsComponent,
        path: 'processing-card',
        resolve: {
          genericPlatformOptions: GenericPlatformResolver,
          hardnessOptions: HardnessValuesResolver,
          productionToleranceOptions: ProductionToleranceValuesResolver,
          thinnerOptions: ThinnerValuesResolver,
        },
      },
    ],
    component: AppComponent,
    data: {
      requiredRoles: ['admin'],
    } as IRoleGuardConfig,
    path: 'processingcard/main',
    resolve: { plantSelections: PlantSelectionResolver },
    runGuardsAndResolvers: 'always',
  },
  { path: 'processingcard/**', redirectTo: 'processingcard/main' },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
  providers: [{ provide: LoggerService, useClass: ConsoleLoggerService }, PlantSelectionResolver],
})
export class AppRoutingModule {}
