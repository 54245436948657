import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoggerService } from '@shared/logger/logger.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-search',
  styleUrls: ['./search.component.less'],
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {
  public searchForm: FormGroup;

  public constructor(
    private router: Router,
    private fb: FormBuilder,
    private logger: LoggerService
  ) {}

  public ngOnInit() {
    this.logger.info('Initializing search component');
    this.searchForm = this.fb.group({
      // tslint:disable: no-unbound-method
      // tslint:disable: no-null-keyword
      searchInput: [null, [Validators.required]],
    });
  }

  public searchMaterial() {
    this.router.navigateByUrl(`main/details/${this.searchForm.value.searchInput}`);
  }
}
