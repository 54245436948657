import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@shared/logger/logger.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { ISubscription } from 'rxjs/Subscription';
import { GenericPlatform } from '../../../model/generic-platform';
import { SearchResult } from '../../../model/search-result';
import { BackendService } from '../../../services/backend.service';
import { SearchResultService } from '../../../services/search-result.service';

@Component({
  selector: 'app-processing-cards',
  styleUrls: ['./processing-cards.component.less'],
  templateUrl: './processing-cards.component.html',
})
export class ProcessingCardsComponent implements OnInit, OnDestroy {
  genericPlatformOptions: GenericPlatform[];
  searchResultSubscription: ISubscription;
  searchResult: SearchResult;
  selectedPlatform: string;

  constructor(
    private searchResultService: SearchResultService,
    private route: ActivatedRoute,
    private modalService: NzModalService,
    private backendService: BackendService,
    private messageService: NzMessageService,
    private logger: LoggerService,
    private ts: TranslateService
  ) {}

  confirmDelete(plantId: number, decorationPhysicalId: string, machineTypeId: string) {
    this.modalService.confirm({
      nzCancelText: this.ts.instant(
        'main-content.processing-cards.processincCards.confirmDeleteCancelText'
      ),
      nzContent: this.ts.instant(
        'main-content.processing-cards.processincCards.confirmDeleteContent'
      ),
      nzOkText: this.ts.instant(
        'main-content.processing-cards.processincCards.confirmDeleteOkText'
      ),
      nzOkType: 'danger',
      // tslint:disable-next-line: no-void-expression
      nzOnOk: () => this.deleteProcessingCard(plantId, decorationPhysicalId, machineTypeId),
      nzTitle: this.ts.instant('main-content.processing-cards.processincCards.confirmDeleteTitle'),
    });
  }

  ngOnInit() {
    this.genericPlatformOptions = this.route.snapshot.data
      .genericPlatformOptions as GenericPlatform[];
  }

  ngOnDestroy() {
    this.searchResultSubscription.unsubscribe();
  }

  private deleteProcessingCard(
    plantId: number,
    decorationPhysicalId: string,
    machineTypeId: string
  ) {
    this.backendService
      .deleteProcessingCard(plantId, decorationPhysicalId, machineTypeId)
      .subscribe(
        (response) => {
          this.searchRefresh(String(plantId), decorationPhysicalId);
          this.messageService.success(
            this.ts.instant('main-content.processing-cards.processingCards.deleteCardSucess')
          );
        },
        (error) => {
          const errorMessage = this.ts.instant(
            'main-content.processing-cards.processingCards.deleteCardError'
          );
          this.logger.warn(errorMessage, error);
          this.messageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });
        }
      );
  }

  private searchRefresh(plantId: string, decorationPhysicalId: string) {
    let searchResult: SearchResult;
    this.backendService.searchPhysicalMaterial(plantId, decorationPhysicalId).subscribe(
      (data: SearchResult) => {
        searchResult = data;
        this.logger.info('Result:', data);
        this.searchResultService.changeSearchResult(searchResult);
      },
      (err: HttpErrorResponse) => {
        if (err.status === 400 || err.status === 404) {
          searchResult = new SearchResult(undefined);
          searchResult.error = searchResult.codeToTextMapping[err.status];
        } else {
          const errorMessage = `${this.ts.instant(
            'main-content.processing-cards.processingCards.searchErrorP1'
          )} ${plantId}${this.ts.instant(
            'main-content.processing-cards.processingCards.searchErrorP2'
          )}`;
          this.logger.warn(errorMessage, err);
          this.messageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });
        }
      }
    );
  }
}
