import { Injectable } from '@angular/core';
import { UserFeedback } from './user-feedback';

@Injectable({
  providedIn: 'root',
})
export class UserFeedbackService {
  private feedback: UserFeedback;

  public set feedbackInfo(feedback: UserFeedback) {
    this.feedback = feedback;
  }

  public get feedbackInfo(): UserFeedback {
    return this.feedback;
  }

  public set feedbackId(id: string) {
    this.feedback.event_id = id;
  }
}
