import { HttpErrorResponse } from '@angular/common/http';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { PlantSelectionService } from '@shared/plant-selection.service';
import { FetchDesignSpecification } from 'projects/easy-print/src/app/ngxs/actions/fetch-design-spec';
import { Subscription } from 'rxjs';
import { BackendService } from '../../backend.service';
import {
  FetchProductionSpecification,
  PdfLoadingDeco,
  PdfLoadingProcessing,
  SearchLoadingArchievedDeco,
  SearchLoadingArchievedProcessing,
  SearchLoadingDeco,
  SearchLoadingProcessing,
} from '../actions/print-actions';

@State<IPrintStateModel>({
  defaults: {
    archivedProcessingCardInformation: undefined,
    failedLoadingProcessing: false,
    failedLoadingProcessingArchieved: false,
    loadingProcessing: 0,
    loadingProcessingArchieved: 0,
    loadingProcessingPdf: 0,
    physicalDesignId: undefined,
    processingCardInformation: undefined,

    loadingDeco: 0,
    loadingDecoArchieved: 0,
    loadingDecoPdf: 0,
  },
  name: 'print',
})
@State<IPrintStateDecoModel>({
  defaults: {
    archivedDecoSpecificationInformation: undefined,
    decoDesignId: undefined,
    decoSpecificationInformation: undefined,
  },
  name: 'print',
})
export class PrintState {
  @Selector() static physicalDesignId(state: IPrintStateModel) {
    return state.physicalDesignId;
  }

  @Selector() static processingCardInformation(state: IPrintStateModel) {
    return state.processingCardInformation;
  }

  @Selector() static archivedProcessingCardInformation(state: IPrintStateModel) {
    return state.archivedProcessingCardInformation;
  }

  @Selector() static getProcessingSearchLoading(state: IPrintStateModel) {
    return state.loadingProcessing > 0;
  }

  @Selector() static getArchievedProcessingSearchLoading(state: IPrintStateModel) {
    return state.loadingProcessingArchieved > 0;
  }

  @Selector() static getProcessingPdfLoading(state: IPrintStateModel) {
    return state.loadingProcessingPdf > 0;
  }

  @Selector() static getFailedLoading(state: IPrintStateModel) {
    return state.failedLoadingProcessing || state.failedLoadingProcessingArchieved;
  }

  @Selector() static getDecoSearchLoading(state: IPrintStateModel) {
    return state.loadingDeco > 0;
  }

  @Selector() static getArchievedDecoSearchLoading(state: IPrintStateModel) {
    return state.loadingDecoArchieved > 0;
  }

  @Selector() static getDecoPdfLoading(state: IPrintStateModel) {
    return state.loadingDecoPdf > 0;
  }

  @Selector() static decoDesignId(state: IPrintStateDecoModel) {
    return state.decoDesignId;
  }

  @Selector() static decoSpecificationInformation(state: IPrintStateDecoModel) {
    return state.decoSpecificationInformation;
  }

  @Selector() static archivedDecoSpecificationInformation(state: IPrintStateDecoModel) {
    return state.archivedDecoSpecificationInformation;
  }

  private processingCardsSubscription: Subscription;
  private archivedProcessingCardsSubscription: Subscription;
  private decorationSpecificationSubscription: Subscription;
  private archivedDecorationSpecificationSubscription: Subscription;

  constructor(
    private backend: BackendService,
    private plantSelectionService: PlantSelectionService,
    private logger: LoggerService
  ) {}

  @Action(FetchProductionSpecification) fetchProductionSpecification(
    ctx: StateContext<IPrintStateModel>,
    payload: FetchProductionSpecification
  ) {
    const physicalDesignId = payload.physicalDesignId;
    ctx.patchState({
      failedLoadingProcessing: false,
      failedLoadingProcessingArchieved: false,
      physicalDesignId,
    });

    if (physicalDesignId) {
      ctx.dispatch(new SearchLoadingArchievedProcessing(true));
      this.archivedProcessingCardsSubscription = this.backend
        .getArchivedProcessingCards(physicalDesignId)
        .subscribe(
          (res) => {
            this.logger.info('Got archived processingCards ', res);
            ctx.patchState({
              archivedProcessingCardInformation: res,
              failedLoadingProcessingArchieved: false,
            });
            ctx.dispatch(new SearchLoadingArchievedProcessing(false));
          },
          (err: HttpErrorResponse) => {
            this.logError(err, ctx, 'archived processing card', false, true);
          }
        );
    }

    const plant = this.plantSelectionService.getPlantForUser();
    if (plant && physicalDesignId) {
      this.logger.info(
        'Fetching processingCards for plantId:',
        plant.plantId,
        ' physicalDesignId: ',
        physicalDesignId
      );
      ctx.dispatch(new SearchLoadingProcessing(true));
      this.processingCardsSubscription = this.backend
        .getProcessingCards(Number(plant.plantId), physicalDesignId)
        .subscribe(
          (res) => {
            this.logger.info('Got processingCards ', res);
            ctx.patchState({ processingCardInformation: res, failedLoadingProcessing: false });
            ctx.dispatch(new SearchLoadingProcessing(false));
          },
          (err: HttpErrorResponse) => {
            this.logError(err, ctx, 'processing card', false, false);
          }
        );
    }
  }

  @Action(SearchLoadingArchievedProcessing) searchLoadingArchievedProcessing(
    ctx: StateContext<IPrintStateModel>,
    payload: SearchLoadingArchievedProcessing
  ) {
    let currentLoading = ctx.getState().loadingProcessingArchieved;
    if (payload.isLoading) {
      currentLoading += 1;
    } else {
      currentLoading = currentLoading === 0 ? 0 : currentLoading - 1;
    }
    ctx.patchState({ loadingProcessingArchieved: currentLoading });
  }

  @Action(PdfLoadingProcessing) pdfLoadingProcessing(
    ctx: StateContext<IPrintStateModel>,
    payload: PdfLoadingProcessing
  ) {
    let currentLoading = ctx.getState().loadingProcessingPdf;
    if (payload.isLoading) {
      currentLoading += 1;
    } else {
      currentLoading = currentLoading === 0 ? 0 : currentLoading - 1;
    }
    ctx.patchState({ loadingProcessingPdf: currentLoading });
  }

  @Action(PdfLoadingDeco) pdfLoadingDeco(
    ctx: StateContext<IPrintStateModel>,
    payload: PdfLoadingDeco
  ) {
    let currentLoading = ctx.getState().loadingDecoPdf;
    if (payload.isLoading) {
      currentLoading += 1;
    } else {
      currentLoading = currentLoading === 0 ? 0 : currentLoading - 1;
    }
    ctx.patchState({ loadingDecoPdf: currentLoading });
  }

  @Action(SearchLoadingProcessing) searchLoadingProcessing(
    ctx: StateContext<IPrintStateModel>,
    payload: SearchLoadingProcessing
  ) {
    let currentLoading = ctx.getState().loadingProcessing;
    if (payload.isLoading) {
      currentLoading += 1;
    } else {
      currentLoading = currentLoading === 0 ? 0 : currentLoading - 1;
    }
    ctx.patchState({ loadingProcessing: currentLoading });
  }

  @Action(SearchLoadingDeco) searchLoadingDeco(
    ctx: StateContext<IPrintStateModel>,
    payload: SearchLoadingDeco
  ) {
    let currentLoading = ctx.getState().loadingDeco;
    if (payload.isLoading) {
      currentLoading += 1;
    } else {
      currentLoading = currentLoading === 0 ? 0 : currentLoading - 1;
    }
    ctx.patchState({ loadingDeco: currentLoading });
  }

  @Action(SearchLoadingArchievedDeco) searchLoadingArchievedDeco(
    ctx: StateContext<IPrintStateModel>,
    payload: SearchLoadingArchievedDeco
  ) {
    let currentLoading = ctx.getState().loadingDecoArchieved;
    if (payload.isLoading) {
      currentLoading += 1;
    } else {
      currentLoading = currentLoading === 0 ? 0 : currentLoading - 1;
    }
    ctx.patchState({ loadingDecoArchieved: currentLoading });
  }

  @Action(FetchDesignSpecification) fetchDesignSpecification(
    ctx: StateContext<IPrintStateDecoModel | IPrintStateModel>,
    payload: FetchDesignSpecification
  ) {
    const decoDesignId = payload.decoDesignId;
    ctx.patchState({
      decoDesignId,
      failedLoadingProcessing: false,
      failedLoadingProcessingArchieved: false,
    });

    if (decoDesignId) {
      ctx.dispatch(new SearchLoadingDeco(true));
      this.logger.info('Fetching deco specification for decoration design id:', decoDesignId);
      this.decorationSpecificationSubscription = this.backend
        .getDesignSpecificationData(decoDesignId)
        .subscribe(
          (res) => {
            this.logger.info('Got Decoration Specification ', res);
            ctx.patchState({ decoSpecificationInformation: res });
            ctx.dispatch(new SearchLoadingDeco(false));
          },
          (err: HttpErrorResponse) => {
            this.logError(err, ctx, 'Decoration Specification', true, false);
          }
        );

      ctx.dispatch(new SearchLoadingArchievedDeco(true));
      this.archivedDecorationSpecificationSubscription = this.backend
        .getArchivedDesignSpecificationData(decoDesignId)
        .subscribe(
          (res) => {
            this.logger.info('Got archived Decoration Specification ', res);
            ctx.patchState({
              archivedDecoSpecificationInformation: [res],
              failedLoadingProcessingArchieved: false,
            });
            ctx.dispatch(new SearchLoadingArchievedDeco(false));
          },
          (err) => {
            this.logError(err, ctx, 'archived Decoration Specification', true, true);
          }
        );
    }
  }

  private logError(
    error: HttpErrorResponse,
    ctx: StateContext<IPrintStateDecoModel | IPrintStateModel>,
    text: string,
    isDeco: boolean,
    isArchive: boolean
  ) {
    if (error.status === 404) {
      this.logger.info(`${text} is not found`);
    } else if (error.status === 403) {
      this.logger.info(`No access to this material`, error);
    } else {
      this.logger.error(`Failed getting ${text}`, error);
    }
    if (isDeco) {
      if (isArchive) {
        ctx.patchState({ archivedDecoSpecificationInformation: undefined });
        ctx.dispatch(new SearchLoadingArchievedDeco(false));
      } else {
        ctx.patchState({
          decoSpecificationInformation: undefined,
          failedLoadingProcessing: false,
        });
        ctx.dispatch(new SearchLoadingDeco(false));
      }
      // tslint:disable-next-line: no-collapsible-if
    } else {
      if (isArchive) {
        ctx.patchState({ archivedProcessingCardInformation: undefined });
        ctx.dispatch(new SearchLoadingArchievedProcessing(false));
      } else {
        ctx.patchState({ processingCardInformation: undefined });
        ctx.dispatch(new SearchLoadingProcessing(false));
      }
    }
    const statusCode = error.status;
    if (statusCode === 401 || statusCode === 403) {
      if (isArchive) {
        ctx.patchState({ failedLoadingProcessingArchieved: true });
      } else {
        ctx.patchState({ failedLoadingProcessing: true });
      }
    }
  }
}

export interface IPrintStateModel {
  archivedProcessingCardInformation?: IArchivedProcessingCardInformation;
  physicalDesignId?: number;
  processingCardInformation?: IProcessingCardInformation;
  loadingProcessing: number;
  loadingProcessingArchieved: number;
  failedLoadingProcessing: boolean;
  failedLoadingProcessingArchieved: boolean;
  loadingProcessingPdf: number;

  loadingDeco: number;
  loadingDecoArchieved: number;
  loadingDecoPdf: number;
}

export interface IProcessingCardInformation {
  _type: string;
  plantId: string;
  decorationPhysicalId: string;
  processingCards: IProcessingCardInfo[];
  decorationDesignId: string;
  decorationDesignDescription: string;
  launchId: string;
  error: string;
}

export interface IProcessingCardInfo {
  plantId: string;
  decorationPhysicalId: number;
  platformMachineTypeId: string;
  platformDisplayName: string;
  genericFlexId: number;
  npiStatusOptionCode: string;
}

export interface IArchivedProcessingCardInformation extends IProcessingCardInformation {
  _type: string;
  processingCards: IArchivedProcessingCardInfo[];
}

export interface IArchivedProcessingCardInfo extends IProcessingCardInfo {
  machineTypeId: number;
}

export interface IPrintStateDecoModel {
  decoDesignId?: number;
  decoSpecificationInformation?: IDecoSpecification;
  archivedDecoSpecificationInformation?: IArchivedDecoSpecification[];
}

export interface IDecoSpecification {
  decorationDesignId: string;
  graphicsVersion: number;
  masterDataVersion: number;
  statusCode: string;
  modifiedIsoDate: string;
  decorationDescription: string;
}

export interface IArchivedDecoSpecification {
  decorationDesignId: string;
  modifiedIsoDate: string;
}
