import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MsalConfig, MsalModule, MsalService } from '@azure/msal-angular';
import { MSAL_CONFIG } from '@azure/msal-angular/dist/msal.service';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NoCacheHeadersInterceptor } from '@shared/cache-control/NoCacheHeadersInterceptor';
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
// tslint:disable-next-line: ordered-imports
import { en_US, NgZorroAntdModule, NzFormModule, NzMessageModule, NZ_I18N } from 'ng-zorro-antd';
import { PrintState } from 'projects/easy-print/src/app/ngxs/states/print-state';
import { PlantState } from 'projects/processing-card/src/app/ngxs/states/plant-state';
import { SearchState } from 'projects/processing-card/src/app/ngxs/states/search-state';
import { DecoBriefSharedModule } from '../../projects/deco-brief/src/app/app.module';
import { ProcessingCardSharedModule } from '../../projects/processing-card/src/app/app.module';
import { AuthModule } from '../../projects/shared/src/lib/auth/auth.module';
import { MsalComponent } from '../../projects/shared/src/lib/auth/msal.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const isIEOrEdge =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1 ||
  window.navigator.userAgent.indexOf('Edge') > -1;

export function msalConfigFactory(): MsalConfig {
  return {
    // This is the MsalConfig object - it has to be a factory function so the evaluation of redirectUri is postponed until runtime in the production build compilation
    authority: 'https://login.microsoftonline.com/1d063515-6cad-4195-9486-ea65df456faa/', // LEGO AAD TenantID
    cacheLocation: 'localStorage',
    clientID: environment.clientId,
    consentScopes: ['openid', 'email', 'profile', environment.rest_api_oauth_scope],
    logger: environment.production ? undefined : loggerCallback,
    popUp: false, // !isIE,
    protectedResourceMap,
    redirectUri: `${window.location.origin}`,
    storeAuthStateInCookie: isIEOrEdge,
  };
}

// tslint:disable-next-line: no-unused
export function loggerCallback(logLevel, message) {
  if (!environment.production) {
    // tslint:disable-next-line: no-console
    console.log('MSAL :: client logging', message);
  }
}

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']], // MS Graph API URL
];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, MsalComponent],
  entryComponents: [MsalComponent],
  imports: [
    environment.enable_oauth ? AuthModule : [],
    environment.enable_oauth ? MsalModule : [],
    BrowserModule,
    AppRoutingModule,
    DecoBriefSharedModule.forRoot(),
    FormsModule,
    NzMessageModule,
    NgZorroAntdModule,
    NzFormModule,
    ProcessingCardSharedModule.forRoot(),
    NgxsModule.forRoot([PrintState, PlantState, SearchState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['plantstate'],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
  ],
  providers: [
    { provide: LoggerService, useClass: ConsoleLoggerService },
    MsalService,
    {
      provide: MSAL_CONFIG, // MsalService needs config, this provides it.
      useFactory: msalConfigFactory,
    },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_US },
  ],
})
export class AppModule {
  public static ngDoBootstrap(ref: ApplicationRef) {
    const isIFrame = window !== window.parent && !window.opener;
    if (isIFrame) {
      ref.bootstrap(MsalComponent);
    } else {
      ref.bootstrap(AppComponent);
    }
  }
}
