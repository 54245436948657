import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { SelectionOption } from 'projects/deco-brief/src/app/model/selection-option';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BackendService } from '../../backend.service';
import {
  IArchivedDecoSpecification,
  IArchivedProcessingCardInformation,
  IDecoSpecification,
  IProcessingCardInformation,
  PrintState,
} from '../../ngxs/states/print-state';

@Component({
  selector: 'app-print-top',
  styleUrls: ['./print-top.component.less'],
  templateUrl: './print-top.component.html',
})
export class PrintTopComponent implements OnInit, OnDestroy {
  @Input() statusOptions: SelectionOption[];

  // tslint:disable-next-line: no-unbound-method
  @Select(PrintState.physicalDesignId) physicalDesignId$: Observable<number | undefined>;

  // tslint:disable-next-line: no-unbound-method
  @Select(PrintState.decoDesignId) decoDesignId$: Observable<number | undefined>;
  // tslint:disable-next-line: no-unbound-method
  @Select(PrintState.processingCardInformation) processingCardInformation$: Observable<
    IProcessingCardInformation | undefined
  >;
  // tslint:disable-next-line: no-unbound-method prefer-inline-decorator
  @Select(PrintState.archivedProcessingCardInformation)
  archivedProcessingCardInformation$: Observable<IArchivedProcessingCardInformation | undefined>;

  // tslint:disable-next-line: no-unbound-method
  @Select(PrintState.decoSpecificationInformation) decoSpecificationInformation$: Observable<
    IDecoSpecification | undefined
  >;

  // tslint:disable-next-line: no-unbound-method
  @Select(PrintState.archivedDecoSpecificationInformation) // tslint:disable-line: prefer-inline-decorator
  archivedDecoSpecificationInformation$: Observable<IArchivedDecoSpecification | undefined>;

  imageUrl = `./assets/not-available.png`;
  processingCardHeader: IProcessingCardInformation;
  archivedProcessingCardHeader: IArchivedProcessingCardInformation;

  decoSpecificationHeader: IDecoSpecification;
  archivedDecoSpecificationHeader: IArchivedDecoSpecification;

  private printStateSubscription: Subscription;
  private getProdSpecSubscription: Subscription;
  private processingCardsStateSubscription: Subscription;
  private archivedProcessingCardsStateSubscription: Subscription;
  private decoSpecificationStateSubscription: Subscription;

  constructor(
    private logger: LoggerService,
    private backend: BackendService,
    private http: HttpClient,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.statusOptions = this.route.snapshot.data.statusOptions as SelectionOption[];
  }

  ngOnInit() {
    this.route.params.subscribe((routeParams) => {
      this.cancelDataSubscriptions();
      if (routeParams.id !== '0') {
        this.processingCardsStateSubscription = this.processingCardInformation$.subscribe(
          (procCardInfo) => {
            this.processingCardHeader = procCardInfo;
          }
        );
        this.archivedProcessingCardsStateSubscription = this.archivedProcessingCardInformation$.subscribe(
          (procCardInfo) => {
            this.archivedProcessingCardHeader = procCardInfo;
          }
        );

        this.decoSpecificationStateSubscription = this.decoSpecificationInformation$.subscribe(
          (decoSpecInfo) => {
            this.decoSpecificationHeader = decoSpecInfo;
          }
        );

        this.decoSpecificationStateSubscription = this.archivedDecoSpecificationInformation$.subscribe(
          (decoSpecInfo) => {
            this.archivedDecoSpecificationHeader = decoSpecInfo;
          }
        );

        if (this.router.url.includes('/print/processing')) {
          this.printStateSubscription = this.physicalDesignId$.subscribe((designId) => {
            this.imageUrl = this.getImageUrl(designId);
          });
        }

        if (this.router.url.includes('/print/design')) {
          this.printStateSubscription = this.decoDesignId$.subscribe((designId) => {
            this.imageUrl = this.getImageUrl(designId);
          });
        }
      } else {
        this.processingCardHeader = undefined;
        this.archivedProcessingCardHeader = undefined;
        this.decoSpecificationHeader = undefined;
        this.archivedDecoSpecificationHeader = undefined;
        this.imageUrl = undefined;
      }
    });
    this.logger.info('Initializing PrintTopComponent');
  }

  cancelDataSubscriptions() {
    if (this.printStateSubscription) {
      this.printStateSubscription.unsubscribe();
    }
    if (this.getProdSpecSubscription) {
      this.getProdSpecSubscription.unsubscribe();
    }
    if (this.processingCardsStateSubscription) {
      this.processingCardsStateSubscription.unsubscribe();
    }
    if (this.archivedProcessingCardsStateSubscription) {
      this.archivedProcessingCardsStateSubscription.unsubscribe();
    }
    if (this.decoSpecificationStateSubscription) {
      this.decoSpecificationStateSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.cancelDataSubscriptions();
  }

  private getImageUrl(designId) {
    return `https://dkawww-pic.corp.lego.com/any/64x64/raw/${designId}.jpg`;
  }
}
