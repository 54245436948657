import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BroadcastService } from '@azure/msal-angular';
import { Severity } from '@sentry/browser';
import { CollapseService } from '@shared/collapse.service';
import { LoggerService } from '@shared/logger/logger.service';
import { SentryContextService } from '@shared/sentry/sentry-context.service';
// import { SentryErrorHandler } from '@shared/sentry/sentry-error-handler';
import { UserService } from '@shared/user.service';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  // tslint:disable:component-selector
  selector: 'app-root',
  styleUrls: ['./app.component.less'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  public loggedIn: boolean;
  public spinner: boolean;
  private subscription: Subscription;

  public constructor(
    @Optional() private userService: UserService,
    @Optional() private broadcastService: BroadcastService,
    public collapseService: CollapseService,
    private router: Router,
    private sentryContextService: SentryContextService,
    private titleService: Title,
    // private sentryErrorHandler: SentryErrorHandler,
    private logger: LoggerService
  ) {
    this.titleService.setTitle('Decoration Brief');
  }

  public ngOnInit() {
    if (environment.enable_oauth) {
      this.broadcastService.subscribe('msal:loginFailure', (payload) => {
        this.logger.info(`login failure ${JSON.stringify(payload)}`);
        this.loggedIn = false;
      });

      this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
        this.logger.info(`login success ${JSON.stringify(payload)}`);
        this.loggedIn = true;
        // TODO: Make REST request here to backend and fetch user (including photo)
        const userlogin = `User Login: ${JSON.stringify(this.userService.getUser())}`;
        this.logger.userLogin(userlogin);
        // this.sentryErroHandler.handleUserLogin(userlogin);
      });

      if (!this.userService.userExists()) {
        if (this.userService.isCallback(window.location.hash)) {
          this.logger.info(`authService.isCallback: ${window.location.hash}`);
        } else {
          this.login();
        }
      } else {
        this.logger.info(
          'Authenticated userIdentifier: ',
          this.userService.getUserIdentifier(),
          `name: ${this.userService.getUserName()}`
        );
        this.sentryContextService.setUser(this.userService.getUserName());
        this.sentryContextService.addBreadcrumb({
          category: 'auth',
          level: Severity.Info,
          message: `Authenticated user ${this.userService.getUserName()}`,
        });
      }
    }

    if (this.router.navigated) {
      this.router.navigate(['/decobrief/main']);
    }
  }

  public ngOnDestroy() {
    if (!environment.enable_oauth) {
      return;
    }
    if (this.broadcastService) {
      this.broadcastService.getMSALSubject().next(1);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public get isIframe() {
    return window !== window.parent && !window.opener;
  }

  public login() {
    this.logger.info('Triggering login');
    this.userService.loginRedirect(['user.read']);
  }
}
