import { Component, OnInit } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import { BackendService } from '../../services/backend.service';

@Component({
  selector: 'app-test-pdf',
  styleUrls: ['./test-pdf.component.less'],
  templateUrl: './test-pdf.component.html',
})
export class TestPdfComponent implements OnInit {
  pdfSrc: Blob;

  constructor(private backendService: BackendService, private logger: LoggerService) {}

  ngOnInit() {
    this.fetchProcessingCard('decorationPhysicalId', '146575', '54574');
  }

  fetchProcessingCard(decorationPhysicalId: string, plantId: string, platformId: string) {
    this.backendService
      .getProcessingCardPdf(decorationPhysicalId, plantId, platformId)
      .subscribe((data) => {
        this.logger.info('data: ', data);
        this.pdfSrc = data;
      });
  }
}
