import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { IPlantSelection } from '@shared/plant-selection';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { PlantState } from 'projects/processing-card/src/app/ngxs/states/plant-state';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { GenericPlatform } from '../../../../model/generic-platform';
import { ProcessingCardInfo } from '../../../../model/processing-card-info';
import { DoSearch } from '../../../../ngxs/actions/search-actions';
import { ISearchStateModel, SearchState } from '../../../../ngxs/states/search-state';
import { BackendService } from '../../../../services/backend.service';

@Component({
  selector: 'app-edit-page',
  styleUrls: ['./edit-page.component.less'],
  templateUrl: './edit-page.component.html',
})
export class EditPageComponent implements OnInit, OnDestroy {
  genericPlatformOptions: GenericPlatform[];
  searchResultSubscription: Subscription;
  selectedPlantSubscription: Subscription;
  searchResult: ISearchStateModel;
  selectedPlant: IPlantSelection;

  @Select(SearchState) searchState$: Observable<ISearchStateModel>;
  @Select(PlantState.getSelectedPlant) selectedPlant$: Observable<IPlantSelection>;
  @Select(SearchState.getProcessingCards) processingCards$: Observable<ProcessingCardInfo[]>;

  constructor(
    private route: ActivatedRoute,
    private modalService: NzModalService,
    private backendService: BackendService,
    private messageService: NzMessageService,
    private logger: LoggerService,
    private store: Store,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {}

  confirmDelete(plantId: number, decorationPhysicalId: string, machineTypeId: string) {
    this.modalService.confirm({
      nzCancelText: 'No',
      nzContent:
        '<b>This will permanently delete the processing card<br/></b>Are you sure you want to delete it?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      // tslint:disable-next-line: no-void-expression
      nzOnOk: () => this.deleteProcessingCard(plantId, decorationPhysicalId, machineTypeId),
      nzTitle: 'Confirmation',
    });
  }

  ngOnInit() {
    const decorationPhysicalId = this.store.selectSnapshot<ISearchStateModel>(SearchState)
      .decorationPhysicalId;

    this.selectedPlantSubscription = this.selectedPlant$.subscribe((plant) => {
      this.selectedPlant = plant;
      this.store.dispatch(new DoSearch(decorationPhysicalId, plant && plant.plantId));
    });

    this.genericPlatformOptions = this.route.snapshot.data
      .genericPlatformOptions as GenericPlatform[];
    this.searchResultSubscription = this.searchState$.subscribe((searchResult) => {
      if (searchResult) {
        this.searchResult = searchResult;
      }
    });
  }

  ngOnDestroy() {
    if (this.searchResultSubscription) {
      this.searchResultSubscription.unsubscribe();
    }
    if (this.selectedPlantSubscription) {
      this.selectedPlantSubscription.unsubscribe();
    }
  }

  print(decorationPhysicalId: string, plantId: string, platformId: string) {
    this.backendService.getProcessingCardPdf(decorationPhysicalId, plantId, platformId).subscribe(
      (data) => {
        this.generateDownloadProcessing(data, decorationPhysicalId);
      },
      (error: HttpErrorResponse) => {
        const errorMessage = `There was an error fetching the pdf for decoration physical id ${decorationPhysicalId}. Please report this error.`;
        console.error('error message', errorMessage);
      }
    );
  }

  generateDownloadProcessing(pdfFileForDownload: Blob, decorationPhysicalId: string) {
    this.download('pc', decorationPhysicalId, pdfFileForDownload);
  }

  download(prefix: string, id: string, pdfFileForDownload: Blob) {
    const file = new Blob([pdfFileForDownload], { type: 'application/pdf' });
    const fileURL = this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file))
    );
    const dateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd_HHmmss');
    const fileName = `${prefix}_${id}_${dateTime}.pdf`;
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      window.navigator.msSaveOrOpenBlob(pdfFileForDownload, fileName);
    } else {
      const element = document.createElement('a');
      element.href = fileURL;
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    }
  }

  encodeValue(value: string) {
    return encodeURIComponent(value);
  }

  trackByFunction(index, item) {
    if (!item) {
      return undefined;
    }

    return index;
  }

  private deleteProcessingCard(
    plantId: number,
    decorationPhysicalId: string,
    machineTypeId: string
  ) {
    this.backendService
      .deleteProcessingCard(plantId, decorationPhysicalId, machineTypeId)
      .subscribe(
        (response) => {
          this.messageService.success('Processing card is successfully deleted');
          this.store.dispatch(new DoSearch(decorationPhysicalId, plantId.toString()));
        },
        (error) => {
          const errorMessage = 'Processing card could not be deleted. Please report this error.';
          this.logger.warn(errorMessage, error);
          this.messageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });
        }
      );
  }
}
