import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Optional, Output } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../user.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-profile-dropdown',
  styleUrls: ['./profile-dropdown.component.less'],
  templateUrl: './profile-dropdown.component.html',
})
export class ProfileDropdownComponent implements OnInit {
  @Output() public imageUrl;
  public visibleDrawer = false;

  public constructor(
    @Optional() private authService: UserService,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.imageUrl = './assets/avatar.png';
  }

  public ngOnInit() {
    this.fetchUserImage();
  }

  public get isAuthenticated() {
    return this.authService.userExists();
  }

  public fetchUser() {
    return this.httpClient.get('/api/v1/user');
  }

  public fetchUserImage() {
    this.fetchUser().subscribe((res: any) => {
      if (res && res.profilePhoto) {
        this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `data:image/jpg;base64,${res.profilePhoto}`
        );
      }
    });
  }

  public fetchUsername() {
    if (this.authService.userExists()) {
      return this.decodeUtf8(this.authService.getUserName());
    }

    return 'Unauthorized';
  }

  public openFeedback(): void {
    this.visibleDrawer = true;
  }

  public closeFeedback(): void {
    this.visibleDrawer = false;
  }

  private decodeUtf8(input: string) {
    return decodeURIComponent(escape(input));
  }
}
