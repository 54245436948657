import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IRoleGuardConfig, RoleGuard } from '@shared/auth/guards/role-guard.service';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { SearchResultTableComponent } from './components/main-content/advanced-search/search-result-table/search-result-table.component';
import { UploadMainComponent } from './components/main-content/upload/upload-main/upload-main.component';
import { StatusValuesResolver } from './resolvers/StatusValuesResolver';

const routes: Routes = [
  {
    component: AppComponent,
    path: 'decobrief',
    pathMatch: 'full',
  },
  // { path: 'decobrief/', redirectTo: 'decobrief/main', pathMatch: 'full' },
  { path: 'decobrief/frameredirect', component: AppComponent },
  { path: 'decobrief/accessdenied', component: AppComponent },
  {
    canActivate: environment.enable_oauth ? [RoleGuard] : undefined,
    children: [
      {
        component: UploadMainComponent,
        path: 'upload',
        resolve: { statusOptions: StatusValuesResolver },
      },
      {
        component: SearchResultTableComponent,
        path: 'advanced-search',
        resolve: { statusOptions: StatusValuesResolver },
      },
    ],
    component: AppComponent,
    data: {
      requiredRoles: ['designer', 'designer_admin'],
    } as IRoleGuardConfig,
    path: 'decobrief/main',
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
