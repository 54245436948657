import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import { UserService } from '@shared/user.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PreflightService {
  private readonly preflightIntegrationIsEnabled = environment.preflightIntegrationIsEnabled;
  private readonly PREFLIGHT_API_URL = '/api/v1/productbrief/preflight';
  private readonly PREFLIGHT_REPORT_URL = `${this.PREFLIGHT_API_URL}/file/`;
  private readonly PREFLIGHT_REPORT_DATA_URL = `${this.PREFLIGHT_API_URL}/data/`;

  constructor(
    private httpClient: HttpClient,
    private logger: LoggerService,
    private userService: UserService
  ) {}

  isPreflightIntegrationEnabled(): boolean {
    return this.preflightIntegrationIsEnabled;
  }

  postPreflightData(decorationDesignId: string, designStatus: string) {
    const userEmail = this.userService.getUserId();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const preflightDataUrl = `${this.PREFLIGHT_REPORT_DATA_URL}${decorationDesignId}`;

    const requestBody = {
      designStatus,
      userEmail,
    };

    return this.httpClient.post(preflightDataUrl, requestBody, { headers }).pipe(
      catchError((error) => {
        this.logger.error(error);

        return throwError(error);
      })
    );
  }

  getPreflightReport(decorationDesignId: string): Observable<Blob | undefined> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', ['application/pdf', 'application/json']);

    const url = `${this.PREFLIGHT_REPORT_URL}${decorationDesignId}`;

    return this.httpClient
      .get(url, { headers, responseType: 'blob' })
      .map((preflightReport) => (preflightReport.size ? preflightReport : undefined))
      .pipe(
        catchError((error) => {
          this.logger.error(error);

          return throwError(error);
        })
      );
  }
}
