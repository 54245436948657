import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BroadcastService } from '@azure/msal-angular';
import { RoleGuard } from '@shared/auth/guards/role-guard.service';
import { MsalComponent } from '@shared/auth/msal.component';
import { NoCacheHeadersInterceptor } from '@shared/cache-control/NoCacheHeadersInterceptor';
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
import { SharedModule } from '@shared/shared.module';
// tslint:disable-next-line: no-submodule-imports
import { NzButtonModule } from 'ng-zorro-antd/button';
// tslint:disable-next-line: no-submodule-imports
import { NzCardModule } from 'ng-zorro-antd/card';
// tslint:disable-next-line: no-submodule-imports
import { NzDividerModule } from 'ng-zorro-antd/divider';
// tslint:disable-next-line: no-submodule-imports
import { NzFormModule } from 'ng-zorro-antd/form';
// tslint:disable-next-line: no-submodule-imports
import { NzInputModule } from 'ng-zorro-antd/input';
// tslint:disable-next-line: no-submodule-imports
import { NzTableModule } from 'ng-zorro-antd/table';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { SystemUsersComponent } from './system-users/system-users.component';

const providers = [
  { provide: LoggerService, useClass: ConsoleLoggerService },
  { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
  BroadcastService,
  RoleGuard,
];
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, MainComponent, SystemUsersComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzCardModule,
    NzDividerModule,
    NzFormModule,
    NzInputModule,
    NzTableModule,
  ],
  providers,
})
export class AppModule {}

// tslint:disable-next-line: max-classes-per-file
@NgModule({})
export class AdminPagesSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers,
    };
  }

  public static ngDoBootstrap(ref: ApplicationRef) {
    const isIFrame = window !== window.parent && !window.opener;
    if (isIFrame) {
      ref.bootstrap(MsalComponent);
    } else {
      ref.bootstrap(AppComponent);
    }
  }
}
