import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-progressbar',
  styleUrls: ['./progressbar.component.less'],
  templateUrl: './progressbar.component.html',
})
export class ProgressbarComponent {
  @Input() public stepNo = 0;
  @Output() stepNoChange = new EventEmitter<number>();

  onIndexChange(index: number) {
    this.stepNo = index;
    this.stepNoChange.emit(this.stepNo);
  }
}
