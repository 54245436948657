import { Timestamp } from 'rxjs/internal-compatibility';

export class GlobalNotification {
  decoPhysicalId: string;
  globalNotificationId: string;
  globalNotificationType: string;
  globalNotificationMessage: string;
  modifiedBy: string;
  modifiedTime: string;

  constructor(data?: Partial<GlobalNotification>) {
    Object.assign(this, data);
  }
}
