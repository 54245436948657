/* tslint:disable */
import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '@shared/logger/logger.service';
import { NzMessageService } from 'ng-zorro-antd';
import { PreflightService } from 'projects/deco-brief/src/app/services/preflight.service';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BackendService } from '../../../../services/backend.service';
import { ProductBriefCreateInfo } from '../model/product-brief-create-info';
import { ProductBriefData } from '../model/product-brief-data';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-upload-create-step',
  styleUrls: ['./upload-create-step.component.less'],
  templateUrl: './upload-create-step.component.html',
})
export class UploadCreateStepComponent implements OnDestroy {
  @Input() productBriefList: ProductBriefData[];
  isCreating = false;
  message =
    'When you click this button the design specification(s) will be created and can immediately be accessed from LDP etc.';
  private productBriefUploadInfoList: ProductBriefCreateInfo[] = [];
  private preflightAndProductBriefRequests$: Observable<any>[] = [];
  private data$$: Subscription | undefined = undefined;

  constructor(
    private backendService: BackendService,
    private preflightService: PreflightService,
    private router: Router,
    private messageService: NzMessageService,
    private logger: LoggerService
  ) {}

  ngOnDestroy(): void {
    this.data$$.unsubscribe();
  }

  createProductBriefs(): void {
    if (this.productBriefList === undefined || this.productBriefList.length === 0) {
      this.messageService.error('No design specifications specified. Please report this error.', {
        nzDuration: 10000,
        nzPauseOnHover: true,
      });
    }

    this.isCreating = true;
    this.productBriefList.forEach((prodBriefData) => {
      this.productBriefUploadInfoList.push(
        new ProductBriefCreateInfo(
          prodBriefData.decorationDesignId,
          prodBriefData.decorationDescription,
          prodBriefData.decorationTemplateFileName,
          prodBriefData.statusCode
        )
      );
      if (this.preflightService.isPreflightIntegrationEnabled()) {
        this.preflightAndProductBriefRequests$.push(
          this.preflightService.postPreflightData(
            prodBriefData.decorationDesignId,
            prodBriefData.statusCode
          )
        );
      }
    });

    const productBriefs$ = this.backendService
      .postProductBriefs(this.productBriefUploadInfoList)
      .pipe(
        tap((status) => this.createProductBriefsFeedback(status)),
        catchError((error) => {
          const errorMessage =
            'There was an error creating the product brief(s). Please report this error.';
          this.logger.error(errorMessage, error);
          this.messageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });

          this.isCreating = false;

          return of(error);
        })
      );

    this.preflightAndProductBriefRequests$.push(productBriefs$);

    this.data$$ = forkJoin(this.preflightAndProductBriefRequests$).subscribe();
  }

  private createProductBriefsFeedback(data: any): void {
    this.isCreating = false;
    if (data !== undefined) {
      let success = `<p>These design specifications have successfully been created:</p>`;
      let failure = `<p>These design specifications were not created because of errors:</p>`;
      let successCount = 0;
      let failureCount = 0;
      data.forEach((fileUpload) => {
        if (fileUpload.success) {
          success += `<p>${fileUpload.decorationDesignId}</p>`;
          successCount += 1;
        } else {
          failure += `<p>${fileUpload.decorationDesignId}</p>`;
          failure += `<p>${fileUpload.errorMessage}</p>`;
          failureCount += 1;
          this.logger.error(fileUpload.errorMessage);
        }
      });
      if (successCount > 0) {
        this.messageService.success(success, {
          nzDuration: 5000,
          nzPauseOnHover: true,
        });
      }
      if (failureCount > 0) {
        this.messageService.error(failure, {
          nzDuration: 10000,
          nzPauseOnHover: true,
        });
      }
      if (this.router.navigated) {
        this.router.navigate(['/decobrief/main']);
      }
    }
  }
}
