import { Inject, Injectable } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public get(key) {
    return this.storage.get(key);
  }

  public set(key, value) {
    this.storage.set(key, value);
  }
}
