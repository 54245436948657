import { HttpErrorResponse } from '@angular/common/http';
import { Component, forwardRef, OnInit } from '@angular/core';
import {
  AbstractControl,
  Form,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@shared/logger/logger.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ISubscription } from 'rxjs/Subscription';
import { FormCanDeactivate } from '../../../form-can-deactivate/form-can-deactivate';
import { GlobalNotification } from '../../../model/global-notification';
import { SearchResult } from '../../../model/search-result';
import { ISelectionOption } from '../../../model/selection-option';
import { GlobalNotificationService } from '../../../services/global-notification.service';
import { SearchResultService } from '../../../services/search-result.service';

@Component({
  selector: 'app-global-notifications',
  styleUrls: ['./global-notifications.component.less'],
  templateUrl: './global-notifications.component.html',
})
export class GlobalNotificationsComponent extends FormCanDeactivate implements OnInit {
  get form(): FormGroup {
    return this.editorForm;
  }

  _$element: any; // jquery wrapped element
  editorForm: FormGroup;
  searchResultSubscription: ISubscription;
  searchResult: SearchResult;
  notificationTypes: ISelectionOption[];
  globalNotifications: GlobalNotification[] = [];
  hasBeenInitialized = false;
  config = {
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
    height: 'auto',
    maximumImageFileSize: 1048576,
    minHeight: '100px',
    placeholder: '',
    tabsize: 2,
    toolbar: [
      // [groupName, [list of button]]
      ['misc', ['undo', 'redo']],
      [
        'font',
        ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear'],
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'hr']],
    ],
    uploadImagePath: '',
  };

  constructor(
    private fb: FormBuilder,
    private searchResultService: SearchResultService,
    private gnService: GlobalNotificationService,
    private route: ActivatedRoute,
    private modalService: NzModalService,
    private messageService: NzMessageService,
    private logger: LoggerService,
    private ts: TranslateService
  ) {
    super();
    this.notificationTypes = this.route.snapshot.data.notificationTypes as ISelectionOption[];
    this.editorForm = this.fb.group({
      editorArray: this.fb.array([]),
    });
  }

  get editorForms(): FormArray {
    return this.editorForm.get('editorArray') as FormArray;
  }

  addEditor() {
    this.editorForms.push(this.addEditorFormGroup());
  }

  addEditorFormGroup(): FormGroup {
    // tslint:disable:no-unbound-method
    return this.fb.group({
      decoPhysicalId: [this.searchResult.decorationPhysicalId, Validators.required],
      // tslint:disable-next-line: no-null-keyword
      globalNotificationId: [null, undefined],
      globalNotificationMessage: ['', Validators.required],
      // tslint:disable-next-line: no-null-keyword
      globalNotificationType: [undefined, Validators.required],
      modifiedBy: [''],
      modifiedTime: [''],
    });
  }

  confirmDelete(editor: FormGroup, i) {
    this.modalService.confirm({
      nzCancelText: this.ts.instant('main-content.global-notifications.confirmDeleteCancel'),
      nzContent: this.ts.instant('main-content.global-notifications.confirmDeleteContent'),
      nzOkText: this.ts.instant('main-content.global-notifications.confirmDeleteYes'),
      nzOkType: 'danger',
      // tslint:disable-next-line: no-void-expression
      nzOnOk: () => this.removeEditor(editor, i),
      nzTitle: this.ts.instant('main-content.global-notifications.confirmDeleteTitle'),
    });
  }

  cancelChanges() {
    this.modalService.confirm({
      nzCancelText: this.ts.instant('main-content.global-notifications.cancelChangesCancel'),
      nzContent: this.ts.instant('main-content.global-notifications.cancelChangesContent'),
      nzOkText: this.ts.instant('main-content.global-notifications.cancelChangesYes'),
      nzOkType: 'danger',
      // tslint:disable-next-line: no-void-expression
      nzOnOk: () => this.fetchNotificationsFromBackend(),
      nzTitle: this.ts.instant('main-content.global-notifications.cancelChangesTitle'),
    });
  }

  addEditorFormGroups(notification: GlobalNotification[] | null): FormGroup[] {
    let formGroups: FormGroup[];

    if (notification) {
      formGroups = notification.map((x) => {
        return this.createFormGroup(
          x.decoPhysicalId,
          x.globalNotificationId,
          x.globalNotificationMessage,
          x.globalNotificationType,
          x.modifiedBy,
          x.modifiedTime
        );
      });
    } else {
      formGroups = [this.createFormGroup('', undefined, '', undefined, '', '')];
    }

    return formGroups;
  }

  removeEditor(editor: FormGroup, i) {
    if (editor.value.globalNotificationId !== null) {
      const notificationToRemove = new GlobalNotification();
      notificationToRemove.decoPhysicalId = editor.value.decoPhysicalId;
      notificationToRemove.globalNotificationId = editor.value.globalNotificationId;
      notificationToRemove.globalNotificationType = editor.value.globalNotificationType[0];
      notificationToRemove.globalNotificationMessage = editor.value.globalNotificationMessage;

      this.gnService.deleteGlobalNotification(notificationToRemove).subscribe(
        (data) => {
          this.messageService.success(
            this.ts.instant('main-content.global-notifications.removeEditorSuccess')
          );

          this.editorForms.removeAt(i);
          this.fetchNotificationsFromBackend();
        },
        (error: HttpErrorResponse) => {
          const errorMessage = this.ts.instant(
            'main-content.global-notifications.removeEditorError'
          );
          this.logger.warn(errorMessage, error);
          this.messageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });
        }
      );
    } else {
      this.editorForms.removeAt(i);
      this.fetchNotificationsFromBackend();
    }
  }

  async saveChanges() {
    const notificationsToSend: GlobalNotification[] = [];

    this.editorForms.controls.forEach((control) => {
      const value = control.value;

      const notification = new GlobalNotification();
      notification.decoPhysicalId = value.decoPhysicalId;
      notification.globalNotificationId = value.globalNotificationId;
      notification.globalNotificationType = value.globalNotificationType[0];
      notification.globalNotificationMessage = value.globalNotificationMessage;

      const MAX_SIZE_MEGABYTES = 1.04; // More than this will currently produce an error on the backend.
      if (notification.globalNotificationMessage.length > MAX_SIZE_MEGABYTES * 1000000) {
        this.showMessageTooLargeMessage(notification.globalNotificationType, MAX_SIZE_MEGABYTES);

        return;
      }
      notificationsToSend.push(notification);
    });

    await this.gnService.putGlobalNotifications(notificationsToSend).toPromise();

    this.fetchNotificationsFromBackend();
  }

  ngOnInit() {
    this.fetchNotificationsFromBackend();
  }

  fetchNotificationsFromBackend() {
    this.searchResultSubscription = this.searchResultService.getSearchResult().subscribe(
      (searchResult) => {
        if (searchResult) {
          this.searchResult = searchResult;
          this.getGlobalNotifications(this.searchResult.decorationPhysicalId);
        }
      },
      (error) => {
        const errorMessage = this.ts.instant(
          'main-content.global-notifications.fetchNotificationsError'
        );
        this.logger.warn(errorMessage, error);
        this.messageService.error(errorMessage, {
          nzDuration: 10000,
          nzPauseOnHover: true,
        });
      }
    );
  }

  getGlobalNotifications(decorationPhysicalId: string) {
    this.gnService.getGlobalNotifications(decorationPhysicalId).subscribe(
      (notifications) => {
        this.globalNotifications = notifications;
        this.editorForm.setControl(
          'editorArray',
          this.fb.array(this.addEditorFormGroups(this.globalNotifications))
        );

        // Hack: The summernote fields seem to be doing something asynchronously after initialization that with no event
        // we can listen for. Added delay instead.
        setTimeout(() => {
          this.editorForm.markAsPristine();
          this.hasBeenInitialized = true;
        }, 1000);
      },
      (error) => {
        this.hasBeenInitialized = true;
        if (error.status === 404) {
          this.editorForm.setControl('editorArray', this.fb.array([]));
          this.editorForms.reset();
        } else {
          const errorMessage = this.ts.instant(
            'main-content.global-notifications.getGlobalNotificationsError'
          );
          this.logger.warn(errorMessage, error);
          this.messageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });
        }
      }
    );
  }

  hasUnsavedChanges(): boolean {
    this.editorForms.controls.forEach((control) => {
      control.updateValueAndValidity();
    });

    if (this.editorForm !== undefined) {
      return this.editorForm.dirty && this.allAreValid() && this.hasBeenInitialized;
    }

    return false;
  }

  hasChanges(): boolean {
    this.editorForms.controls.forEach((control) => {
      control.updateValueAndValidity();
    });

    if (this.editorForm !== undefined) {
      return this.editorForm.dirty && this.hasBeenInitialized;
    }

    return false;
  }

  public trackByFunction(index, item) {
    if (!item) {
      return undefined;
    }

    return index;
  }

  private allAreValid(): boolean {
    let allAreValid = true;
    this.editorForms.controls.forEach((control) => {
      allAreValid = allAreValid && control.valid;
    });

    return allAreValid;
  }

  private createFormGroup(
    decoPhysicalId: string,
    globalNotificationId: string,
    globalNotificationMessage: string,
    globalNotificationType: string,
    modifiedBy: string,
    modifiedTime: string
  ) {
    return this.fb.group({
      decoPhysicalId: [decoPhysicalId, Validators.required],
      globalNotificationId: [globalNotificationId, Validators.required],
      globalNotificationMessage: [globalNotificationMessage, Validators.required],
      globalNotificationType: [[globalNotificationType], Validators.required],
      modifiedBy: [modifiedBy],
      modifiedTime: [modifiedTime],
    });
  }

  private showMessageTooLargeMessage(globalNotificationType, MAX_SIZE_MEGABYTES: number) {
    const prettyGlobalNotificationType = globalNotificationType
      .toLowerCase()
      .replace(new RegExp('_', 'g'), ' ');

    const message = `${this.ts.instant(
      'main-content.global-notifications.messageToLargeP1'
    )}"${prettyGlobalNotificationType}"${this.ts.instant(
      'main-content.global-notifications.messageToLargeP2'
    )} ${MAX_SIZE_MEGABYTES} ${this.ts.instant(
      'main-content.global-notifications.messageToLargeP3'
    )} `;
    this.messageService.error(message, {
      nzDuration: 5000,
      nzPauseOnHover: true,
    });
  }
}
