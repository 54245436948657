import { Injectable } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
// tslint:disable-next-line: no-implicit-dependencies
import { Observable } from 'rxjs';

@Injectable()
export class AllowAllGuard implements CanActivate {
  public constructor(private router: Router) {}

  public canActivate(
    // tslint:disable-next-line: no-unused
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
