import { Component } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { MsalService } from '@azure/msal-angular';

// This component is used only to avoid Angular reload
// when doing acquireTokenSilent()

@Component({
  // tslint:disable:component-selector
  selector: 'app-root',
  template: '',
})
export class MsalComponent {
  public constructor(private Msal: MsalService) {}
}
