import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-npi-status',
  styleUrls: ['./npi-status.component.less'],
  templateUrl: './npi-status.component.html',
})
export class NPIStatusComponent implements OnInit {
  ngOnInit() {
    return 'hello';
  }
}
