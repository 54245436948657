import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { ProcessingCardInfo } from 'projects/processing-card/src/app/model/processing-card-info';
import {
  DisableSearch,
  EnableSearch,
} from 'projects/processing-card/src/app/ngxs/actions/search-actions';
import { PlantState } from 'projects/processing-card/src/app/ngxs/states/plant-state';
import { SearchState } from 'projects/processing-card/src/app/ngxs/states/search-state';
import { combineLatest } from 'rxjs';
import { FormCanDeactivate } from '../../../../form-can-deactivate/form-can-deactivate';
import { ClicheData } from '../../../../model/cliche-data';
import { ClicheDataHelper } from '../../../../model/cliche-data-helper';
import { ColourOption } from '../../../../model/colour-option';
import { GenericPlatform } from '../../../../model/generic-platform';
import { NotificationDataHelper } from '../../../../model/notification-data-helper';
import { PrinterDataHelper } from '../../../../model/printer-data-helper';
import { PrinterSpecification } from '../../../../model/printer-specification';
import { ProcessingCard } from '../../../../model/processing-card';
import { ProcessingDataHelper } from '../../../../model/processing-data-helper';
import { SearchResult } from '../../../../model/search-result';
import { ISelectionOption } from '../../../../model/selection-option';
import { BackendService } from '../../../../services/backend.service';
import { SearchResultService } from '../../../../services/search-result.service';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-processing-card',
  styleUrls: ['./processing-card.component.less'],
  templateUrl: './processing-card.component.html',
})
export class ProcessingCardComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  get form(): FormGroup {
    return this.cardForm;
  }

  cardForm: FormGroup;
  actionType: string;
  productionToleranceOptions: ISelectionOption[];
  hardnessOptions: ISelectionOption[];
  thinnerOptions: ISelectionOption[];
  colourOptions: ColourOption[];
  selectedPlatformId: string;
  selectedGenericPlatform: GenericPlatform;
  decorationPhysicalId: string;
  selectedPlantKey = 'selectedPlant';
  plantId: string;
  processingCard: ProcessingCard;
  clicheData: ClicheData;
  processingDataHelper: ProcessingDataHelper;
  printerDataHelper: PrinterDataHelper;
  notificationDataHelper: NotificationDataHelper;
  technicalColours: ColourOption[];
  inkColours: ColourOption[];
  printerColours: ColourOption[];

  frontPrinters: number;
  backPrinters: number;
  newCard = false;

  private cardCopy: ProcessingCardInfo;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: BackendService,
    private searchResultService: SearchResultService,
    private messageService: NzMessageService,
    private modalService: NzModalService,
    private logger: LoggerService,
    private ts: TranslateService,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new DisableSearch());
    this.selectedPlatformId = this.route.snapshot.params.platform;
    this.actionType = this.route.snapshot.params.action;
    this.decorationPhysicalId = this.route.snapshot.params.decorationPhysicalId;
    this.productionToleranceOptions = this.route.snapshot.data
      .productionToleranceOptions as ISelectionOption[];
    this.hardnessOptions = this.route.snapshot.data.hardnessOptions as ISelectionOption[];
    this.thinnerOptions = this.route.snapshot.data.thinnerOptions as ISelectionOption[];
    this.technicalColours = this.route.snapshot.data.technicalColourOptions as ColourOption[];
    this.printerColours = [] as ColourOption[];
    this.inkColours = this.route.snapshot.data.inkColourOptions as ColourOption[];
    this.plantId = JSON.parse(localStorage.getItem(this.selectedPlantKey)).plantId;
    this.colourOptions = [] as ColourOption[];

    this.cardForm = new FormGroup({
      backPrinters: new FormArray([]),
      filmDataForm: new FormControl(''),
      frontPrinters: new FormArray([]),
      notificationDataForm: new FormControl(''),
      printerData: new FormControl(''),
      processingData: new FormControl(''),
    });

    this.cardCopy = this.store.selectSnapshot(SearchState.getCopyProcessingCard);

    const printerObservable = this.getGenericPlatformPrinterNumber(this.selectedPlatformId);
    const processingCardObservable = this.service.getProcessingCard(
      (this.cardCopy && this.cardCopy.plantId) || this.plantId,
      (this.cardCopy && encodeURIComponent(this.cardCopy.platformMachineTypeId)) ||
        this.selectedPlatformId,
      this.decorationPhysicalId
    );

    combineLatest([printerObservable, processingCardObservable])
      .map(([_printer, processingCard]) => processingCard)
      .subscribe(
        (data: ProcessingCard) => {
          if (data) {
            this.processingCard = new ProcessingCard(data);
            this.processingDataHelper = new ProcessingDataHelper(
              this.processingCard.genericFlexId,
              this.processingCard.productToleranceMm,
              this.processingCard.clicheId,
              this.processingCard.clicheStorageText
            );
            if (this.cardCopy) {
              this.processingCard.plantId = this.plantId;
            }
            // this.printerDataHelper = new PrinterDataHelper(this.processingCard.Printers);
            this.notificationDataHelper = new NotificationDataHelper(
              this.processingCard.notification
            );
          } else {
            this.processingDataHelper = new ProcessingDataHelper(
              '',
              this.productionToleranceOptions.filter(
                (tolerance) => tolerance.defaultSelection
              )[0].code,
              '',
              ''
            );
            this.notificationDataHelper = new NotificationDataHelper(undefined);
            // this.printerDataHelper = new PrinterDataHelper(undefined);
          }
          this.cardForm.controls.processingData.setValue(this.processingDataHelper);
          this.cardForm.controls.notificationDataForm.setValue(this.notificationDataHelper);
          this.clicheChangedEvent();

          let i = 0;
          for (; i < this.frontPrinters; i += 1) {
            const currentPrinter = (this.cardForm.controls.frontPrinters as FormArray).controls[i];
            const dataPrinter = data.Printers[i];
            this.setPrinterData(currentPrinter, dataPrinter);
          }

          for (let j = 0; j < this.backPrinters; j += 1) {
            const currentPrinter = (this.cardForm.controls.backPrinters as FormArray).controls[j];
            const dataPrinter = data.Printers[j + i];
            this.setPrinterData(currentPrinter, dataPrinter);
          }
          this.setColourOptions(undefined, this.printerColours);
          this.cardForm.markAsPristine();
        },
        (error: Response) => {
          if (error.status !== 404) {
            const errorMessage = this.ts.instant(
              'main-content.processing-cards.processingCard.retrieveProcessingCardError'
            );
            this.logger.warn(errorMessage, error);
            this.messageService.error(errorMessage, {
              nzDuration: 10000,
              nzPauseOnHover: true,
            });
          } else {
            this.newCard = true;
            this.cardForm.markAsPristine();
          }
        }
      );
    this.searchResultService.getSearchResult().subscribe(
      (searchResult) => {
        if (searchResult) {
          this.service
            .getDesignColourValues(searchResult.decorationDesignId)
            .subscribe((colourOptions) => {
              this.service
                .getElementColourValue(searchResult.colourId)
                .subscribe((colourOption) => {
                  const indexOf = colourOptions.indexOf(colourOption);
                  if (indexOf === -1) {
                    const elementColour = new ColourOption(colourOption);
                    this.technicalColours.push(elementColour);
                    this.setColourOptions(colourOptions, undefined);
                  }
                });
            });
        } else {
          this.colourOptions = [];
        }
      },
      (error) => {
        const errorMessage = this.ts.instant(
          'main-content.processing-cards.processingCard.retrieveDataError'
        );
        this.logger.warn(errorMessage, error);
        this.messageService.error(errorMessage, {
          nzDuration: 10000,
          nzPauseOnHover: true,
        });
        this.colourOptions = undefined;
      }
    );
  }

  setColourOptions(designColourOptions: ColourOption[], printerColours: ColourOption[]) {
    if (designColourOptions) {
      designColourOptions && designColourOptions.length > 0
        ? (this.colourOptions = designColourOptions)
        : (this.colourOptions = this.inkColours);
      const colourOptions = this.colourOptions;
      this.technicalColours.forEach((technicalColour: ColourOption) => {
        const indexOf = colourOptions.indexOf(technicalColour);
        if (indexOf === -1) {
          const technicalColor = new ColourOption(technicalColour);
          technicalColor.technical = true;
          colourOptions.push(technicalColor);
        }
      });
    }
    if (printerColours) {
      const colourOptions = this.colourOptions;
      printerColours.forEach((printerColour: ColourOption) => {
        let found = false;
        colourOptions.forEach((currentColour) => {
          if (currentColour.colourID === printerColour.colourID) {
            found = true;
          }
        });
        if (!found) {
          const outdatedColor = new ColourOption(printerColour);
          outdatedColor.outdated = true;
          colourOptions.push(outdatedColor);
        }
      });
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new EnableSearch());
  }

  submitForm(): void {
    const printerSpecifications: PrinterSpecification[] = [];
    let idx: number;
    let totalPrinters = 0;
    for (idx = 0; idx < this.frontPrinters; idx += 1, totalPrinters += 1) {
      const printer = (this.cardForm.controls.frontPrinters as FormArray).controls[idx];
      const printerSpecification = new PrinterSpecification(undefined);
      printerSpecification.printerNumber = `${totalPrinters + 1}`;
      this.setPrinterSpec(printerSpecification, printer);
      printerSpecification.printerType = 'FRONT';
      printerSpecifications.push(printerSpecification);
    }

    for (idx = 0; idx < this.backPrinters; idx += 1, totalPrinters += 1) {
      const printer = (this.cardForm.controls.backPrinters as FormArray).controls[idx];
      const printerSpecification = new PrinterSpecification(undefined);
      printerSpecification.printerNumber = `${totalPrinters + 1}`;
      this.setPrinterSpec(printerSpecification, printer);
      printerSpecification.printerType = 'BACK';
      printerSpecifications.push(printerSpecification);
    }
    this.clicheData = new ClicheData(this.cardForm.value.filmDataForm);
    this.clicheData.createFromClicheDataHelper(this.cardForm.value.filmDataForm);
    this.clicheData.clicheId = this.cardForm.value.processingData.clicheId;
    this.clicheData.platformMachineTypeId = this.selectedPlatformId;
    this.processingCard = new ProcessingCard(this.cardForm.value.processingData);
    this.processingCard.platformDisplayName = this.selectedPlatformId; // TODO FRJ - verify if this should be the display name or the id (displayName is selectable from the selectedGenericPlatform variable /Jonas)
    this.processingCard.decorationPhysicalId = this.decorationPhysicalId;
    this.processingCard.Printers = printerSpecifications;
    const MAX_SIZE_MEGABYTES = 1.04; // More than this will currently produce an error on the backend.
    if (this.cardForm.value.notificationDataForm.productionNotification) {
      if (
        this.cardForm.value.notificationDataForm.productionNotification.length >
        MAX_SIZE_MEGABYTES * 1000000
      ) {
        this.showMessageTooLargeMessage(MAX_SIZE_MEGABYTES);

        return;
      }
      this.processingCard.notification = this.cardForm.value.notificationDataForm.productionNotification;
    }
    this.modalService.confirm({
      nzCancelText: 'No',
      nzContent:
        '<p>If this is not your plant, you might overwrite the Processing Card on another plant</p>' +
        `<p>Are you sure you want to save this processing card to <b>Plant [${this.plantId}]</b></p>`,
      nzOkText: 'Yes',
      nzOkType: 'danger',
      // tslint:disable-next-line: no-void-expression
      nzOnOk: () => this.saveProcessingCard(),
      nzTitle: 'Confirmation',
    });
  }

  saveProcessingCard(): void {
    this.service
      .putProcessingCard(
        this.processingCard,
        this.plantId,
        this.selectedPlatformId,
        this.decorationPhysicalId
      )
      .subscribe(
        (data: ProcessingCard) => {
          this.searchRefresh();
          this.messageService.success(
            this.ts.instant('main-content.processing-cards.processingCard.saveSucess')
          );
          this.cardForm.markAsPristine();
          this.router.navigateByUrl(
            `/processingcard/main/processing-card/${
              this.actionType === 'create' ? 'create' : 'edit'
            }`
          );
        },
        (error: HttpErrorResponse) => {
          const errorMessage = this.ts.instant(
            'main-content.processing-cards.processingCard.saveError'
          );
          this.logger.warn(errorMessage, error);
          this.messageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });
        }
      );
    this.service
      .putClicheData(this.clicheData, this.selectedPlatformId, this.processingCard.clicheId)
      .subscribe(
        (data: ClicheData) => {
          this.searchRefresh();
          this.messageService.success(
            this.ts.instant('main-content.processing-cards.processingCard.saveSucess')
          );
          this.cardForm.markAsPristine();
          this.router.navigateByUrl(
            `/processingcard/main/processing-card/${
              this.actionType === 'create' ? 'create' : 'edit'
            }`
          );
        },
        (error: HttpErrorResponse) => {
          const errorMessage = this.ts.instant(
            'main-content.processing-cards.processingCard.saveError'
          );
          this.logger.warn(errorMessage, error);
          this.messageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });
        }
      );
  }

  searchRefresh() {
    let searchResult: SearchResult;
    this.service.searchPhysicalMaterial(this.plantId, this.decorationPhysicalId).subscribe(
      (data: SearchResult) => {
        searchResult = data;
        this.logger.info('Result:', data);
        this.searchResultService.changeSearchResult(searchResult);
      },
      (err: HttpErrorResponse) => {
        if (err.status === 400 || err.status === 404) {
          searchResult = new SearchResult(undefined);
          searchResult.error = searchResult.codeToTextMapping[err.status];
        } else {
          const errorMessage = `${this.ts.instant(
            'main-content.processing-cards.processingCard.searchErrorP1'
          )} ${this.plantId}${this.ts.instant(
            'main-content.processing-cards.processingCard.searchErrorP2'
          )}`;
          this.logger.warn(errorMessage, err);
          this.messageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });
        }
      }
    );
  }

  getGenericPlatformPrinterNumber(machineTypeId: string) {
    return this.service.getGenericPlatform(machineTypeId).do(
      (data: GenericPlatform) => {
        this.logger.info('data: ', data);
        this.selectedGenericPlatform = data;
        this.frontPrinters = this.selectedGenericPlatform.numberOfPrintersFront;
        this.backPrinters = this.selectedGenericPlatform.numberOfPrintersBack;
        for (let i = 0; i < this.frontPrinters; i += 1) {
          const fPrinters = this.cardForm.get('frontPrinters') as FormArray;
          fPrinters.push(this.getPrinterFormGroup());
        }
        for (let i = 0; i < this.backPrinters; i += 1) {
          const bPrinters = this.cardForm.get('backPrinters') as FormArray;
          bPrinters.push(this.getPrinterFormGroup());
        }
        this.logger.info(`numberOfPrintersFront:${this.frontPrinters}`);
        this.logger.info(`numberOfPrintersBack:${this.backPrinters}`);
      },
      (error) => {
        const errorMessage = `${this.ts.instant(
          'main-content.processing-cards.processingCard.genericPlaformErrorP1'
        )}${machineTypeId}${this.ts.instant(
          'main-content.processing-cards.processingCard.genericPlaformErrorP2'
        )}`;
        this.logger.warn(errorMessage, error);
        this.messageService.error(errorMessage, {
          nzDuration: 10000,
          nzPauseOnHover: true,
        });
      }
    );
  }

  cancelForm(event) {
    event.preventDefault();
    this.router.navigate([
      `/processingcard/main/processing-card/${this.actionType === 'create' ? 'create' : 'edit'}`,
    ]);
  }

  decodeValue(value: string) {
    return decodeURIComponent(value);
  }

  clicheChangedEvent(): void {
    this.service
      .getClicheData(this.selectedPlatformId, this.cardForm.value.processingData.clicheId)
      .subscribe(
        (data) => {
          // if (data) {
          const dataHelper = new ClicheDataHelper(data);
          this.cardForm.controls.filmDataForm.setValue(dataHelper);
          // }
        }
        // ,
        // (error) => {
        //   this.cardForm.controls.filmDataForm.setValue(undefined);
        // }
      );
  }

  private getPrinterFormGroup() {
    return new FormGroup({
      colour: new FormControl(''),
      comment: new FormControl(),
      hardness: new FormControl(),
      pad: new FormControl(),
      placement: new FormControl(),
      thinner: new FormControl(''),
    });
  }

  private setPrinterData(form: AbstractControl, dataPrinter: PrinterSpecification) {
    const placementText =
      (dataPrinter.placementText && dataPrinter.placementText.split(',')) || undefined;
    if (dataPrinter.colour) {
      form.get('colour').setValue(dataPrinter.colour.colourID);
      this.printerColours.push(dataPrinter.colour);
    } else {
      form.get('colour').setValue(undefined);
    }
    form.get('thinner').setValue(dataPrinter.thinnerText);
    form.get('hardness').setValue(dataPrinter.hardnessText);
    form.get('pad').setValue(dataPrinter.padText);
    form.get('placement').setValue(placementText);
    form.get('comment').setValue(dataPrinter.noteText);
  }

  private setPrinterSpec(printerSpecification: PrinterSpecification, printer: AbstractControl) {
    printerSpecification.colour = { colourID: printer.get('colour').value } as ColourOption;
    printerSpecification.thinnerText = printer.get('thinner').value;
    printerSpecification.hardnessText = printer.get('hardness').value;
    printerSpecification.padText = printer.get('pad').value;
    printerSpecification.placementText = '';

    if (printer.get('placement') && printer.get('placement').value) {
      printerSpecification.placementText = (printer.get('placement').value as string[]).join();
    }
    printerSpecification.noteText = printer.get('comment').value;
  }

  private showMessageTooLargeMessage(MAX_SIZE_MEGABYTES: number) {
    const prettyGlobalNotificationType = 'Production Notification'
      .toLowerCase()
      .replace(new RegExp('_', 'g'), ' ');

    const message = `${this.ts.instant(
      'main-content.global-notifications.messageToLargeP1'
    )}"${prettyGlobalNotificationType}"${this.ts.instant(
      'main-content.global-notifications.messageToLargeP2'
    )} ${MAX_SIZE_MEGABYTES} ${this.ts.instant(
      'main-content.global-notifications.messageToLargeP3'
    )} `;
    this.messageService.error(message, {
      nzDuration: 5000,
      nzPauseOnHover: true,
    });
  }
}
