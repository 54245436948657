import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminPagesSharedModule } from '../../projects/admin-pages/src/app/app.module';
import { DecoBriefSharedModule } from '../../projects/deco-brief/src/app/app.module';
import { EasyPrintSharedModule } from '../../projects/easy-print/src/app/app.module';
import { ProcessingCardSharedModule } from '../../projects/processing-card/src/app/app.module';

const routes: Routes = [
  {
    loadChildren: '../../projects/deco-brief/src/app/app.module#DecoBriefSharedModule',
    path: 'decobrief',
  },
  {
    loadChildren: '../../projects/processing-card/src/app/app.module#ProcessingCardSharedModule',
    path: 'processingcard',
  },
  {
    loadChildren: '../../projects/easy-print/src/app/app.module#EasyPrintSharedModule',
    path: 'print',
  },
  {
    loadChildren: '../../projects/admin-pages/src/app/app.module#AdminPagesSharedModule',
    path: 'admin',
  },
  // { path: '', redirectTo: 'decobrief/main', pathMatch: 'full' },
  { path: '**', redirectTo: 'decobrief/main' }, // This is a catch all line, so any url not mapped, will hit this and get redirected - normally ** is used for a PageNotFound
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes),
    DecoBriefSharedModule.forRoot(),
    ProcessingCardSharedModule.forRoot(),
    EasyPrintSharedModule.forRoot(),
    AdminPagesSharedModule.forRoot(),
  ],
})
export class AppRoutingModule {}
