import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
import { NzInputDirective, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { DecorationDesignDataFromSap } from '../../../../model/decoration-design-data-from-sap';
import { DesignProductBriefData } from '../../../../model/design-product-brief-data';
import { SelectionOption } from '../../../../model/selection-option';
import { BackendService } from '../../../../services/backend.service';
import { PdfViewerDialogComponent } from '../../../pdf-viewer-dialog/pdf-viewer-dialog.component';
import { ProductBriefData } from '../model/product-brief-data';

@Component({
  // tslint:disable-next-line:component-selector
  providers: [{ provide: LoggerService, useClass: ConsoleLoggerService }, PdfViewerDialogComponent],
  selector: 'app-upload-validate-step',
  styleUrls: ['./upload-validate-step.component.less'],
  templateUrl: './upload-validate-step.component.html',
})
export class UploadValidateStepComponent implements AfterViewInit {
  @Input() productBriefList: ProductBriefData[];
  @Input() statusOptions: SelectionOption[];
  @Output() public isValid = new EventEmitter<ProductBriefData[]>();
  @Output() public isNotValid = new EventEmitter();

  editId: string | undefined;

  @ViewChild(NzInputDirective, { static: false, read: ElementRef }) inputElement: ElementRef;

  constructor(
    private backendService: BackendService,
    private messageService: NzMessageService,
    private modalService: NzModalService,
    private logger: LoggerService,
    private datePipe: DatePipe,
    private authService: MsalService
  ) {}

  ngAfterViewInit() {
    // Data is fetched from SAP after a short delay to give the user the impression
    // that IDs are being checked after displaying the table.
    setTimeout(() => {
      for (const pb of this.productBriefList) {
        if (pb.decorationDesignIdIsValid()) {
          this.validateProductBriefInSap(pb);
        }
      }
      this.checkIfAllRowsAreValid();
    }, 1000);
  }

  @HostListener('window:click', ['$event']) handleClick(e: MouseEvent): void {
    if (this.editId && this.inputElement && this.inputElement.nativeElement !== e.target) {
      this.editId = undefined;
    }
  }

  trackByFileName(index: number, item: ProductBriefData): string {
    if (item) {
      return item.decorationTemplateFileName;
    }

    return undefined;
  }

  trackByCode(index: number, item: SelectionOption): string {
    if (item) {
      return item.code;
    }

    return undefined;
  }

  startEditDecorationDesignId(id: any, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.editId = id;
  }

  validateProductBriefInSap(productBrief: ProductBriefData): void {
    productBrief.hasBeenIdentifiedInSap = false;
    if (!productBrief.decorationDesignIdIsValid()) {
      productBrief.reset();

      return;
    }

    productBrief.isBeingFetchedFromSap = true;
    this.backendService.getSapMasterDataByDecorationId(productBrief.decorationDesignId).subscribe(
      (data) => {
        this.handleDataFromSap(data, productBrief);
      },
      (error: HttpErrorResponse) => {
        this.handleErrorFromSap(error, productBrief);
      }
    );
  }

  checkIfAllRowsAreValid(): void {
    const allAreValid = this.productBriefList.every((pb) => pb.isValid());
    const allDesignIds = this.productBriefList.map((pb) => pb.decorationDesignId);
    const allDesignIdsAreNotFetched = this.productBriefList.every(
      (pb) => !pb.isBeingFetchedFromSap
    );
    let hasDuplicates = false;
    this.productBriefList.forEach((pb, index) => {
      pb.isDuplicate = false;
      if (pb.decorationDesignId && allDesignIds.indexOf(pb.decorationDesignId) < index) {
        pb.isDuplicate = true;
        hasDuplicates = true;
      }
    });

    if (
      this.productBriefList.length > 0 &&
      allAreValid &&
      !hasDuplicates &&
      allDesignIdsAreNotFetched
    ) {
      this.isValid.emit(this.productBriefList);
    } else {
      this.isNotValid.emit(this.productBriefList);
    }
  }

  setStatusOnAll(statusCode: string) {
    this.productBriefList.forEach((pb) => (pb.statusCode = statusCode));
    this.checkIfAllRowsAreValid();
  }

  generateAndViewPdf(productBrief: ProductBriefData): void {
    const decorationDesignIdOrUndefined = productBrief.decorationDesignIdIsValid()
      ? productBrief.decorationDesignId
      : undefined;
    this.backendService
      .getGeneratedProductBriefPdf(
        productBrief.decorationTemplateFileName,
        decorationDesignIdOrUndefined,
        productBrief.statusCode
      )
      .subscribe(
        (data) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const dateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd_HHmmss');
          const fileName = `ds_${productBrief.decorationDesignId}_${dateTime}.pdf`;
          PdfViewerDialogComponent.createPdfViewerModal(this.modalService, file, fileName);
        },
        (error: HttpErrorResponse) => {
          const errorMessage = `There was an error fetching the pdf for decoration design id ${productBrief.decorationDesignId}. Please report this error.`;
          this.logger.warn(errorMessage, error);
          this.messageService.error(errorMessage, {
            nzDuration: 10000,
            nzPauseOnHover: true,
          });
        }
      );
  }

  removeRow(fileNameOfProductBriefToRemove: string) {
    this.productBriefList = this.productBriefList.filter(
      (data) => data.decorationTemplateFileName !== fileNameOfProductBriefToRemove
    );

    this.checkIfAllRowsAreValid();
  }

  getStatusText = (productBrief: ProductBriefData): string =>
    SelectionOption.getDisplayLabelForCode(this.statusOptions, productBrief.statusCode);

  private handleErrorFromSap(error: HttpErrorResponse, productBrief: ProductBriefData) {
    if (error.status === 404) {
      // Do nothing
    } else if (error.status === 403) {
      const errorMessage = `Can't fetch SAP data for decoration design id ${productBrief.decorationDesignId}: ${error.error.message}.`;
      this.logger.info(errorMessage, error);
      productBrief.sapDataErrors = [
        `${error.error.message}. Could be caused by missing launch date`,
      ];
    } else {
      const errorMessage = `There was an error fetching SAP data for decoration design id ${productBrief.decorationDesignId}. Please report this error.`;
      this.logger.warn(errorMessage, error);
      productBrief.sapDataErrors = ['Error fetching SAP data for this decoration design ID'];
    }
    productBrief.isBeingFetchedFromSap = false;
  }

  private logAndShowError(errorMessage: string, error: HttpErrorResponse) {
    this.logger.warn(errorMessage, error);
    this.messageService.error(errorMessage, {
      nzDuration: 10000,
      nzPauseOnHover: true,
    });
  }

  private handleDataFromSap(data, productBrief: ProductBriefData) {
    this.logger.info('SAP Masterdata received from server', data);
    if (data !== undefined) {
      const decorationDataFromSap = new DecorationDesignDataFromSap(data);
      if (decorationDataFromSap.decorationDesignId !== undefined) {
        productBrief.hasBeenIdentifiedInSap = true;
        productBrief.materialGroupId = decorationDataFromSap.materialGroupId;
        productBrief.materialGroupDescription = decorationDataFromSap.materialGroupDescription;
        productBrief.decorationDescription = decorationDataFromSap.decorationDescription;
        productBrief.sapDataErrors = decorationDataFromSap.getValidationErrorTexts();
      }
    }
    this.fetchDataFromProductBriefDatabase(productBrief);
    this.checkIfAllRowsAreValid();
    productBrief.isBeingFetchedFromSap = false;
  }

  private fetchDataFromProductBriefDatabase(productBrief: ProductBriefData) {
    this.backendService.getDesignProductBriefData(productBrief.decorationDesignId).subscribe(
      (data) => {
        this.handleDataFromDatabaseLookup(data, productBrief);
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          productBrief.versionText = 'None';
        } else {
          this.handleDatabaseLookupError(productBrief, error);
        }
      }
    );
  }

  private handleDataFromDatabaseLookup(
    data: DesignProductBriefData,
    productBrief: ProductBriefData
  ): void {
    // tslint:disable-next-line:prefer-conditional-expression
    if (data.decorationDesignId) {
      productBrief.isInFinalStatus = data.statusCode === 'APPROVED_MASTER' && !this.isAdmin();
      const WARNING_TEXT = productBrief.isInFinalStatus ? '' : ' (Will overwrite)';
      productBrief.versionText = `${data.graphicsVersion}.${data.masterDataVersion} ${WARNING_TEXT}`;
      productBrief.statusCode = data.statusCode;
    } else {
      this.handleDatabaseLookupError(productBrief);
    }
  }

  private handleDatabaseLookupError(productBrief: ProductBriefData, error?: HttpErrorResponse) {
    productBrief.versionText = 'Error';
    const errorMessage = `There was an error fetching data for decoration design id ${productBrief.decorationDesignId}. Please report this error.`;
    this.logAndShowError(errorMessage, error);
  }

  private isAdmin(): boolean {
    const user = this.authService.getUser();
    // tslint:disable-next-line: no-string-literal
    const userRoles = (((user && user.idToken && user.idToken['roles']) || ['user']) as Array<
      string
    >).map((roleName) => String(roleName).toLowerCase());

    return userRoles.indexOf('designer_admin') > -1;
  }
}
