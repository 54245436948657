import { ProcessingCardInfo } from 'projects/processing-card/src/app/model/processing-card-info';
import { SearchResult } from '../../model/search-result';

export class DoSearch {
  static readonly type = '[Search] Do Search';

  constructor(public decorationPhysicalId: string, public plantId?: string) {}
}

// tslint:disable-next-line:max-classes-per-file
export class SetSearchErrorMessage {
  static readonly type = '[Search] Set Search Error Message';

  constructor(public message: string) {}
}

// tslint:disable-next-line:max-classes-per-file
export class RemoveSearchErrorMessage {
  static readonly type = '[Search] Remove Search Error Message';
}

// tslint:disable-next-line:max-classes-per-file
export class SetSearchState {
  static readonly type = '[Search] Set Search State';

  constructor(public result: SearchResult) {}
}

// tslint:disable-next-line:max-classes-per-file
export class SetCopyProcessingCard {
  static readonly type = '[Search] Set Copy Processing Card';

  constructor(public processingCard: ProcessingCardInfo) {}
}

// tslint:disable-next-line:max-classes-per-file
export class ClearCopyProcessingCard {
  static readonly type = '[Search] Clear Copy Processing Card';
}

// tslint:disable-next-line:max-classes-per-file
export class DisableSearch {
  static readonly type = '[Search] Disable Search';
}

// tslint:disable-next-line:max-classes-per-file
export class EnableSearch {
  static readonly type = '[Search] Enable Search';
}

// tslint:disable-next-line:max-classes-per-file
export class SearchLoading {
  static readonly type = '[Search] Loading';

  constructor(public isLoading: boolean) {}
}
