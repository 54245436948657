import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@shared/auth/guards/role-guard.service';
import { StatusValuesResolver } from 'src/../projects/deco-brief/src/app/resolvers/StatusValuesResolver';
import { PlantSelectionResolver } from 'src/../projects/processing-card/src/app/resolvers/PlantSelectionResolver';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    canActivate: environment.enable_oauth ? [RoleGuard] : undefined,
    component: AppComponent,
    path: 'print',
    pathMatch: 'full',
  },
  // { path: 'decobrief/', redirectTo: 'decobrief/main', pathMatch: 'full' },
  { path: 'print/frameredirect', component: AppComponent },
  { path: 'print/accessdenied', component: AppComponent },
  {
    canActivate: environment.enable_oauth ? [RoleGuard] : undefined,
    component: AppComponent,
    path: 'print/design/:id',
    resolve: { statusOptions: StatusValuesResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    canActivate: environment.enable_oauth ? [RoleGuard] : undefined,
    component: AppComponent,
    path: 'print/processing/:id',
    resolve: { plantSelections: PlantSelectionResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    canActivate: environment.enable_oauth ? [RoleGuard] : undefined,
    path: 'print/design',
    redirectTo: 'print/design/0',
    resolve: { statusOptions: StatusValuesResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    canActivate: environment.enable_oauth ? [RoleGuard] : undefined,
    path: 'print/processing',
    redirectTo: 'print/processing/0',
    resolve: { statusOptions: StatusValuesResolver },
    runGuardsAndResolvers: 'always',
  },
  { path: '', redirectTo: 'print/', pathMatch: 'full' },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
