import { Injectable } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { MsalService } from '@azure/msal-angular';
// tslint:disable-next-line:no-implicit-dependencies
import { User } from 'msal';

// This class is responsible for encapsulating ALL access the the MSAL service, so that we
// can do something else, for example in case there is no user.
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private authService: MsalService) {}

  userExists() {
    return this.authService.getUser() !== null;
  }

  getUserId(): string {
    return this.getUser().displayableId;
  }

  getUserName(): string {
    return this.getUser().name;
  }

  getUserIdentifier(): string {
    return this.getUser().userIdentifier;
  }

  isCallback(hash: string): boolean {
    return this.authService.isCallback(hash);
  }

  loginRedirect(strings: string[]) {
    this.authService.loginRedirect(strings);
  }

  getUser() {
    if (this.userExists()) {
      return this.authService.getUser();
    }

    return new User('', '', '', '', '');
  }
}
