import { Injectable } from '@angular/core';
import { Logger } from './logger';

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements Logger {
  info: any;
  warn: any;
  error: any;
  userLogin: any;

  // tslint:disable-next-line: no-empty
  invokeConsoleMethod(type: string, args?: any): void {}
}
