import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// tslint:disable-next-line: match-default-export-name
import en from '@angular/common/locales/en';
import { ApplicationRef, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BroadcastService } from '@azure/msal-angular';
import { RoleGuard } from '@shared/auth/guards/role-guard.service';
import { MsalComponent } from '@shared/auth/msal.component';
import { NoCacheHeadersInterceptor } from '@shared/cache-control/NoCacheHeadersInterceptor';
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
import { ProfileDropdownComponent } from '@shared/profile/profile-dropdown.component';
import { SharedModule } from '@shared/shared.module';
// tslint:disable
import {
  en_US,
  NgZorroAntdModule,
  NzFormModule,
  NzMessageModule,
  NzModalModule,
  NzSpinModule,
  NZ_I18N,
} from 'ng-zorro-antd';
// tslint:enable
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SearchComponent } from './components/header/search/search.component';
import { SearchOptionsComponent } from './components/main-content/advanced-search/search-options/search-options.component';
import { SearchResultTableComponent } from './components/main-content/advanced-search/search-result-table/search-result-table.component';
import { ProgressbarComponent } from './components/main-content/upload/progressbar/progressbar.component';
import { UploadCreateStepComponent } from './components/main-content/upload/upload-create-step/upload-create-step.component';
import { UploadMainComponent } from './components/main-content/upload/upload-main/upload-main.component';
import { UploadSelectStepComponent } from './components/main-content/upload/upload-select-step/upload-select-step.component';
import { UploadValidateStepComponent } from './components/main-content/upload/upload-validate-step/upload-validate-step.component';
import { PdfViewerDialogComponent } from './components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { StartComponent } from './components/start/start.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { StatusValuesResolver } from './resolvers/StatusValuesResolver';

registerLocaleData(en);

const providers = [
  { provide: LoggerService, useClass: ConsoleLoggerService },
  BroadcastService,
  StatusValuesResolver,
  { provide: NZ_I18N, useValue: en_US },
  { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
  RoleGuard,
];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    // MsalComponent,
    StartComponent,
    HeaderComponent,
    SearchComponent,
    DateFormatPipe,
    UploadMainComponent,
    ProgressbarComponent,
    UploadSelectStepComponent,
    UploadValidateStepComponent,
    UploadCreateStepComponent,
    SideMenuComponent,
    SearchOptionsComponent,
    SearchResultTableComponent,
    PdfViewerDialogComponent,
  ],
  entryComponents: [PdfViewerDialogComponent, ProfileDropdownComponent],
  imports: [
    // environment.enable_oauth ? AuthModule : [],
    // environment.enable_oauth ? MsalModule : [],
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    NgxExtendedPdfViewerModule,
    NzMessageModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    // SentryModule.forRoot(environment.sentryConfig),
    NgZorroAntdModule,
    NzFormModule,
    NzModalModule,
    NzSpinModule,
  ],
  providers,
})
export class AppModule {
  // public static ngDoBootstrap(ref: ApplicationRef) {
  //   const isIFrame = window !== window.parent && !window.opener;
  //   if (isIFrame) {
  //     ref.bootstrap(MsalComponent);
  //   } else {
  //     ref.bootstrap(AppComponent);
  //   }
  // }
}

// tslint:disable-next-line: max-classes-per-file
@NgModule({})
export class DecoBriefSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers,
    };
  }

  public static ngDoBootstrap(ref: ApplicationRef) {
    const isIFrame = window !== window.parent && !window.opener;
    if (isIFrame) {
      ref.bootstrap(MsalComponent);
    } else {
      ref.bootstrap(AppComponent);
    }
  }
}
