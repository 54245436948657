export class DecorationDesignDataFromSap {
  private static codeToTextMapping = {
    NO_DECORATION_COLORS_FOR_DECORATED_ELEMENT: 'No decoration colors found.',
    NO_ELEMENT_DESIGN_ID_FOR_DECORATED_ELEMENT: 'No design ID for decorated element.',
    NO_PHYSICAL_MATERIAL_DECORATION: 'No physical material for the decoration.',
  };

  decorationDesignId: string;
  decorationDescription: string;
  materialGroupId: string;
  materialGroupDescription: string;
  validationErrors: string[];

  constructor(data: Partial<DecorationDesignDataFromSap>) {
    Object.assign(this, data);
  }

  getValidationErrorTexts() {
    return this.validationErrors.map((code) => DecorationDesignDataFromSap.codeToTextMapping[code]);
  }
}
