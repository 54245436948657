import { Injectable, Optional } from '@angular/core';
// import { environment } from '../../../environments/environment';
import { SentryErrorHandler } from '@shared/sentry/sentry-error-handler';
import { Logger } from './logger';

export let isDebugMode = true; // !environment.production;

const noop = (): any => undefined;

@Injectable({
  providedIn: 'root',
})
export class ConsoleLoggerService implements Logger {
  constructor(@Optional() private sentryErrorHandler: SentryErrorHandler) {}

  get info() {
    if (isDebugMode) {
      return console.info.bind(console);
    }

    return noop;
  }

  get warn() {
    if (isDebugMode) {
      return console.warn.bind(console);
    }

    return noop;
  }

  get userLogin() {
    if (isDebugMode) {
      return (...args: any) => {
        console.log.apply(console, args);
        const stringifiedArgs = JSON.stringify(args);
        if (this.sentryErrorHandler) {
          this.sentryErrorHandler.handleUserLogin(stringifiedArgs);
        }
      };
    }

    return noop;
  }

  get error() {
    if (isDebugMode) {
      return (...args: any) => {
        console.error.apply(console, args);
        const stringifiedArgs = JSON.stringify(args);
        if (this.sentryErrorHandler) {
          this.sentryErrorHandler.handleError(stringifiedArgs);
        }
      };
    }

    return noop;
  }

  invokeConsoleMethod(type: string, args?: any): void {
    // tslint:disable-next-line: ban-types no-unbound-method
    const logFn: Function = console[type] || console.log || noop;
    logFn.apply(console, [args]);
  }
}
