import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchResult } from '../model/search-result';

@Injectable({ providedIn: 'root' })
export class MessageService {
  public subject = new BehaviorSubject<Array<SearchResult>>(undefined);

  sendMessage(message: SearchResult[]) {
    this.subject.next(message);
  }

  clear() {
    this.subject.next(undefined);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
