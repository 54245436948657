import { Injectable } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import { addBreadcrumb, Breadcrumb, configureScope } from '@sentry/browser';

@Injectable({
  providedIn: 'root',
})
export class SentryContextService {
  public setUser(username: string) {
    configureScope((scope) => {
      scope.setUser({ username });
    });
  }

  public addBreadcrumb(breadcrumb: Breadcrumb) {
    addBreadcrumb(breadcrumb);
  }
}
