import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';
import { SearchResult } from '../../../model/search-result';
import { SearchResultService } from '../../../services/search-result.service';

@Component({
  selector: 'app-content-header',
  styleUrls: ['./content-header.component.less'],
  templateUrl: './content-header.component.html',
})
export class ContentHeaderComponent implements OnInit, OnDestroy {
  searchResult: SearchResult;
  imageUrl = `./assets/not-available.png`;
  searchResultSubscription: ISubscription;

  constructor(private searchResultService: SearchResultService) {}

  ngOnInit() {
    this.getSearchResult();
  }

  getSearchResult() {
    this.searchResultSubscription = this.searchResultService
      .getSearchResult()
      .subscribe((searchResult) => {
        if (searchResult) {
          this.searchResult = searchResult;
          this.imageUrl = `https://dkawww-pic.corp.lego.com/any/64x64/raw/${this.searchResult.decorationPhysicalId}.jpg`;
        }
      });
  }

  ngOnDestroy() {
    this.searchResultSubscription.unsubscribe();
  }
}
