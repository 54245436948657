import { PrinterSpecification } from './printer-specification';

export class ProcessingCard {
  plantId: string;
  decorationPhysicalId: string;
  platformMachineTypeId: string;
  platformDisplayName: string;
  genericFlexId: string;
  productToleranceMm: string;
  clicheId: string;
  clicheStorageText: string;
  lenFileDate: Date;
  lenPreparedByUserId: string;
  notification: string;
  modifiedBy: string;
  Printers: PrinterSpecification[];

  constructor(data: Partial<ProcessingCard>) {
    Object.assign(this, data);
  }
}
