import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollapseService } from '@shared/collapse.service';
// tslint:disable-next-line:ban-ts-ignore
// @ts-ignore
import { version } from 'src/../package.json';
// import { timingSafeEqual } from 'crypto';
// import { runInThisContext } from 'vm';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-header',
  styleUrls: ['./header.component.less'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  public version: string = version;
  router: string;

  public constructor(public collapseService: CollapseService, public _router: Router) {}
}
