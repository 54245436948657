import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchResult } from '../model/search-result';

@Injectable({ providedIn: 'root' })
export class SearchResultService {
  public searchResult = new BehaviorSubject<SearchResult>(undefined);

  changeSearchResult(searchResult: SearchResult) {
    this.searchResult.next(searchResult);
  }

  getSearchResult(): Observable<SearchResult> {
    return this.searchResult.asObservable();
  }
}
