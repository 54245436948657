import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { IPlantSelection } from '@shared/plant-selection';
import { ProcessingCardInfo } from 'projects/processing-card/src/app/model/processing-card-info';
import {
  ClearCopyProcessingCard,
  DoSearch,
  SetCopyProcessingCard,
} from 'projects/processing-card/src/app/ngxs/actions/search-actions';
import { PlantState } from 'projects/processing-card/src/app/ngxs/states/plant-state';
import {
  ISearchStateModel,
  SearchState,
} from 'projects/processing-card/src/app/ngxs/states/search-state';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { GenericPlatform } from '../../../../model/generic-platform';
import { CreatePageService } from './create-page.service';

@Component({
  selector: 'app-create-page',
  styleUrls: ['./create-page.component.less'],
  templateUrl: './create-page.component.html',
})
export class CreatePageComponent implements OnInit, OnDestroy {
  selectableGenericPlatformOptions: GenericPlatform[];
  combinedSubscription: Subscription;
  searchResult: ISearchStateModel;
  plants: IPlantSelection[];
  _selectedPlatform: string;
  _unencodedSelectedPlatform: string;

  get selectedPlatform() {
    return this._selectedPlatform;
  }

  set selectedPlatform(value: string) {
    this._unencodedSelectedPlatform = value;
    this._selectedPlatform = encodeURIComponent(value);
  }

  @Select(SearchState) searchState$: Observable<ISearchStateModel>;
  @Select(SearchState.getProcessingCards) processingCards$: Observable<ProcessingCardInfo[]>;
  @Select(PlantState.getSelectedPlant) selectedPlant$: Observable<IPlantSelection>;

  private genericPlatformOptions: GenericPlatform[];

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private logger: LoggerService,
    private router: Router,
    private createPageService: CreatePageService
  ) {}

  ngOnInit() {
    this.store.dispatch(new ClearCopyProcessingCard());
    const decorationPhysicalId = this.store.selectSnapshot<ISearchStateModel>(SearchState)
      .decorationPhysicalId;
    this.store.dispatch(new DoSearch(decorationPhysicalId));
    this.genericPlatformOptions = this.route.snapshot.data
      .genericPlatformOptions as GenericPlatform[];

    this.combinedSubscription = combineLatest([this.searchState$, this.selectedPlant$]).subscribe(
      ([searchState, selectedPlant]) => {
        if (searchState) {
          this.searchResult = searchState;
          this.setSelectableGenericPlatformOptions(selectedPlant);
        }
      }
    );
    this.plants = this.store.selectSnapshot(PlantState.getPlants);
  }

  ngOnDestroy() {
    if (this.combinedSubscription) {
      this.combinedSubscription.unsubscribe();
    }
  }

  trackByFunction(index, item) {
    if (!item) {
      return undefined;
    }

    return index;
  }

  isCopyEnabled(card: ProcessingCardInfo, newSelectedPlatform: string) {
    return encodeURIComponent(newSelectedPlatform) === this._unencodedSelectedPlatform
      ? this.createPageService.isCopyEnabledForPlatform(
          card.platformMachineTypeId,
          this._unencodedSelectedPlatform
        )
      : false;
  }

  copyCard(card: ProcessingCardInfo) {
    this.store.dispatch(new SetCopyProcessingCard(card));
    this.router.navigate([
      `/processingcard/main/processing-card/${this.selectedPlatform}/${this.searchResult.decorationPhysicalId}/create`,
    ]);
  }

  private setSelectableGenericPlatformOptions(selectedPlant: IPlantSelection) {
    this.selectableGenericPlatformOptions = [];
    for (const item of this.genericPlatformOptions || []) {
      if (item.active) {
        if (this.searchResult.processingCards) {
          const index = this.searchResult.processingCards.findIndex(
            (x) => x.platformDisplayName === item.displayName && x.plantId === selectedPlant.plantId
          );
          if (index === -1) {
            this.selectableGenericPlatformOptions.push(item);
          }
        } else {
          this.selectableGenericPlatformOptions.push(item);
        }
      }
    }
    const selectedIndex = this.selectableGenericPlatformOptions.findIndex(
      (platform) => platform.machineTypeId === this._selectedPlatform
    );
    if (selectedIndex === -1) {
      this._selectedPlatform = undefined;
    }
  }
}
