export class ProcessingDataHelper {
  genericFlexId: string;
  productToleranceMm: string;
  clicheId: string;
  clicheStorageText: string;

  constructor(
    genericFlexId: string,
    productToleranceMm: string,
    clicheId: string,
    clicheStorageText: string
  ) {
    this.genericFlexId = genericFlexId;
    this.productToleranceMm = productToleranceMm;
    this.clicheStorageText = clicheStorageText;
    this.clicheId = clicheId;
  }
}
