import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  // tslint:disable-next-line: pipe-prefix
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  public transform(date: string): Date {
    return new Date(parseInt(date.substr(6), 10));
  }
}
