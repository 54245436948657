export class ColourOption {
  colourID: string;
  colourDescription: string;
  colourType: string;
  red: string;
  green: string;
  blue: string;
  rgb: string;
  technical = false;
  outdated = false;

  constructor(data: Partial<ColourOption>) {
    Object.assign(this, data);
    if (this.red && this.green && this.blue) {
      this.rgb = `rgb(${this.red},${this.green},${this.blue})`;
    }
  }

  public getColour(): string {
    return `rgb(${this.red},${this.green},${this.blue})`;
  }

  public getLabel(): string {
    return `${this.colourID} ${this.colourDescription}`;
  }
}
