import { ProcessingCardInfo } from './processing-card-info';

export class SearchResult {
  public codeToTextMapping = {
    400: 'Bad Request - Wrong ID format',
    404: 'ID not found',
  };

  plantId: string;
  decorationPhysicalId: string;
  processingCards: ProcessingCardInfo[];
  decorationDesignId: string;
  decorationDesignDescription: string;
  launchId: string;
  error: string;
  colourId: string;

  constructor(data: Partial<SearchResult>) {
    Object.assign(this, data);
  }
}
