export class AdvancedSearchData {
  id: number;
  decorationDesignID: string;
  designDescription: string;
  designerID: string;
  decorationType: string;
  uploaderUserID: string;
  launchID: string;
  launchDescription: string;
  statusCode: string;
  status: string;

  isDisabled(): boolean {
    return this.statusCode === 'APPROVED_MASTER';
  }
}
