export class ProductBriefCreateInfo {
  decorationDesignId: string;
  decorationDescription: string;
  decorationTemplateFileName: string;
  statusCode: string;

  constructor(designId: string, description: string, templateFileName: string, statusCode: string) {
    this.decorationDesignId = designId;
    this.decorationDescription = description;
    this.decorationTemplateFileName = templateFileName;
    this.statusCode = statusCode;
  }
}
