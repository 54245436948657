import { Injectable } from '@angular/core';
import { copyEnabledPlatforms } from './create-page.config';

@Injectable({
  providedIn: 'root',
})
export class CreatePageService {
  isCopyEnabledForPlatform(sourcePlatformId: string, targetPlatformId: string): boolean {
    if (sourcePlatformId === targetPlatformId) {
      return true;
    }

    const copyEnabledPlatform = copyEnabledPlatforms[sourcePlatformId];

    return !!(copyEnabledPlatform && copyEnabledPlatform[targetPlatformId]);
  }
}
