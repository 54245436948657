import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Severity } from '@sentry/browser';
import { CollapseService } from '@shared/collapse.service';
import { LoggerService } from '@shared/logger/logger.service';
import { PlantSelectionService } from '@shared/plant-selection.service';
import { SentryContextService } from '@shared/sentry/sentry-context.service';
import { UserService } from '@shared/user.service';
import { FetchDesignSpecification } from 'projects/easy-print/src/app/ngxs/actions/fetch-design-spec';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FetchProductionSpecification } from './ngxs/actions/print-actions';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.less'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'Print';
  public loggedIn: boolean;
  public spinner: boolean;
  private subscription: Subscription;
  private plantSubscription: Subscription;

  public constructor(
    @Optional() private userService: UserService,
    @Optional() private broadcastService: BroadcastService,
    public collapseService: CollapseService,
    private translate: TranslateService,
    private plantSelection: PlantSelectionService,
    private router: Router,
    private sentryContextService: SentryContextService,
    private logger: LoggerService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private titleService: Title
  ) {
    this.titleService.setTitle(this.title);
    this.translate.addLangs(['en', 'da']);
    this.translate.setDefaultLang('en');
  }

  public ngOnInit() {
    if (environment.enable_oauth) {
      this.broadcastService.subscribe('msal:loginFailure', (payload) => {
        this.logger.info(`login failure ${JSON.stringify(payload)}`);
        this.loggedIn = false;
      });

      this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
        this.logger.info(`login success ${JSON.stringify(payload)}`);
        this.loggedIn = true;
        // TODO: Make REST request here to backend and fetch user (including photo)
        const userlogin = `User Login: ${JSON.stringify(this.userService.getUser())}`;
        this.logger.userLogin(userlogin);
      });

      if (!this.userService.userExists()) {
        if (this.userService.isCallback(window.location.hash)) {
          this.logger.info(`authService.isCallback: ${window.location.hash}`);
        } else {
          this.login();
        }
      } else {
        this.logger.info(
          'Authenticated userIdentifier: ',
          this.userService.getUserIdentifier(),
          `name: ${this.userService.getUserName()}`
        );
        this.sentryContextService.setUser(this.userService.getUserName());
        this.sentryContextService.addBreadcrumb({
          category: 'auth',
          level: Severity.Info,
          message: `Authenticated user ${this.userService.getUserName()}`,
        });
      }
    }

    let path = this.router.url;

    if (path.includes('/print/processing')) {
      this.plantSubscription = this.plantSelection.getPlant().subscribe((plant) => {
        if (plant) {
          this.logger.info(`Plant selection changed to ${plant.plantId}, refreshing list`);
          const _id = this.activatedRoute.snapshot.paramMap.get('id');
          const _idNumeric = Number(_id);
          if (!isNaN(_idNumeric)) {
            this.store.dispatch(new FetchProductionSpecification(_idNumeric));
          }
        }
      });

      this.logger.info('Print route url: ', this.router.url);
      const id = this.activatedRoute.snapshot.paramMap.get('id');
      const idNumeric = Number(id);
      if (isNaN(idNumeric) && id) {
        path = 'print/processing';
      } else if (!isNaN(idNumeric)) {
        this.store.dispatch(new FetchProductionSpecification(idNumeric));
      }
    } else if (path.includes('/print/design')) {
      this.logger.info('Print route url: ', this.router.url);
      const id = this.activatedRoute.snapshot.paramMap.get('id');
      const idNumeric = Number(id);
      if (isNaN(idNumeric) && id) {
        path = 'print/design';
      } else if (!isNaN(idNumeric)) {
        this.store.dispatch(new FetchDesignSpecification(idNumeric));
      }
    }

    if (this.router.navigated) {
      this.router.navigate([`${path}`]);
    }
  }

  public ngOnDestroy() {
    if (!environment.enable_oauth) {
      return;
    }
    if (this.broadcastService) {
      this.broadcastService.getMSALSubject().next(1);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.plantSubscription) {
      this.plantSubscription.unsubscribe();
    }
  }

  public get isIframe() {
    return window !== window.parent && !window.opener;
  }

  public login() {
    this.logger.info('Triggering login');
    this.userService.loginRedirect(['user.read']);
  }
}
