import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { LoggerService } from '@shared/logger/logger.service';
import { PlantSelectionService } from '@shared/plant-selection.service';
import { NzMessageService } from 'ng-zorro-antd';
import { FetchDesignSpecification } from 'projects/easy-print/src/app/ngxs/actions/fetch-design-spec';
import {
  IPlantStateModel,
  PlantState,
} from 'projects/processing-card/src/app/ngxs/states/plant-state';
import { Observable } from 'rxjs';
import { FetchProductionSpecification } from '../ngxs/actions/print-actions';
import { IPrintStateDecoModel, IPrintStateModel, PrintState } from '../ngxs/states/print-state';

@Component({
  selector: 'app-search-processing-specs',
  styleUrls: ['./search-processing-specs.component.less'],
  templateUrl: './search-processing-specs.component.html',
})
export class SearchProcessingSpecsComponent implements OnInit {
  processingSearchForm: FormGroup;
  designSearchForm: FormGroup;

  @Select(PrintState) printState$?: Observable<IPrintStateModel>;
  @Select(PrintState) printStateDeco$?: Observable<IPrintStateDecoModel>;
  // tslint:disable: no-unbound-method
  @Select(PlantState.getSelectedPlant) selectedPlant$?: Observable<IPlantStateModel>;
  isDesignMode: boolean;

  private readonly DESIGN_ID_LENGTH = 8;
  private readonly PHYSICAL_ID_LENGTH = 7;

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
    public router: Router,
    private store: Store,
    private route: ActivatedRoute,
    private message: NzMessageService
  ) {}

  ngOnInit() {
    this.logger.info('Initializating SearchProcessingSpecsComponent');

    this.isDesignMode = this.router.url.includes('/print/design');

    if (this.isDesignMode) {
      this.createDesignFG();
    } else {
      this.createProcessingFG();
    }

    // this.store.dispatch(new FetchProductionSpecification(+id));
    this.selectedPlant$.subscribe((_plant) => {
      if (!this.isDesignMode) {
        this.updateProcessingCards();
      }
    });
  }

  updateProcessingCards() {
    this.logger.info('A change to the id was submitted');
    const selectedPlant = this.store.selectSnapshot(PlantState.getSelectedPlant);

    if (!selectedPlant && !this.isDesignMode) {
      this.createMessage('warning', 'Plant is not selected\nPlease select a plant first');
    } else {
      const searchInputValue = this.getProcessingSearchInput().value;
      const searchInputNumeric = Number(searchInputValue);
      if (
        this.isValid(searchInputNumeric, this.PHYSICAL_ID_LENGTH, this.getProcessingSearchInput())
      ) {
        this.router.navigate([`/print/processing/${searchInputNumeric}`]);
        this.store.dispatch(new FetchProductionSpecification(searchInputNumeric));
      } else {
        this.router.navigate([`/print/processing/${searchInputValue}`]);
      }
    }
  }

  createMessage(type: string, text: string) {
    this.message.create(type, text);
  }

  updateDesingSpecs() {
    this.logger.info('A change to the design spec id was submitted');
    const searchInputValue = this.getDesignSearchInput().value;
    const searchInputNumeric = Number(searchInputValue);
    if (this.isValid(searchInputNumeric, this.DESIGN_ID_LENGTH, this.getDesignSearchInput())) {
      this.router.navigate([`/print/design/${searchInputNumeric}`]);
      this.store.dispatch(new FetchDesignSpecification(searchInputNumeric));
    } else {
      this.router.navigate([`/print/design/${searchInputValue}`]);
    }
  }

  createProcessingFG() {
    const id = this.route.snapshot.params.id;
    this.processingSearchForm = this.fb.group({
      // tslint:disable-next-line: no-unbound-method
      searchInput: [
        id || '',
        [
          Validators.required,
          Validators.minLength(this.PHYSICAL_ID_LENGTH),
          Validators.maxLength(this.PHYSICAL_ID_LENGTH),
        ],
      ],
    });
  }

  createDesignFG() {
    const id = this.route.snapshot.params.id;
    this.designSearchForm = this.fb.group({
      // tslint:disable-next-line: no-unbound-method
      searchInput: [
        id || '',
        [
          Validators.required,
          Validators.minLength(this.DESIGN_ID_LENGTH),
          Validators.maxLength(this.DESIGN_ID_LENGTH),
        ],
      ],
    });
  }

  private isValid(input: number, expectedLength: number, control: AbstractControl): boolean {
    return !isNaN(input) && input !== 0 && control.valid;
  }

  private getProcessingSearchInput() {
    return this.processingSearchForm.get('searchInput');
  }

  private getDesignSearchInput() {
    return this.designSearchForm.get('searchInput');
  }
}
