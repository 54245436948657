import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@shared/logger/logger.service';
import { NzMessageService } from 'ng-zorro-antd';
import { BackendService } from '../services/backend.service';

@Component({
  selector: 'app-system-users',
  styleUrls: ['./system-users.component.scss'],
  templateUrl: './system-users.component.html',
})
export class SystemUsersComponent implements OnInit {
  angForm: FormGroup;

  public allSystemUsers: any;
  private currentUser: any;

  constructor(
    private backendService: BackendService,
    private fb: FormBuilder,
    private errorMessageService: NzMessageService,
    private logger: LoggerService
  ) {
    this.createSystemUserForm();
  }

  ngOnInit() {
    this.logger.info('SystemUsersComponent - ngOnInit');
    this.backendService.fetchUser().subscribe(
      (res: any) => {
        this.currentUser = res;
        this.logger.info('user: ', res);
      },
      (error: HttpErrorResponse) => {
        const errorMessage = `There was an error fetching the user. Please report this error.`;
        this.logAndShowError(errorMessage, error);
      }
    );

    this.updateSystemUsers();
  }

  get user() {
    return JSON.stringify(this.currentUser, undefined, 4);
  }

  get f() {
    return this.angForm.controls;
  }

  get userName() {
    return this.angForm.get('userName');
  }

  get password() {
    return this.angForm.get('password');
  }

  get role() {
    return this.angForm.get('role');
  }

  createUser(userName: string, password: string, role: string): void {
    this.validateForm();

    this.logger.info('form valid', this.angForm.valid);
    this.logger.info('userName', userName, this.userName);
    this.logger.info('password', password, this.password);
    this.logger.info('role', role, this.role);

    this.backendService.createSystemUser(userName, password).subscribe(
      (res: any) => {
        this.logger.info('create system user result', res);
        if (role !== undefined) {
          this.backendService.setRoles(userName, ['SYSTEM', role]).subscribe(
            (resRole: any) => {
              this.logger.info('set roles result', resRole);
              this.updateSystemUsers();
            },
            (error: HttpErrorResponse) => {
              const errorMessage = `There was an error adding role to the system user. Please report this error.`;
              this.logAndShowError(errorMessage, error);
            }
          );
        } else {
          this.updateSystemUsers();
        }
      },
      (error: HttpErrorResponse) => {
        const errorMessage = `There was an error creating the system user. Please report this error.`;
        this.logAndShowError(errorMessage, error);
      }
    );
  }

  validateForm() {
    // tslint:disable-next-line: forin
    for (const i in this.angForm.controls) {
      this.f[i].markAsDirty();
      this.f[i].updateValueAndValidity();
    }
  }

  trackByFunction(index, item) {
    if (!item) {
      return undefined;
    }

    return index;
  }

  // tslint:disable-next-line: no-empty
  editUser(userName: string): void {}

  deleteUser(userName: string): void {
    this.backendService.deleteSystemUser(userName).subscribe(
      (res: any) => {
        this.logger.info(`deleteUser(${userName})`, res);
        this.updateSystemUsers();
      },
      (error: HttpErrorResponse) => {
        const errorMessage = `There was an error deleting the user. Please report this error.`;
        this.logAndShowError(errorMessage, error);
      }
    );
  }

  createSystemUserForm() {
    this.angForm = this.fb.group({
      // tslint:disable-next-line: no-unbound-method
      password: ['', [Validators.required, Validators.minLength(8)]],
      // tslint:disable-next-line: no-unbound-method
      role: [''],
      // tslint:disable-next-line: no-unbound-method
      userName: ['', Validators.required],
      // confirmPassword: ['', Validators.required], // TODO: add a confirmPassword field
    });
  }

  private updateSystemUsers() {
    this.backendService.getSystemUsers().subscribe(
      (res: any) => {
        this.allSystemUsers = res;
        this.logger.info('System users: ', res);
      },
      (error: HttpErrorResponse) => {
        const errorMessage = `There was an error fetching the system users. Please report this error.`;
        this.logAndShowError(errorMessage, error);
      }
    );
  }

  private logAndShowError(errorMessage: string, error: HttpErrorResponse) {
    this.logger.info(errorMessage, error);
    this.errorMessageService.error(errorMessage, {
      nzDuration: 10000,
      nzPauseOnHover: true,
    });
  }
}
