import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import {
  NzMessageService,
  UploadChangeParam,
  UploadFile,
  UploadFileStatus,
  UploadType,
  UploadXHRArgs,
} from 'ng-zorro-antd';
import { ProductBriefData } from '../model/product-brief-data';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-upload-step-select',
  styleUrls: ['./upload-select-step.component.less'],
  templateUrl: './upload-select-step.component.html',
})
export class UploadSelectStepComponent {
  @Input() files: UploadFile[];
  @Output() isValid = new EventEmitter<UploadFile[]>();
  @Output() isNotValid = new EventEmitter();

  constructor(private http: HttpClient, private logger: LoggerService) {}

  handleChange(changeEvent: UploadChangeParam): void {
    // Why handle changes manually and not using the two-way binding offered by nz-upload?
    // Because we need to validate the files and notify the parent component.
    // And the change event happens before nzFileList is updated.

    const file: UploadFile = changeEvent.file;
    const status: UploadFileStatus = file.status;

    switch (status) {
      case 'uploading': {
        // do nothing
        break;
      }
      case 'success': {
        // do nothing. This never happens?
        break;
      }
      case 'error': {
        // do nothing
        break;
      }
      case 'removed': {
        this.removeFile(file);
        break;
      }
      case 'done': {
        if (!this.fileHasAlreadyBeenUploaded(file)) {
          this.addFile(file);
        }
        break;
      }
      default: {
        // do nothing
      }
    }

    this.validateAndNotify();
  }

  customReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('file', item.file as any);
    formData.append('id', '1000');

    this.logger.info('Action: ', item.action);
    const req = new HttpRequest('POST', item.action, formData, {
      reportProgress: true,
      withCredentials: true,
    });

    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      // tslint:disable-next-line no-any
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            // tslint:disable-next-line:no-any
            (event as any).percent = (event.loaded / event.total) * 100;
          }
          item.onProgress(event, item.file);
        } else if (event instanceof HttpResponse) {
          item.onSuccess(event.body, item.file, event);
        }
      },
      (err) => {
        item.onError(err, item.file);
      }
    );
  };

  private fileHasAlreadyBeenUploaded(file: UploadFile) {
    const filesWithMatchingName = this.files.filter((f) => f.name === file.name);

    return filesWithMatchingName.length > 0;
  }

  private validateAndNotify(): void {
    if (this.files.length > 0) {
      const errors = this.files.filter((file) => file.error !== undefined);
      if (errors.length > 0) {
        this.sendNotValidEvent();
      } else {
        this.sendValidEvent();
      }
    } else {
      this.sendNotValidEvent();
    }
  }

  private sendValidEvent() {
    this.isValid.emit(this.files);
  }

  private sendNotValidEvent() {
    this.isNotValid.emit();
  }

  private addFile(file: UploadFile) {
    this.files.push(file);
  }

  private removeFile(file: UploadFile) {
    this.files = this.files.filter((f) => f.name !== file.name);
  }
}
