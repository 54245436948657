import { FormGroup } from '@angular/forms';
import { ComponentCanDeactivate } from '../can-deactivate/component-can-deactivate';

export abstract class FormCanDeactivate extends ComponentCanDeactivate {
  abstract get form(): FormGroup;

  canDeactivate(): boolean {
    return this.form.pristine || !this.form.dirty;
  }
}
