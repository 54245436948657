import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  IArchivedDecoSpecification,
  IArchivedProcessingCardInformation,
  IDecoSpecification,
  IProcessingCardInformation,
} from './ngxs/states/print-state';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  public constructor(private httpClient: HttpClient, private logger: LoggerService) {}

  public fetchUser() {
    return this.httpClient.get('/api/v1/user');
  }

  getDesignSpecificationData(decoDesignId: number): Observable<IDecoSpecification> {
    return this.get<IDecoSpecification>(`api/v1/productbrief/${decoDesignId}`);
  }

  getArchivedDesignSpecificationData(decoDesignId: number): Observable<IArchivedDecoSpecification> {
    return this.get<IArchivedDecoSpecification>(`api/v1/productbrief/archived/${decoDesignId}`);
  }

  getDesignSpecificationPdf(decoDesignId: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', ['application/pdf', 'application/json']);
    const statusCodeOrNull = 'NULL';
    const url = `api/v1/document/generated/${decoDesignId}/${statusCodeOrNull}/NULL`;

    return this.httpClient
      .get(url, { headers, responseType: 'blob' })
      .pipe(catchError((error) => this.logError(error)));
  }

  getArchivedProductionSpecificationPdf(
    decoPhysicalId: string,
    machineTypeId: string
  ): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', ['application/pdf', 'application/json']);
    const url = `api/v1/processingcard/cards/archived/document/${decoPhysicalId}/${machineTypeId}`;

    return this.httpClient
      .get(url, { headers, responseType: 'blob' })
      .pipe(catchError((error) => this.logError(error)));
  }

  getArchivedDesignSpecificationPdf(decoDesignId: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', ['application/pdf', 'application/json']);
    const url = `api/v1/productbrief/archived/document/${decoDesignId}`;

    return this.httpClient
      .get(url, { headers, responseType: 'blob' })
      .pipe(catchError((error) => this.logError(error)));
  }

  getGeneratedProductBriefPdf(
    decorationPhysicalId: string,
    plantId?: string,
    machineTypeId?: string
  ): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', ['application/pdf', 'application/json']);
    let url = `api/v1/processingcard/cards/document?decorationPhysicalId=${decorationPhysicalId}`;
    if (plantId) {
      url += `&plantId=${plantId}`;
    }
    if (machineTypeId) {
      url += `&machineTypeId=${machineTypeId}`;
    }

    return this.httpClient
      .get(url, { headers, responseType: 'blob' })
      .pipe(catchError((error) => this.logError(error)));
  }

  getProcessingCards(
    plantId: number,
    decoPhysicalId: number
  ): Observable<IProcessingCardInformation> {
    return this.get<IProcessingCardInformation>(
      `api/v1/processingcard/cards/search?plantId=${plantId}&searchTerm=${decoPhysicalId}`
    );
  }

  getArchivedProcessingCards(
    decoPhysicalId: number
  ): Observable<IArchivedProcessingCardInformation> {
    return this.get<IArchivedProcessingCardInformation>(
      `api/v1/processingcard/cards/archived/search?searchTerm=${decoPhysicalId}`
    );
  }

  private get<T>(url: string, ignoreStatusCodes?: number[]) {
    return this.httpClient.get<T>(url).pipe(catchError((error) => this.logError(error)));
  }

  private logError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.logger.error('An error occurred:', error.error.message);
      if (error.status !== 404) {
        this.logger.error('An error occurred:', error.error.message);
      }
    } else if (error.error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status !== 404) {
        if (error.error.message) {
          this.logger.error(
            `Backend returned code ${error.status}, ` + `message: ${error.error.message}`
          );
        } else {
          // The backend returned an unsuccessful response code without the message set.
          // The response body may contain clues as to what went wrong,
          this.logger.error(
            `Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`
          );
        }
      }
    } else {
      this.logger.error(`Backend returned code ${error.status}, error: ${JSON.stringify(error)}`);
    }

    return throwError(error);
  }
}
