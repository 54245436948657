import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  public constructor(private httpClient: HttpClient, private logger: LoggerService) {}

  public fetchUser() {
    return this.get('/api/v1/user');
  }

  public getSystemUsers() {
    return this.get('/api/system/v1/users');
  }

  public createSystemUser(username: string, password: string) {
    return this.httpClient
      .post('/api/system/v1/users', { username, password })
      .pipe(catchError((error) => this.logError(error)));
  }

  public setRoles(username: string, roles: string[]) {
    return this.httpClient
      .put(`/api/system/v1/users/${username}/roles`, roles)
      .pipe(catchError((error) => this.logError(error)));
  }

  public deleteSystemUser(username: string) {
    return this.httpClient
      .delete(`/api/system/v1/users/${username}`)
      .pipe(catchError((error) => this.logError(error)));
  }

  private get<T>(url: string) {
    return this.httpClient.get<T>(url).pipe(catchError((error) => this.logError(error)));
  }

  private logError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.logger.error('An error occurred:', error.error.message);
    } else if (error.error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.error.message) {
        this.logger.error(
          `Backend returned code ${error.status}, ` + `message: ${error.error.message}`
        );
      } else {
        // The backend returned an unsuccessful response code without the message set.
        // The response body may contain clues as to what went wrong,
        this.logger.error(
          `Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`
        );
      }
    } else {
      this.logger.error(`Backend returned code ${error.status}, error: ${JSON.stringify(error)}`);
    }

    return throwError(error);
  }
}
