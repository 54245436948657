import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ISubscription } from 'rxjs/Subscription';
import { GenericPlatform } from '../../../../model/generic-platform';
import { SearchResult } from '../../../../model/search-result';
import { ISelectionOption } from '../../../../model/selection-option';
import { SearchResultService } from '../../../../services/search-result.service';

@Component({
  selector: 'app-create-new-selection',
  styleUrls: ['./create-new-selection.component.less'],
  templateUrl: './create-new-selection.component.html',
})
export class CreateNewSelectionComponent implements OnInit, OnDestroy {
  npiStatusOptions: ISelectionOption[];
  genericPlatformOptions: GenericPlatform[];
  searchResultSubscription: ISubscription;
  searchResult: SearchResult;
  isVisibleTop = false;
  selectedPlatform: string;

  constructor(private searchResultService: SearchResultService, private route: ActivatedRoute) {}

  createNewCard(): void {
    this.isVisibleTop = true;
  }

  handleOkTop(): void {
    this.isVisibleTop = false;
  }

  handleCancelTop(): void {
    this.isVisibleTop = false;
  }

  ngOnInit() {
    this.genericPlatformOptions = this.route.snapshot.data
      .genericPlatformOptions as GenericPlatform[];
    this.npiStatusOptions = this.route.snapshot.data.npiStatusOptions as ISelectionOption[];
    this.searchResultSubscription = this.searchResultService
      .getSearchResult()
      .subscribe((searchResult: SearchResult) => {
        if (searchResult) {
          this.searchResult = searchResult;
        }
      });
  }

  ngOnDestroy() {
    this.searchResultSubscription.unsubscribe();
  }

  public trackByFunction(index, item) {
    if (!item) {
      // tslint:disable-next-line: no-null-keyword
      return null;
    }

    return index;
  }

  getNpiStatusText = (npiStatusOptionCode: string): string =>
    this.npiStatusOptions.find((option) => option.code === npiStatusOptionCode).displayLabel;
}
