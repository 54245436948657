import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { IPlantSelection } from '@shared/plant-selection';
import { PlantSelectionService } from '@shared/plant-selection.service';
import {
  SetPlants,
  SetSelectedPlant,
} from 'projects/processing-card/src/app/ngxs/actions/plant-actions';
import { Observable, Subscription } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-plant-selection',
  styleUrls: ['./plant-selection.component.less'],
  templateUrl: './plant-selection.component.html',
})
export class PlantSelectionComponent implements OnInit, OnDestroy {
  control = new FormControl();
  plantSelections: IPlantSelection[];
  selectedPlantKey = 'selectedPlant';
  defaultValueString: string;
  displayName: string;
  searchResultSubscription: ISubscription;

  private subscriptions = new Subscription();
  private plantSelectionSubscription = new Subscription();
  private selectedPlant: IPlantSelection;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private plantSelectionService: PlantSelectionService,
    private store: Store
  ) {
    this.plantSelections = (this.route.snapshot.data.plantSelections || []) as IPlantSelection[];
    this.store.dispatch(new SetPlants(this.plantSelections));

    this.subscriptions = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateLanguagePlantSelections();
    });

    this.plantSelectionSubscription = this.plantSelectionService.getPlant().subscribe((plant) => {
      this.selectedPlant = plant;
      this.store.dispatch(new SetSelectedPlant(this.selectedPlant));
      this.updateLanguagePlantSelections();
    });
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.control.valueChanges.subscribe((plant) => {
        this.plantSelectionService.setPlantForUser(plant);
        this.store.dispatch(new SetSelectedPlant(plant));
      })
    );
  }

  updateLanguagePlantSelections() {
    this.tranlateKey('displayNameLanguage').subscribe((data) => {
      this.displayName = data;
      if (this.selectedPlant) {
        this.defaultValueString = this.selectedPlant[`${this.displayName}`];
      } else {
        this.tranlateKey('defaultValueString').subscribe((defaultValue) => {
          this.defaultValueString = defaultValue;
        });
      }
    });
  }

  public tranlateKey(key: string): Observable<any> {
    return this.translate.get(`header.plant-selection.${key}`);
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.plantSelectionSubscription) {
      this.plantSelectionSubscription.unsubscribe();
    }
  }

  trackById(index, item) {
    if (item) {
      return item.id;
    }

    return undefined;
  }
}
