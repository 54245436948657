import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import {
  ApplicationRef,
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RoleGuard } from '@shared/auth/guards/role-guard.service';
import { MsalComponent } from '@shared/auth/msal.component';
import { NoCacheHeadersInterceptor } from '@shared/cache-control/NoCacheHeadersInterceptor';
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
import { PdfViewerDialogComponent } from '@shared/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { ProfileDropdownComponent } from '@shared/profile/profile-dropdown.component';
import { SharedModule } from '@shared/shared.module';
// tslint:disable
import {
  en_US,
  NgZorroAntdModule,
  NzFormModule,
  NzLayoutModule,
  NzMessageModule,
  NzModalModule,
  NZ_I18N,
} from 'ng-zorro-antd';
// tslint:enable
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { version } from 'src/../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header.component';
import { PrintListComponent } from './main-content/print-list/print-list.component';
import { PrintTopComponent } from './main-content/print-top/print-top.component';
import { PlantSelectionComponent } from './plant-selection/plant-selection.component';
import { SearchProcessingSpecsComponent } from './search-processing-specs/search-processing-specs.component';
import { SideMenuComponent } from './side-menu/side-menu.component';

const providers = [
  { provide: LoggerService, useClass: ConsoleLoggerService },
  { provide: NZ_I18N, useValue: en_US },
  { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
  RoleGuard,
  DatePipe,
];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    SideMenuComponent,
    HeaderComponent,
    PdfViewerDialogComponent,
    PlantSelectionComponent,
    PrintTopComponent,
    PrintListComponent,
    SearchProcessingSpecsComponent,
  ],
  entryComponents: [PdfViewerDialogComponent, ProfileDropdownComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgxExtendedPdfViewerModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    NgZorroAntdModule,
    NzFormModule,
    NzLayoutModule,
    NzMessageModule,
    NzModalModule,
  ],
  providers,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

// tslint:disable-next-line: max-classes-per-file
@NgModule({})
export class EasyPrintSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers,
    };
  }

  public static ngDoBootstrap(ref: ApplicationRef) {
    const isIFrame = window !== window.parent && !window.opener;
    if (isIFrame) {
      ref.bootstrap(MsalComponent);
    } else {
      ref.bootstrap(AppComponent);
    }
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${version}`);
}
