import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConsoleLoggerService } from '@shared/logger/console-logger.service';
import { LoggerService } from '@shared/logger/logger.service';
import { ISelectionOption } from '../../../../model/selection-option';

@Component({
  providers: [
    { provide: LoggerService, useClass: ConsoleLoggerService },
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line: no-forward-ref
      useExisting: forwardRef(() => ProcessingDataComponent),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      // tslint:disable-next-line: no-forward-ref
      useExisting: forwardRef(() => ProcessingDataComponent),
    },
  ],
  selector: 'app-processing-data',
  styleUrls: ['./processing-data.component.less'],
  templateUrl: './processing-data.component.html',
})
export class ProcessingDataComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() productionToleranceOptions: ISelectionOption[];
  @Output() public clicheChanged = new EventEmitter();

  processingDataForm: FormGroup;
  genericFlexId: string;
  productToleranceMM: string;
  clicheId: string;
  clicheStorageText: string;

  constructor(private fb: FormBuilder, private ts: TranslateService) {
    this.processingDataForm = this.fb.group({
      // tslint:disable: no-unbound-method
      clicheId: ['', Validators.required],
      clicheStorageText: [''],
      genericFlexId: ['', [Validators.pattern('G\\d{5,6}')]],
      // tslint:disable-next-line: no-null-keyword
      productToleranceMm: [null, Validators.required],
    });
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.processingDataForm.valid
      ? // tslint:disable-next-line: no-null-keyword
        null
      : {
          invalidForm: {
            message: this.ts.instant(
              'main-content.processing-cars.processingData.processingDataFieldError'
            ),
            valid: false,
          },
        };
  }

  // tslint:disable-next-line: no-empty
  public onTouched: () => void = () => {};

  writeValue(obj: any): void {
    // tslint:disable-next-line: no-unused-expression
    obj && this.processingDataForm.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.processingDataForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.processingDataForm.disable() : this.processingDataForm.enable();
  }

  ngOnInit() {
    this.processingDataForm.controls.productToleranceMm.setValue(
      this.productionToleranceOptions.filter((toleranceDef) => toleranceDef.defaultSelection)[0]
        .code
    );
  }

  public trackByFunction(index, item) {
    if (!item) {
      // tslint:disable-next-line: no-null-keyword
      return null;
    }

    return index;
  }

  public sendClicheChanged() {
    this.clicheChanged.emit();
  }
}
