import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from '@shared/logger/logger.service';
import { IPlantSelection } from '@shared/plant-selection';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BackendService } from '../services/backend.service';

@Injectable()
export class PlantSelectionResolver implements Resolve<IPlantSelection[]> {
  constructor(private service: BackendService, private logger: LoggerService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IPlantSelection[]> | Promise<IPlantSelection[]> | IPlantSelection[] {
    return this.service.getPlantValues().pipe(
      catchError((error) => {
        this.logger.error(`Failed fetching plants from backend - ${error.message}`, error);

        return Observable.of([]);
        // return throwError('Failed fetching plants from backend');
      })
    );
  }
}
