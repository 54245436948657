export class FetchProductionSpecification {
  static readonly type = '[Print] Fetch Production Spec';

  constructor(public physicalDesignId: number) {}
}
// tslint:disable-next-line:max-classes-per-file
export class SearchLoadingProcessing {
  static readonly type = '[Search] Loading processing';

  constructor(public isLoading: boolean) {}
}
// tslint:disable-next-line:max-classes-per-file
export class SearchLoadingArchievedProcessing {
  static readonly type = '[Search] Loading archieved processing';

  constructor(public isLoading: boolean) {}
}
// tslint:disable-next-line:max-classes-per-file
export class PdfLoadingProcessing {
  static readonly type = '[PDF] Loading processing PDF';

  constructor(public isLoading: boolean) {}
}

// tslint:disable-next-line:max-classes-per-file
export class SearchLoadingDeco {
  static readonly type = '[Search] Loading deco';

  constructor(public isLoading: boolean) {}
}
// tslint:disable-next-line:max-classes-per-file
export class SearchLoadingArchievedDeco {
  static readonly type = '[Search] Loading archieved deco';

  constructor(public isLoading: boolean) {}
}
// tslint:disable-next-line:max-classes-per-file
export class PdfLoadingDeco {
  static readonly type = '[PDF] Loading Deco PDF';

  constructor(public isLoading: boolean) {}
}
